import { Badge, styled, Tooltip } from "@mui/material";
import { ProposalFieldCategory } from "work/entities/proposal/proposal";
import ProposalIssues, {
  ProposalIssueLevel,
} from "work/values/proposal-issues/proposal-issues";

const CountBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: "-8px",
    top: "-8px",
  },
}));

type ProposalIssuesBadgeProps = {
  isLoading?: boolean;
  issues?: ProposalIssues;
  category: ProposalFieldCategory;
  title?: string;
};

export default function ProposalIssuesBadge(
  props: Readonly<ProposalIssuesBadgeProps>
) {
  const { isLoading, issues, category, title } = props;

  const filteredIssues = issues?.entries.filter(
    (issue) => issue.field.category === category
  );
  const criticalIssuesPresent = filteredIssues?.some(
    (issue) => issue.level === ProposalIssueLevel.Critical
  );

  function getToolTip() {
    if (!filteredIssues) return "";
    return filteredIssues.map((issue) => {
      return (
        <p key={issue.field.name + (issue.field.id?.toString ?? "")}>
          {issue.description}
        </p>
      );
    });
  }
  return (
    <CountBadge
      color={criticalIssuesPresent ? "error" : "warning"}
      variant="standard"
      invisible={isLoading || !filteredIssues || filteredIssues.length === 0}
      badgeContent={
        <Tooltip title={getToolTip()} placement="bottom">
          <span>{filteredIssues?.length ?? 0}</span>
        </Tooltip>
      }
    >
      {title ?? category.toString()}
    </CountBadge>
  );
}
