import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import EntityMember from "legal-entities/entities/entity-member/entity-member";
import EntityName from "legal-entities/values/entity-name/entity-name";
import BillingRateInfo from "marketplace/values/billing-rate-info/billing-rate-info";

export default class MemberUserInfoAPIResponse {
    userId?: string;
    memberId?: string;
    entityId?: string;
    entityName?: string;
    individualId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    created?: string;
    isAdmin?: boolean;
    isActive?: boolean
    isOfficer?: boolean;
    avatarId?: string;
    description?: string;
    location?: string;
    school?: string;
    phoneNumber?: string;
    resumeId?: string;
    professionalRegistration?: string;
    technicalBackground?: string;
    numberOfApplicationsDraftedProsecuted?: number;
    numberOfAllowedApplications?: number;
    barAdmissionYear?: number;
    isOnCall?: boolean;
    insuredAmount?: number;
    billingRate?: string;
    potentialDiversityCandidate?: boolean;
    collaborates?: boolean;
    additionalInfo?: string;
    categories?: string[];
    links?: string[];
    professionalPublications?: string[];
    sampleApplications?: string[];
    fluentLanguages?: string[];
    technicalLanguages?: string[];
    regionsRegistered?: string[];
    regionsLicensed?: string[];

    deserialize(): EntityMember {
        if (!this.userId) throw new Error('Invalid response from API: userId is required');
        if (!this.memberId) throw new Error('Invalid response from API: memberId is required');
        if (!this.entityId) throw new Error('Invalid response from API: entityId is required');
        if (!this.entityName) throw new Error('Invalid response from API: entityName is required');
        if (!this.individualId) throw new Error('Invalid response from API: individualId is required');
        if (!this.created) throw new Error('Invalid response from API: created is required');
        if (!this.email) throw new Error('Invalid response from API: email is required');

        return new EntityMember(
            {
                userId: new Guid(this.userId),
                memberId: new Guid(this.memberId),
                entityId: new Guid(this.entityId),
                entityName: new EntityName(this.entityName),
                individualId: new Guid(this.individualId),
                email: new EmailAddress(this.email),
                name: new Name(this.firstName, this.lastName),
                created: new Date(this.created),
                isAdmin: this.isAdmin ?? false,
                isActive: this.isActive ?? false,
                isOfficer: this.isOfficer ?? false,
                isManager: this.isOfficer ?? this.isAdmin,
                avatarId: this.avatarId ? new Guid(this.avatarId) : undefined,
                description: this.description,
                location: this.location,
                school: this.school,
                phoneNumber: this.phoneNumber,
                resumeId: this.resumeId ? new Guid(this.resumeId) : undefined,
                professionalRegistration: this.professionalRegistration,
                technicalBackground: this.technicalBackground,
                numberOfApplicationsDraftedProsecuted: this.numberOfApplicationsDraftedProsecuted,
                numberOfAllowedApplications: this.numberOfAllowedApplications,
                barAdmissionYear: this.barAdmissionYear,
                isOnCall: this.isOnCall,
                insuredAmount: this.insuredAmount,
                billingRate: this.billingRate ? new BillingRateInfo(this.billingRate, "hr") : undefined,
                potentialDiversityCandidate: this.potentialDiversityCandidate,
                collaborates: this.collaborates,
                additionalInfo: this.additionalInfo,
                categories: this.categories,
                links: this.links,
                professionalPublications: this.professionalPublications,
                sampleApplications: this.sampleApplications,
                fluentLanguages: this.fluentLanguages,
                technicalLanguages: this.technicalLanguages,
                regionsRegistered: this.regionsRegistered,
                regionsLicensed: this.regionsLicensed
            }
        )
    }
}
