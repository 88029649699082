import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import Guid from "common/values/guid/guid";
import CompanyAPIService from "marketplace/entities/company/api/company-api-service";
import MarketplaceCompanySearch from "marketplace/entities/company/api/request-contracts/marketplace-company-search";
import Company from "marketplace/entities/company/company";
import Forum from "messaging/entities/forum/forum";
import Session from "users/session/session";

type CompanyStoreState = {
  vendors: {
    entries: Company[];
    loading: boolean;
    error: SerializedError | null;
  };
  byId: {
    entries: Record<string, Company>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  byQuery: {
    entries: Record<string, Company[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  avatars: {
    entries: Record<string, string>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  contactForums: {
    entries: Record<string, Forum[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
};

const initialState: CompanyStoreState = {
  vendors: {
    entries: [],
    loading: false,
    error: null,
  },
  byId: {
    entries: {},
    loading: {},
    error: {},
  },
  byQuery: {
    entries: {},
    loading: {},
    error: {},
  },
  avatars: {
    entries: {},
    loading: {},
    error: {},
  },
  contactForums: {
    entries: {},
    loading: {},
    error: {},
  },
};

export const populateCompanyVendors = createAsyncThunk<
  Company[],
  { session: Session; userIds: Guid[] }
>(
  "companies/getCompanyVendors",
  async ({ session }: { session: Session }, thunkAPI) => {
    try {
      const apiService = new CompanyAPIService(session);
      const companies = await apiService.getCompanyVendors();
      return companies;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const populateCompanyById = createAsyncThunk(
  "companies/getCompanyById",
  async (
    { session, companyId }: { session: Session; companyId: Guid },
    thunkAPI
  ) => {
    try {
      const apiService = new CompanyAPIService(session);
      const company = await apiService.getCompanyById(companyId);
      return company;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateCompaniesByQuery = createAsyncThunk(
  "companies/getCompaniesByQuery",
  async (
    {
      session,
      query,
    }: {
      session: Session;
      query: MarketplaceCompanySearch;
    },
    thunkAPI
  ) => {
    try {
      const apiService = new CompanyAPIService(session);
      const companies = await apiService.searchCompanies(query);
      return companies;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateCompanyAvatar = createAsyncThunk(
  "companies/getCompanyAvatar",
  async (
    { session, companyId }: { session: Session; companyId: Guid },
    thunkAPI
  ) => {
    try {
      const apiService = new CompanyAPIService(session);
      const avatars = await apiService.getCompanyAvatar(companyId);
      return avatars;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateCompanyContactForums = createAsyncThunk(
  "companies/getCompanyContactForums",
  async (
    { session, companyId }: { session: Session; companyId: Guid },
    thunkAPI
  ) => {
    try {
      const apiService = new CompanyAPIService(session);
      const forums = await apiService.getCompanyContactForums(companyId);
      return forums;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    addCompany: (state, action: PayloadAction<Company>) => {
      const companyId = action.payload.id?.value;
      if (!companyId) {
        return;
      }

      state.byId.entries[companyId] = action.payload as any;
      state.vendors.entries = state.vendors.entries.filter(
        (company) => company.id?.value !== companyId
      );
      state.vendors.entries.push(action.payload);
    },
    removeCompany: (state, action: PayloadAction<Guid>) => {
      delete state.byId.entries[action.payload.value];
    },
    addAvatar: (
      state,
      action: PayloadAction<{ companyId: Guid; avatar: string }>
    ) => {
      state.avatars.entries[action.payload.companyId.value] =
        action.payload.avatar;
    },
    removeAvatar: (state, action: PayloadAction<Guid>) => {
      delete state.avatars.entries[action.payload.value];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateCompanyVendors.pending, (state, action) => {
      state.vendors.loading = true;
      state.vendors.error = null;
    });
    builder.addCase(populateCompanyVendors.fulfilled, (state, action) => {
      state.vendors.entries = action.payload;
      state.vendors.loading = false;
    });
    builder.addCase(populateCompanyVendors.rejected, (state, action) => {
      state.vendors.loading = false;
      state.vendors.error = action.error;
    });

    builder.addCase(populateCompanyById.pending, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.byId.loading[companyId] = true;
      state.byId.error[companyId] = null;
    });
    builder.addCase(populateCompanyById.fulfilled, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.byId.loading[companyId] = false;
      if (!action.payload) {
        console.error("Company not received by store.");
        return;
      }
      state.byId.entries[companyId] = action.payload;
    });
    builder.addCase(populateCompanyById.rejected, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.byId.loading[companyId] = false;
      state.byId.error[companyId] = action.error;
    });

    builder.addCase(populateCompaniesByQuery.pending, (state, action) => {
      const query = action.meta.arg.query.asSearchParams().toString();
      state.byQuery.loading[query] = true;
      state.byQuery.error[query] = null;
    });
    builder.addCase(populateCompaniesByQuery.fulfilled, (state, action) => {
      const query = action.meta.arg.query.asSearchParams().toString();
      state.byQuery.entries[query] =
        action.payload.hits?.map((hit) => hit.data.deserializeToCompany()) ||
        [];
      state.byQuery.loading[query] = false;
    });
    builder.addCase(populateCompaniesByQuery.rejected, (state, action) => {
      const query = action.meta.arg.query.asSearchParams().toString();
      state.byQuery.loading[query] = false;
      state.byQuery.error[query] = action.error;
    });

    builder.addCase(populateCompanyAvatar.pending, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.avatars.loading[companyId] = true;
      state.avatars.error[companyId] = null;
    });
    builder.addCase(populateCompanyAvatar.fulfilled, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.avatars.entries[companyId] = action.payload;
      state.avatars.loading[companyId] = false;
    });
    builder.addCase(populateCompanyAvatar.rejected, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.avatars.loading[companyId] = false;
      state.avatars.error[companyId] = action.error;
    });

    builder.addCase(populateCompanyContactForums.pending, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.contactForums.loading[companyId] = true;
      state.contactForums.error[companyId] = null;
    });
    builder.addCase(populateCompanyContactForums.fulfilled, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.contactForums.entries[companyId] = action.payload;
      state.contactForums.loading[companyId] = false;
    });
    builder.addCase(populateCompanyContactForums.rejected, (state, action) => {
      const companyId = action.meta.arg.companyId.value;
      state.contactForums.loading[companyId] = false;
      state.contactForums.error[companyId] = action.error;
    });
  },
});
