import Text from "common/values/text/text";

export default class ProjectName extends Text {
    public override clone(): ProjectName {
        return new ProjectName(this.value);
    }
    public static get Prototype(): ProjectName {
        return new ProjectName('Prototype');
    }
    public get isEmpty(): boolean {
        return this.value === '';
    }
    public override fromJSON(object: any): ProjectName | null {
        if(!object) return object;
        if(typeof object !== 'string') throw new Error('ProjectName.fromJSON requires a string');
        return new ProjectName(object);
    }
    public toJSON(): any {
        return this.value;
    }
}
