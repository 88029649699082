export type DocumentOrderField = 'name' | 'created';

export default class DocumentOrderParameters {
  field: DocumentOrderField;
  direction: 'asc' | 'desc';

  constructor(
    field: DocumentOrderField,
    direction: 'asc' | 'desc'
  ) {
    this.field = field;
    this.direction = direction;
  }

  public asSearchParams(): URLSearchParams {
    const params = new URLSearchParams();
    params.append("field", this.field);
    params.append("direction", this.direction);
    return params;
  }
}
