import axios, { AxiosHeaders, AxiosResponse, CanceledError } from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import Document from "documents/entities/document/document";
import DocumentAPIResponse from "documents/entities/document/api/response-contracts/document-api-response";
import Session from "users/session/session";
import DocumentTopic from "documents/values/document-topic";
import DocumentFormAPIRequest from "documents/entities/document/api/request-contracts/document-form-api-request";
import UpdateDocumentAPIRequest from "documents/entities/document/api/request-contracts/update-document-api-request";
import UnavailableDocument from "documents/values/unavailable-document";
import PaginationParameters from "common/contracts/pagination-parameters";
import DocumentTopicParameters from "documents/entities/document/api/request-contracts/document-topic-parameters";
import PaginatedResponse from "common/contracts/paginated-response";
import DocumentOrderParameters from "documents/entities/document/api/request-contracts/document-order-parameters";
import DocumentFilterParameters from "documents/entities/document/api/request-contracts/document-filter-parameters";

export default class DocumentAPIService {
  private headers: AxiosHeaders = new AxiosHeaders();
  private headersWithFormData(): AxiosHeaders {
    return this.headers.concat({ 'Content-Type': 'multipart/form-data' });
  }

  constructor(session: Session) {
    if (!session.authToken) throw new Error("Cannot create DocumentAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getDocumentsInfo(
    abortController: AbortController | undefined,
    paginationParameters: PaginationParameters,
    topicParameters: DocumentTopicParameters,
    orderParameters: DocumentOrderParameters,
    filterParameters: DocumentFilterParameters
  ): Promise<PaginatedResponse<Document>> {
    const url = new URL(`/documents`, AttorneyHubAPIService.apiBaseUrl);
    url.searchParams.append('pageIndex', paginationParameters.pageIndex.toString());
    url.searchParams.append('pageSize', paginationParameters.pageSize.toString());
    url.searchParams.append('orderBy', orderParameters.field);
    url.searchParams.append('orderDir', orderParameters.direction);
    if (filterParameters.name) url.searchParams.append('name', filterParameters.name);
    if (filterParameters.minCreatedDate) url.searchParams.append('minCreatedDate', filterParameters.minCreatedDate);
    if (filterParameters.maxCreatedDate) url.searchParams.append('maxCreatedDate', filterParameters.maxCreatedDate);
    if (topicParameters.entityClass) url.searchParams.set("entityClass", topicParameters.entityClass);
    if (topicParameters.entityId) url.searchParams.set("entityId", topicParameters.entityId);
    if (topicParameters.context) url.searchParams.set("context", topicParameters.context);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );

      const data = response.data.map((documentData: any) => Object.assign(
        new DocumentAPIResponse(),
        documentData
      ).deserialize());
      const paginationParams = new URLSearchParams(response.headers['x-pagination']);
      return new PaginatedResponse<Document>(
        data,
        Number.parseInt(paginationParams.get('pageIndex') ?? '0'),
        Number.parseInt(paginationParams.get('pageSize') ?? '25'),
        Number.parseInt(paginationParams.get('totalElements') ?? '0'),
        Number.parseInt(paginationParams.get('totalPages') ?? '0')
      );
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      throw new DocumentAPIServiceError("getDocumentsInfo", error);
    }
  }

  async getDocumentInfoById(id: Guid, abortController: AbortController | undefined): Promise<Document | UnavailableDocument | undefined> {
    if (!id) throw new Error("Cannot get document without id.");

    try {
      const url = new URL(`/documents/${id.value}`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const responseData: DocumentAPIResponse = Object.assign(
        new DocumentAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError)
        return;
      if (error.response?.status === 404)
        throw new DocumentNotFoundError(id);
      throw new DocumentAPIServiceError("getDocumentInfoById", error);
    }
  }


  async getDocumentsInfoByIds(ids: Guid[], abortController: AbortController | undefined): Promise<(Document | UnavailableDocument)[]> {
    if (ids.length === 0) throw new Error("Cannot get documents without ids.");

    try {
      const url = new URL(`/documents/get-documents-info-by-ids`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.post(
        url.toString(),
        ids.map(id => id.value),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return response.data.map((document: Document) =>
        Object.assign(new DocumentAPIResponse(), document).deserialize()
      );
    } catch (error: any) {
      if (error instanceof CanceledError)
        throw error;
      if (error.response?.status === 404)
        throw new DocumentNotFoundError();
      throw new DocumentAPIServiceError("getDocumentsInfoByIds", error);
    }
  }

  async downloadDocument(id: Guid): Promise<AxiosResponse> {
    if (!id) throw new Error("Cannot document without id.");

    try {
      const url = new URL(`/documents/${id.value}/download`, AttorneyHubAPIService.apiBaseUrl);
      return await axios.get(
        url.toString(),
        {
          headers: this.headers,
          responseType: 'blob'
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new DocumentNotFoundError(id);
      throw new DocumentAPIServiceError("downloadDocument", error);
    }
  }

  async updateDocument(originalDocument: Document, updatedDocument: Document): Promise<Document | UnavailableDocument> {
    if (!originalDocument.id || !updatedDocument.id)
      throw new Error("Cannot update document without id.");

    const request = new UpdateDocumentAPIRequest(originalDocument, updatedDocument);

    if (request.payload.length === 0)
      return updatedDocument;

    try {
      const url = new URL(`/documents/${originalDocument.id.value}`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.patch(
        url.toString(),
        request.payload,
        {
          headers: this.headers
        }
      );
      const responseData: DocumentAPIResponse = Object.assign(new DocumentAPIResponse(), response.data);
      return responseData.deserialize();
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new DocumentNotFoundError(originalDocument.id);
      if (error.response?.status === 400)
        throw new DocumentUpdateError(updatedDocument, error.response.data);
      throw new DocumentAPIServiceError("updateDocument", error);
    }
  }

  async deleteDocument(id: Guid): Promise<void> {
    if (!id) throw new Error("Cannot delete document without id.");
    try {
      const url = new URL(`/documents/${id.value}`, AttorneyHubAPIService.apiBaseUrl);
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new DocumentNotFoundError(id);
      throw new DocumentAPIServiceError("deleteDocument", error);
    }
  }

  async createDocument(file: File, name?: string, topics?: DocumentTopic[], templateIds?: Guid[]): Promise<Document | UnavailableDocument> {
    try {
      const request = new DocumentFormAPIRequest(file, name, topics, templateIds);
      const url = new URL(`/documents`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.post(
        url.toString(),
        request.formData,
        {
          headers: this.headersWithFormData()
        }
      );
      const responseData: DocumentAPIResponse = Object.assign(new DocumentAPIResponse(), response.data);
      return responseData.deserialize();
    }
    catch (error: any) {
      throw new DocumentAPIServiceError("createDocument", error);
    }
  }
}

export class DocumentNotFoundError extends Error {
  constructor(id?: Guid) {
    if (!id)
      super("One or more documents not found.");
    else
      super(`Document with id ${id} not found.`);
  }
}

export class DocumentUpdateError extends Error {
  updatedDocument: Document;
  errorMessage: string;
  constructor(updatedDocument: Document, errorMessage: string) {
    super("Document update failed.");
    this.updatedDocument = updatedDocument;
    this.errorMessage = errorMessage;
  }
}

export class DocumentAPIServiceError extends Error {
  method: string;
  error: any;
  constructor(method: string, error: any) {
    super(`DocumentAPIService.${method} failed.`);
    this.method = method;
    this.error = error;
  }
}
