import Guid from "common/values/guid/guid";

import Company from "marketplace/entities/company/company";
import MarketplaceCompanyInfoAPIResponse from "marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response";
import CompanyProfile from "marketplace/values/company-profile/company-profile";

export default class MarketplaceCompanyAPIResponse extends MarketplaceCompanyInfoAPIResponse {
    entityId?: string;

    deserialize(): Company {
        if (!this.id) throw new Error("Company id is required, was not returned by the API");
        if (!this.entityId) throw new Error("Company entity id is required, was not returned by the API");
        if (!this.name) throw new Error("Company name is required, was not returned by the API");

        const profile = new CompanyProfile(
            this.name,
            this.description,
            this.location,
            this.yearEstablished,
            this.website,
            this.numberOfEmployees,
            this.categories,
            this.avatar ? new Guid(this.avatar) : undefined,
            this.contact?.id ? new Guid(this.contact.id) : undefined,
            this.contact?.userId ? new Guid(this.contact.userId) : undefined
        )

        return new Company(
            new Guid(this.id),
            new Guid(this.entityId),
            profile,
            this.contact?.id ? new Guid(this.contact.id) : undefined,
            this.isVisible ?? false
        );
    }
}
