import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Button, CircularProgress, debounce, IconButton, List, ListItemAvatar, ListItemButton, ListItemText, styled, TextField, Typography } from '@mui/material';
import LoadingButton from 'common/components/loading-button';
import IndividualAvatar from 'marketplace/view/individual-avatar';
import MessagingAPIService from 'messaging/api/messaging-api-service';
import Forum from 'messaging/entities/forum/forum';
import React, { useEffect } from 'react';
import UserNetworkConnectionAPIService from 'users/entities/user-network-connection/api/user-network-connection-api-service';
import NetworkedUserInfo from 'users/entities/user-network-connection/networked-user-info';
import { enqueueSnackbar } from "notistack";
import { useSession } from 'users/session/session-context';
import { CanceledError } from 'axios';
import Loader from 'common/components/loader';

const AddSubscriberContainer = styled('div')(({ theme }) => ({
  minWidth: '22rem',
  padding: theme.spacing(2),
  textAlign: 'center',
}));
const SearchField = styled(TextField)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1)
})) as typeof TextField;
const ListContainer = styled(List)(({ theme }) => ({
  maxHeight: '20rem',
  overflowX: 'hidden',
  overflowY: 'auto'
}));
const LoaderProgress = styled(CircularProgress)(({ theme }) => ({
  display: 'inline-block',
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4)
}));
const SelectedIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}));
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1)
}));
const MessageInput = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.6),
  marginTop: theme.spacing(1.6)
}));
const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr 1fr',
  width: '100%'
}));

type AddSubscriberProps = {
  forum: Forum;
  onClosed: () => void;
};

export default function AddSubscriber(props: Readonly<AddSubscriberProps>) {
  const { onClosed, forum } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [connections, setConnections] = React.useState<NetworkedUserInfo[]>([]);
  const [selectedSubscribers, setSelectedSubscribers] = React.useState<NetworkedUserInfo[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [customMessage, setCustomMessage] = React.useState<string>('');

  const session = useSession();

  useEffect(() => {
    let abortController = new AbortController();
    getNetworkedIndividuals(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  async function getNetworkedIndividuals(abortController: AbortController) {
    try {
      setIsLoading(true);
      const connections = await new UserNetworkConnectionAPIService(session)
        .getUserConnectionsInfo(abortController);
      setConnections(connections);
      session.updateNetworkConnections(connections);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  function handleSubscriberSelected(event: React.MouseEvent<HTMLDivElement, MouseEvent>, subscriber: NetworkedUserInfo): void {

    event.stopPropagation();

    const alreadySelected = selectedSubscribers.some(r => r.userId?.isEqualTo(subscriber.userId));
    if (!alreadySelected) {
      setSelectedSubscribers(prevSub => [...prevSub, subscriber]);
    } else {
      setSelectedSubscribers(prevSub => prevSub.filter(r => r.userId?.value !== subscriber.userId?.value));
    }
  }

  function search(value: string) {
    setSearchTerm(value);
    if (value === '') {
      return;
    }
    debouncedSearch(value);
  }

  const debouncedSearch = debounce((value) => { }, 100);

  const canAddSubscribers = connections.length > 0 && (forum.subscriberIds?.length - 1) < connections.length;

  function saveChanges(): void {
    setIsSaving(true);
    const messageService = new MessagingAPIService(session);
    try {
      selectedSubscribers.forEach((subscriber) => {
        messageService.inviteSubscriberToForum(forum, subscriber.userId, customMessage);
      });
      enqueueSnackbar('Forum subscribers saved', { variant: 'success' });
      onClosed();
    }
    catch (error) {
      enqueueSnackbar('Failed to save forum subscriber changes', { variant: 'error' });
      console.error(error);
    }
    finally {
      setIsSaving(false);
    }
  }

  return (
    <React.Fragment>
      {isLoading && (<Loader size={50} />)}
      {!isLoading &&
        <AddSubscriberContainer>
          {connections.length > 10 && (
            <SearchField
              placeholder='Search Connections'
              slotProps={{
                input: {
                  startAdornment: <SearchIcon />,
                  endAdornment: (
                    <IconButton size='small' onClick={async () => {
                      setSearchTerm('');
                      search('');
                    }}>
                      <CloseIcon />
                    </IconButton>
                  )
                }
              }}
              value={searchTerm}
              onChange={(event) => search(event.target.value)}
            />
          )}
          {!canAddSubscribers && (
            <Typography>All connections are already subscribed</Typography>
          )}
          <ListContainer dense>
            {canAddSubscribers &&
              connections.map((subscriber: NetworkedUserInfo) => {
                const isAlreadySubscribed = forum.subscriberIds?.some(r => r.isEqualTo(subscriber.userId));
                if (isAlreadySubscribed) return null;

                const isSelected = selectedSubscribers?.some(r => r.userId?.isEqualTo(subscriber.userId));
                return (
                  <StyledListItemButton
                    disableGutters
                    key={subscriber.userId?.value}
                    selected={isSelected}
                    onClick={(event) => handleSubscriberSelected(event, subscriber)}
                  >
                    <ListItemAvatar>
                      {isSelected ?
                        <SelectedIcon><DoneIcon /></SelectedIcon> :
                        renderSubscriberAvatar()
                      }
                    </ListItemAvatar>
                    <ListItemText
                      primary={subscriber.name?.toString()}
                      secondary={subscriber.email?.value}
                    />
                  </StyledListItemButton>
                )

                function renderSubscriberAvatar(): React.ReactNode {
                  return subscriber.avatarId ?
                    <IndividualAvatar
                      avatarId={subscriber.avatarId}
                      individualId={subscriber.individualId}
                      session={session}
                    />
                    : <Avatar>{subscriber.name?.toString().charAt(0)}</Avatar>;
                }
              })}
          </ListContainer>
          {selectedSubscribers.length > 0 && (
            <MessageInput
              multiline
              variant="outlined"
              minRows={3}
              maxRows={6}
              fullWidth
              label="Message (optional)"
              value={customMessage}
              onChange={(event) => setCustomMessage(event.target.value)}
            />
          )}
          <ButtonContainer>
            <LoadingButton
              variant='contained'
              color='primary'
              startIcon={<SaveIcon />}
              loading={isSaving}
              disabled={selectedSubscribers.length < 1 || isSaving}
              onClick={saveChanges}>
              Save
            </LoadingButton>
            <Button
              variant='contained'
              color='primary'
              onClick={onClosed}>
              Cancel
            </Button>
          </ButtonContainer>
        </AddSubscriberContainer>
      }
    </React.Fragment>
  )
}
