import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Date from "common/values/date/date";
import Forum from "messaging/entities/forum/forum";
import moment, { Moment } from "moment";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import CommentThread from "work/entities/comment-thread/comment-thread";
import { ProposalField } from "work/entities/proposal/proposal";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import DateRedlinePicker from "work/entities/proposal/redlining/view/redline-field-inputs/date-redline-picker";
import TextRedlineInput from "work/entities/proposal/redlining/view/redline-field-inputs/text-redline-input";
import { ProposalFieldName } from "work/values/constants";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";

const ProposalContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
  display: "grid",
  gap: theme.spacing(6),
  gridTemplateColumns: "auto auto",
  width: "100%",
}));
const GeneralSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const DateSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& .MuiFormControl-root": {
    marginTop: theme.spacing(1),
  },
}));
const ClientTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
  width: "min-content",
}));
const NameTextField = styled(TextRedlineInput)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const DescriptionTextField = styled(TextRedlineInput)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));

type DetailsTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  issues?: ProposalIssues;
  activeReviewField: ProposalField | undefined;
  commentThreads: CommentThread[];
  disableEditing?: boolean;
  onProposalRedlineChange: (
    newRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onTraverseToNewField: (fieldInfo: ProposalField | undefined) => void;
  onCommentsClicked: (
    field: ProposalField,
    title?: string,
    setToOpen?: boolean
  ) => void;

  clientName: string;
};

const DetailsTab = (props: Readonly<DetailsTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    commentThreads,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    onCommentsClicked,

    clientName,
  } = props;

  if (!isOpen) return null;

  return (
    proposalRedline && (
      <ProposalContainer>
        <GeneralSection>
          {clientName && (
            <ClientTextField label="Client" value={clientName} disabled />
          )}
          <NameTextField
            focused={activeReviewField?.name === ProposalFieldName.Name}
            required={!disableEditing}
            helperText={!disableEditing ? "required" : ""}
            disabled={disableEditing}
            readOnly={disableEditing}
            originalTextFieldRedline={proposalRedline.name}
            issues={props.issues?.entries.filter((entry) =>
              entry.field.isEqualTo(ProposalField.Name)
            )}
            onCommentsClicked={(setToOpen?: boolean) =>
              onCommentsClicked(ProposalField.Name, undefined, setToOpen)
            }
            commentThreads={commentThreads}
            onFocus={() => {
              onCommentsClicked(ProposalField.Name, undefined, true);
            }}
            onTextFieldRedlineChange={(
              updatedRedline: FieldRedline<ProjectName>,
              traversalFieldOverride?: ProposalField | null
            ) => {
              try {
                onProposalRedlineChange(
                  proposalRedline.updateNameRedline(updatedRedline),
                  traversalFieldOverride
                );
              } catch (error) {
                console.error("error", error);
                enqueueSnackbar("Error updating proposal name", {
                  variant: "error",
                });
              }
            }}
            onTraverseIn={() => {
              onTraverseToNewField(ProposalField.Name);
            }}
          />

          <DescriptionTextField
            focused={activeReviewField?.name === ProposalFieldName.Description}
            required={!disableEditing}
            multiline={true}
            helperText={!disableEditing ? "required" : ""}
            disabled={disableEditing}
            readOnly={disableEditing}
            minRows={3}
            originalTextFieldRedline={proposalRedline.description}
            issues={props.issues?.entries.filter((entry) =>
              entry.field.isEqualTo(ProposalField.Description)
            )}
            onCommentsClicked={(setToOpen?: boolean) =>
              onCommentsClicked(ProposalField.Description, undefined, setToOpen)
            }
            commentThreads={commentThreads}
            onTextFieldRedlineChange={(
              updatedRedline: FieldRedline<ProjectDescription>,
              traversalFieldOverride?: ProposalField | null
            ) => {
              try {
                onProposalRedlineChange(
                  proposalRedline.updateDescriptionRedline(updatedRedline),
                  traversalFieldOverride
                );
              } catch (error) {
                console.error("error", error);
                enqueueSnackbar("Error updating proposal description", {
                  variant: "error",
                });
              }
            }}
            onTraverseIn={() => {
              onTraverseToNewField(ProposalField.Description);
            }}
          />
        </GeneralSection>
        <DateSection>
          <DateRedlinePicker
            focused={
              activeReviewField?.name === ProposalFieldName.ResponseDueBy
            }
            disabled={disableEditing}
            readOnly={disableEditing}
            dateRedline={proposalRedline.responseDueBy}
            issues={props.issues?.entries.filter((entry) =>
              entry.field.isEqualTo(ProposalField.ResponseDueBy)
            )}
            minDate={moment()}
            onCommentsClicked={(setToOpen?: boolean) =>
              onCommentsClicked(
                ProposalField.ResponseDueBy,
                undefined,
                setToOpen
              )
            }
            commentThreads={commentThreads}
            onDateRedlineChange={(
              updatedRedline: FieldRedline<Date>,
              traversalFieldOverride?: ProposalField
            ) => {
              try {
                onProposalRedlineChange(
                  proposalRedline.updateResponseDueByRedline(updatedRedline),
                  traversalFieldOverride
                );
              } catch (error) {
                console.error("error", error);
                enqueueSnackbar("Error updating proposal response due by", {
                  variant: "error",
                });
              }
            }}
            onTraverseIn={() => {
              onTraverseToNewField(ProposalField.ResponseDueBy);
            }}
          />

          <DateRedlinePicker
            focused={activeReviewField?.name === ProposalFieldName.StartDate}
            disabled={disableEditing}
            dateRedline={proposalRedline.startDate}
            issues={props.issues?.entries.filter((entry) =>
              entry.field.isEqualTo(ProposalField.StartDate)
            )}
            minDate={moment(proposalRedline.responseDueBy.currentEntry?.value)}
            onCommentsClicked={(setToOpen?: boolean) =>
              onCommentsClicked(ProposalField.StartDate, undefined, setToOpen)
            }
            commentThreads={commentThreads}
            onDateRedlineChange={(
              updatedRedline: FieldRedline<Date>,
              traversalFieldOverride?: ProposalField
            ) => {
              try {
                onProposalRedlineChange(
                  proposalRedline.updateStartDateRedline(updatedRedline),
                  traversalFieldOverride
                );
              } catch (error) {
                console.error("error", error);
                enqueueSnackbar("Error updating proposal start date", {
                  variant: "error",
                });
              }
            }}
            onTraverseIn={() => {
              onTraverseToNewField(ProposalField.StartDate);
            }}
          />

          <DateRedlinePicker
            focused={activeReviewField?.name === ProposalFieldName.EndDate}
            disabled={disableEditing}
            dateRedline={proposalRedline.endDate}
            issues={props.issues?.entries.filter((entry) =>
              entry.field.isEqualTo(ProposalField.EndDate)
            )}
            minDate={moment(proposalRedline.startDate.currentEntry?.value)}
            onCommentsClicked={(setToOpen?: boolean) =>
              onCommentsClicked(
                ProposalField.EndDate,
                undefined,
                setToOpen
              )
            }
            commentThreads={commentThreads}
            onDateRedlineChange={(
              updatedRedline: FieldRedline<Date>,
              traversalFieldOverride?: ProposalField
            ) => {
              try {
                onProposalRedlineChange(
                  proposalRedline.updateEndDateRedline(updatedRedline),
                  traversalFieldOverride
                );
              } catch (error) {
                console.error("error", error);
                enqueueSnackbar("Error updating proposal end date", {
                  variant: "error",
                });
              }
            }}
            onTraverseIn={() => {
              onTraverseToNewField(ProposalField.EndDate);
            }}
          />
        </DateSection>
      </ProposalContainer>
    )
  );
};

export default DetailsTab;
