import CompanyProfile from "marketplace/values/company-profile/company-profile";
import MarketplaceResponseAPIResponse from "marketplace/api/response-contracts/marketplace-response-api-response";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";

import Guid from "common/values/guid/guid";

export default class MarketplaceCompanyInfoAPIResponse extends MarketplaceResponseAPIResponse {
    name?: string;
    yearEstablished?: number;
    numberOfEmployees?: number;
    contact?: MarketplaceIndividualInfoAPIResponse;
    description?: string;
    location?: string;
    website?: string;
    avatar?: string;
    categories?: string[];
    
    deserializeToProfile(): CompanyProfile {
        if (!this.name) throw new Error("Name is required, was not returned by the API");
        if (!this.id) throw new Error("Id is required, was not returned by the API");

        return new CompanyProfile(
            this.name,
            this.description,
            this.location,
            this.yearEstablished,
            this.website,
            this.numberOfEmployees,
            this.categories,
            this.avatar ? new Guid(this.avatar) : undefined,
            this.contact?.userId ? new Guid(this.contact.userId) : undefined
        );
    }
}
