import Guid from "common/values/guid/guid";
import _ from "lodash";
import Individual from "marketplace/entities/individual/individual";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import Team from "work/values/team/team";

export default class DetailedTeam {
  leader?: Individual;
  memberUserIds: Guid[];
  members: Individual[] = [];
  marketplaceId?: Guid;
  description?: string;

  constructor(
    leader: Individual | undefined,
    members: Individual[],
    marketplaceId?: Guid,
    description?: string
  ) {
    this.leader = leader;
    this.memberUserIds = members
      .filter((m) => m.userId)
      .map((m) => m.userId);
    this.members = members;
    this.marketplaceId = marketplaceId;
    this.description = description;
  }

  toTeam(): Team {
    if (!this.leader) throw new Error("Leader is required");
    if (!this.leader.userId) throw new Error("Leader userId is required");
    if (!this.leader.entityId) throw new Error("Leader entityId is required");

    return new Team(
      new EntityVendorRepresentative(this.leader.userId, this.leader.entityId),
      this.memberUserIds,
      this.marketplaceId
    );
  }

  public isEqualTo(other: DetailedTeam | null | undefined): boolean {
    if (!other) return false;
    if ((!this.leader && other.leader) || (this.leader && !this.leader?.isEqualTo(other.leader))) return false;
    if(this.members.length !== other.members.length) return false;
    for(const member of this.members) {
      if(!other.members.some((m) => m.isEqualTo(member))) return false;
    }
    return true;
  }

  public clone(): DetailedTeam {
    return new DetailedTeam(
      this.leader?.clone(),
      this.members.map((member) => member.clone()),
      this.marketplaceId,
      this.description
    );
  }

  public toJSON(): any {
    return {
      leader: this.leader?.toJSON(),
      members: this.members.map((member) => member.toJSON()),
      marketplaceId: this.marketplaceId?.value,
      description: this.description,
    };
  }
  public static fromJSON(object: any): DetailedTeam {
    const leader = Individual.fromJSON(object.leader);
    if(!leader) throw new Error("Leader is required");
    return new DetailedTeam(
      leader,
      object.members.map((member: any) => Individual.fromJSON(member)),
      object.marketplaceId ? new Guid(object.marketplaceId) : undefined,
      object.description
    );
  }
}
