export default class TechnicalBackgroundInfo {
    private _value: string;
    constructor(value: string) {
        this._value = value;
    }
    public get value(): string {
        return this._value;
    }
    public static fromJSON(object: any) {
        if (!object) return;
        return new TechnicalBackgroundInfo(object);
    }

    public toJSON() {
        return this._value;
    }
}
