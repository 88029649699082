import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";

export type ProposalContextParam = "allactive" | "inbox" | "outbox" | "draftreview" | "activereview" | "draft" | "archive";

export default class ProposalParameters {
  entityId: Guid;
  viewingAs: AccountType;
  context: ProposalContextParam;

  constructor(entityId: Guid, viewingAs: AccountType, context: ProposalContextParam) {
    this.entityId = entityId;
    this.viewingAs = viewingAs;
    this.context = context;
  }

    public asSearchParams(): URLSearchParams {
        const searchParams = new URLSearchParams();
        searchParams.append("entityId", this.entityId.toString());
        searchParams.append("viewingAs", this.viewingAs.toString());
        searchParams.append("context", this.context);
        return searchParams;
    }
}