import Text from "common/values/text/text";

export default class FeeScheduleBillingCode extends Text {
    constructor(value: string) {
        super(value);
    }
    public get isEmpty(): boolean {
        return this.value === '';
    }

    public isEqualTo(other: Text | null): boolean {
        if(!(other instanceof FeeScheduleBillingCode)) return false;
        return super.isEqualTo(other);
    }
    
    public override clone(): FeeScheduleBillingCode {
        return new FeeScheduleBillingCode(this.value);
    }

    public static get Prototype(): FeeScheduleBillingCode {
        return new FeeScheduleBillingCode('Prototype');
    }

    public override fromJSON(object: any): FeeScheduleBillingCode | null {
        if(!object) return object;
        if(typeof object !== 'string') throw new Error('FeeScheduleBillingCode.fromJSON requires a string');
        return new FeeScheduleBillingCode(object);
    }
    public toJSON(): any {
        return this.value;
    }
}