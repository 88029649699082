import Guid from "common/values/guid/guid";

export default class DocumentTopicParameters {
  entityClass?: string;
  entityId?: string;
  context?: string;

  constructor(
    entityClass?: string,
    entityId?: Guid,
    context?: string
    ) {
    this.entityClass = entityClass;
    this.entityId = entityId?.toString();
    this.context = context;
  }

  public asSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    if (this.entityClass) searchParams.append("entityClass", this.entityClass);
    if (this.entityId) searchParams.append("entityId", this.entityId);
    if (this.context) searchParams.append("context", this.context);
    return searchParams;
  }
}
