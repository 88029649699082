export default class NotificationMessage {
    private _value: string;

    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: NotificationMessage): boolean {
        return this.value === other?.value;
    }

    public get value(): string {
        return this._value;
    }

    public static get Prototype(): NotificationMessage {
        return new NotificationMessage("");
    }

    public fromJSON(obj: any): NotificationMessage {
        return new NotificationMessage(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): NotificationMessage {
        return new NotificationMessage(this.value);
    }
}