import { init as initFullStory } from "@fullstory/browser";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/react";
import bookmarksLoader from "app/loaders/bookmarks";
import communicationsLoader from "app/loaders/communications";
import contractsLoader from "app/loaders/contracts";
import dashboardLoader from "app/loaders/dashboard";
import entityAdministrationLoader from "app/loaders/entity-administrator";
import feeScheduleLoader from "app/loaders/fee-schedule";
import invitationLoader from "app/loaders/invitation";
import marketplaceLoader from "app/loaders/marketplace";
import marketplaceProfileLoader from "app/loaders/marketplace-profile";
import marketplaceResultProfileLoader from "app/loaders/marketplace-result-profile";
import networkLoader from "app/loaders/network";
import proposalsLoader from "app/loaders/proposals";
import rootLoader from "app/loaders/root";
import teamsLoader from "app/loaders/teams";
import workLoader from "app/loaders/work";
import ConfirmDialogProvider from "app/providers/confirm-dialog";
import DialogProvider from "app/providers/dialog";
import attorneyHubStore from "app/realtime-store/redux-store";
import Calendar from "app/routes/calendar";
import Communications from "app/routes/communications";
import Contracts from "app/routes/contracts";
import Dashboard from "app/routes/dashboard";
import EntityAdministration from "app/routes/entity-administration";
import ErrorPage from "app/routes/error";
import FeeSchedules from "app/routes/fee-schedules";
import Login from "app/routes/login";
import Marketplace from "app/routes/marketplace";
import Bookmarks from "app/routes/marketplace/bookmarks";
import ManageProfiles from "app/routes/marketplace/profiles";
import Network from "app/routes/network";
import Proposals from "app/routes/proposals/index";
import SuperUserDashboard from "app/routes/super-user";
import Teams from "app/routes/teams";
import Work from "app/routes/work";
import MainTemplate from "app/templates/main";
import { ENVIRONMENT, IS_DEV } from "common/helpers/meta";
import "index.css";
import AdministratorInvitationPage from "legal-entities/entities/administrator-invitation/view/administrator-invitation-page";
import EntityAgreementPage from "legal-entities/entities/entity-agreement/view/entity-agreement-page";
import EntityOfficerInvitationPage from "legal-entities/entities/entity-officer-invitation/view/entity-officer-invitation-page";
import OpenEntityMemberInvitationPage from "legal-entities/entities/open-entity-member-invitation/view/open-entity-member-invitation-page";
import { UserMemberInvitationPage } from "legal-entities/pages";
import TeamInvitationPage from "marketplace/entities/team-invitation/view/team-invitation-page";
import { SnackbarProvider } from "notistack";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { RequireSession, SessionProvider } from "users/session/session-context";

initFullStory({
  orgId: "o-1M2PCX-na1",
  devMode: IS_DEV,
});

Sentry.init({
  dsn: "https://9b4b0f7cb45a485ea5dcef37b8da09d7@o1250895.ingest.sentry.io/6415866",
  environment: ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

let theme = createTheme();
theme = responsiveFontSizes(theme);

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainTemplate />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: "dashboard",
            element: (
              <RequireSession>
                <Dashboard />
              </RequireSession>
            ),
            loader: dashboardLoader,
          },
          {
            path: "communications/:tab/:action/:id",
            element: (
              <RequireSession>
                <Communications />
              </RequireSession>
            ),
            loader: communicationsLoader,
          },
          {
            path: "communications/:tab/:action",
            element: (
              <RequireSession>
                <Communications />
              </RequireSession>
            ),
            loader: communicationsLoader,
          },
          {
            path: "communications/:tab",
            element: (
              <RequireSession>
                <Communications />
              </RequireSession>
            ),
            loader: communicationsLoader,
          },
          {
            index: true,
            path: "communications",
            element: (
              <RequireSession>
                <Communications />
              </RequireSession>
            ),
            loader: communicationsLoader,
          },
          {
            path: "contracts/:tab",
            element: (
              <RequireSession>
                <Contracts />
              </RequireSession>
            ),
            loader: contractsLoader,
          },
          {
            index: true,
            path: "contracts",
            element: (
              <RequireSession>
                <Contracts />
              </RequireSession>
            ),
            loader: contractsLoader,
          },
          {
            path: "work/:tab/:action/:id",
            element: (
              <RequireSession>
                <Work />
              </RequireSession>
            ),
            loader: workLoader,
          },
          {
            path: "work/:tab/:action",
            element: (
              <RequireSession>
                <Work />
              </RequireSession>
            ),
            loader: workLoader,
          },
          {
            path: "work/:tab",
            element: (
              <RequireSession>
                <Work />
              </RequireSession>
            ),
            loader: workLoader,
          },
          {
            index: true,
            path: "work",
            element: (
              <RequireSession>
                <Work />
              </RequireSession>
            ),
            loader: workLoader,
          },
          {
            path: "proposals/:pageTab/:action/:id/:tab",
            element: (
              <RequireSession>
                <Proposals />
              </RequireSession>
            ),
            loader: proposalsLoader,
          },
          {
            path: "proposals/:pageTab/:action/:id",
            element: (
              <RequireSession>
                <Proposals />
              </RequireSession>
            ),
            loader: proposalsLoader,
          },
          {
            path: "proposals/:pageTab/:action",
            element: (
              <RequireSession>
                <Proposals />
              </RequireSession>
            ),
            loader: proposalsLoader,
          },
          {
            path: "proposals/:pageTab",
            element: (
              <RequireSession>
                <Proposals />
              </RequireSession>
            ),
            loader: proposalsLoader,
          },
          {
            index: true,
            path: "proposals",
            element: (
              <RequireSession>
                <Proposals />
              </RequireSession>
            ),
            loader: proposalsLoader,
          },
          {
            path: "teams/:tab/:action/:id",
            element: (
              <RequireSession>
                <Teams />
              </RequireSession>
            ),
            loader: teamsLoader,
          },
          {
            path: "teams/:tab/:action",
            element: (
              <RequireSession>
                <Teams />
              </RequireSession>
            ),
            loader: teamsLoader,
          },
          {
            path: "teams/:tab",
            element: (
              <RequireSession>
                <Teams />
              </RequireSession>
            ),
            loader: teamsLoader,
          },
          {
            index: true,
            path: "teams",
            element: (
              <RequireSession>
                <Teams />
              </RequireSession>
            ),
            loader: teamsLoader,
          },
          {
            path: "fee-schedules/:tab/:action/:id",
            element: (
              <RequireSession>
                <FeeSchedules />
              </RequireSession>
            ),
            loader: feeScheduleLoader,
          },
          {
            path: "fee-schedules/:tab/:action",
            element: (
              <RequireSession>
                <FeeSchedules />
              </RequireSession>
            ),
            loader: feeScheduleLoader,
          },
          {
            path: "fee-schedules/:tab",
            element: (
              <RequireSession>
                <FeeSchedules />
              </RequireSession>
            ),
            loader: feeScheduleLoader,
          },
          {
            index: true,
            path: "fee-schedules",
            element: (
              <RequireSession>
                <FeeSchedules />
              </RequireSession>
            ),
            loader: feeScheduleLoader,
          },
          {
            path: "marketplace/team-invitation/:invitationId",
            element: (
              <RequireSession>
                <TeamInvitationPage />
              </RequireSession>
            ),
          },
          {
            path: "marketplace/profiles/:tab/:action/:id",
            element: (
              <RequireSession>
                <ManageProfiles />
              </RequireSession>
            ),
            loader: marketplaceProfileLoader,
          },
          {
            index: true,
            path: "marketplace/profiles/:tab/:action",
            element: (
              <RequireSession>
                <ManageProfiles />
              </RequireSession>
            ),
            loader: marketplaceProfileLoader,
          },
          {
            path: "marketplace/profiles/:tab",
            element: (
              <RequireSession>
                <ManageProfiles />
              </RequireSession>
            ),
            loader: marketplaceProfileLoader,
          },
          {
            path: "marketplace/profiles",
            element: (
              <RequireSession>
                <ManageProfiles />
              </RequireSession>
            ),
            loader: marketplaceProfileLoader,
          },
          {
            path: "marketplace/bookmarks/:tab",
            element: (
              <RequireSession>
                <Bookmarks />
              </RequireSession>
            ),
            loader: bookmarksLoader,
          },
          {
            index: true,
            path: "marketplace/bookmarks",
            element: (
              <RequireSession>
                <Bookmarks />
              </RequireSession>
            ),
            loader: bookmarksLoader,
          },
          {
            index: true,
            path: "marketplace/:type/:id",
            element: (
              <RequireSession>
                <Marketplace />
              </RequireSession>
            ),
            loader: marketplaceResultProfileLoader,
          },
          {
            index: true,
            path: "marketplace/:type",
            element: (
              <RequireSession>
                <Marketplace />
              </RequireSession>
            ),
            loader: marketplaceResultProfileLoader,
          },
          {
            index: true,
            path: "marketplace",
            element: (
              <RequireSession>
                <Marketplace />
              </RequireSession>
            ),
            loader: marketplaceLoader,
          },
          {
            path: "network/:tab/:action/:id",
            element: (
              <RequireSession>
                <Network />
              </RequireSession>
            ),
            loader: networkLoader,
          },
          {
            path: "network/:tab/:action",
            element: (
              <RequireSession>
                <Network />
              </RequireSession>
            ),
            loader: networkLoader,
          },
          {
            path: "network/:tab",
            element: (
              <RequireSession>
                <Network />
              </RequireSession>
            ),
            loader: networkLoader,
          },
          {
            index: true,
            path: "network",
            element: (
              <RequireSession>
                <Network />
              </RequireSession>
            ),
            loader: networkLoader,
          },
          {
            index: true,
            path: "calendar",
            element: (
              <RequireSession>
                <Calendar />
              </RequireSession>
            ),
          },
          {
            path: "administration/:tab/:action/:id",
            element: (
              <RequireSession>
                <EntityAdministration />
              </RequireSession>
            ),
            loader: entityAdministrationLoader,
          },
          {
            path: "administration/:tab/:action",
            element: (
              <RequireSession>
                <EntityAdministration />
              </RequireSession>
            ),
            loader: entityAdministrationLoader,
          },
          {
            path: "administration/:tab",
            element: (
              <RequireSession>
                <EntityAdministration />
              </RequireSession>
            ),
            loader: entityAdministrationLoader,
          },
          {
            index: true,
            path: "administration",
            element: (
              <RequireSession>
                <EntityAdministration />
              </RequireSession>
            ),
            loader: entityAdministrationLoader,
          },
        ],
      },
    ],
  },
  {
    path: "entity-officer-invitation/:invitationId",
    element: <EntityOfficerInvitationPage />,
    errorElement: <ErrorPage />,
    loader: invitationLoader,
  },
  {
    path: "administrator-invitation/:invitationId",
    element: <AdministratorInvitationPage />,
    errorElement: <ErrorPage />,
    loader: invitationLoader,
  },
  {
    path: "entity-member-invitation/:invitationId",
    element: <OpenEntityMemberInvitationPage />,
    errorElement: <ErrorPage />,
    loader: invitationLoader,
  },
  {
    path: "user-member-invitation/:invitationId",
    element: <UserMemberInvitationPage />,
    errorElement: <ErrorPage />,
    loader: invitationLoader,
  },
  {
    path: "/",
    element: <MainTemplate />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      {
        path: "super-user/:tab(users|entities|invitations|system-notifications)?",
        element: (
          <RequireSession>
            <SuperUserDashboard />
          </RequireSession>
        ),
      },
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: "entity-agreements/:id",
            element: (
              <RequireSession>
                <EntityAgreementPage />
              </RequireSession>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    element: <MainTemplate />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "*",
        element: (
          <ErrorPage error={{ status: 404, statusText: "Page Not Found" }} />
        ),
      },
    ],
  },
]);

const container = document.getElementById("root");
if (!container) {
  throw new Error("Root element not found");
}
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ReduxProvider store={attorneyHubStore}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SnackbarProvider maxSnack={3}>
              <SessionProvider>
                <ConfirmDialogProvider>
                  <DialogProvider>
                    <RouterProvider router={router} />
                  </DialogProvider>
                </ConfirmDialogProvider>
              </SessionProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReduxProvider>
  </React.StrictMode>
);

export {};
