import CommentIcon from "@mui/icons-material/Comment";
import {
  Badge,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { downloadFile } from "common/helpers/utils";
import AHBoolean from "common/values/boolean/boolean";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentSelector from "documents/view/document-selector";
import DocumentUploadOverlay from "documents/view/document-upload-overlay";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useSession } from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import SelectedDocumentList from "work/values/work-document/view/selected-document-list";
import WorkDocument, {
  WorkDocumentType,
} from "work/values/work-document/work-document";

const ListContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "waived",
})<{ waived: boolean }>(({ theme, waived }) => ({
  flexWrap: "nowrap",
  minHeight: "20rem",
  opacity: waived ? 0.25 : 1,
  pointerEvents: waived ? "none" : "auto",
  width: "100%",
}));
const DocumentSelectorContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
}));
const SelectDocs = styled(SelectedDocumentList)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
  maxHeight: "60vh",
  marginLeft: theme.spacing(2),
  overflowY: "auto",
}));
const HeadControls = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
}));
const Uploader = styled(DocumentUploadOverlay)(({ theme }) => ({
  backgroundColor: "rgba(250, 250, 250, 0.5)",
  backdropFilter: "blur(5px) saturate(200%)",
  position: "absolute",
  top: 0,
  left: 0,
  margin: "1rem",
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  zIndex: 9999,
}));

type ConflictsUploadProps = {
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  proposal?: Proposal;
  disableCommenting?: boolean;
  commentThreads?: CommentThread[];
  disableEditing?: boolean;
  onCommentsClicked: (
    field: ProposalField,
    name?: string,
    setToOpen?: boolean
  ) => void;
  onProposalBuilderUpdated: (updatedProposalBuilder: ProposalBuilder) => void;
};

export default function ConflictsTab(props: Readonly<ConflictsUploadProps>) {
  const {
    activeTab,
    proposalBuilder,
    disableCommenting,
    commentThreads,
    disableEditing,
    onCommentsClicked,
    onProposalBuilderUpdated,
  } = props;

  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(
    null
  );
  const [creatingDocumentFromTemplate, setCreatingDocumentFromTemplate] =
    React.useState<Guid | null>(null);

  const confirm = useConfirmDialog();
  const session = useSession();

  async function handleConflictsDocumentRemoved(
    documentId: Guid
  ): Promise<void> {
    const response = await confirm({
      title: "Remove Conflicts Document",
      message: "Are you sure you want to remove this conflicts document?",
    });

    if (response === ConfirmResponse.Cancel) return;
    onProposalBuilderUpdated(
      proposalBuilder.setConflictsDocuments(
        (proposalBuilder.currentSpec.conflictsDocuments ?? []).filter(
          (doc) => !doc.id.isEqualTo(documentId)
        )
      )
    );
    onCommentsClicked(ProposalField.Conflicts, undefined, true);
  }

  async function handleDownloadDocumentById(
    documentId: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const conflictsDocument = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(conflictsDocument);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download conflicts document. Please try again",
        { variant: "error" }
      );
    } finally {
      setIsDownloadingFile(null);
    }
  }

  function handleDocumentSelectionChanged(
    selectedDocs: Document[],
    deselectedDocs: Document[]
  ) {
    let currentDocuments = [
      ...(proposalBuilder.currentSpec?.conflictsDocuments ?? []),
    ];

    for (const selectedDoc of selectedDocs) {
      if (!currentDocuments.some((doc) => doc.id.isEqualTo(selectedDoc.id))) {
        const workDocument = WorkDocument.fromDocument(
          selectedDoc,
          WorkDocumentType.Conflicts
        );
        currentDocuments.push(workDocument);
      }
    }

    for (const deselectedDoc of deselectedDocs) {
      currentDocuments = currentDocuments.filter(
        (doc) => !doc.id.isEqualTo(deselectedDoc.id)
      );
    }

    onProposalBuilderUpdated(
      proposalBuilder.setConflictsDocuments(currentDocuments)
    );
    onCommentsClicked(ProposalField.Conflicts, undefined, true);
  }

  function handleConflictsDocumentAdded(document: Document) {
    if (!(document instanceof Document)) {
      console.error("attempted to add unavailable document to conflicts");
      return;
    }
    const updatedDocuments =
      proposalBuilder.currentSpec.conflictsDocuments.concat(
        WorkDocument.fromDocument(document, WorkDocumentType.Conflicts)
      );

    setCreatingDocumentFromTemplate(null);
    onProposalBuilderUpdated(
      proposalBuilder.setConflictsDocuments(updatedDocuments)
    );
    onCommentsClicked(ProposalField.Conflicts, undefined, true);
  }

  function getShouldShowBadge(): boolean {
    return (
      commentThreads?.some((thread) =>
        thread.field.isEqualTo(ProposalField.Conflicts)
      ) ?? false
    );
  }

  function renderCommentsButton() {
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked(ProposalField.Conflicts);
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={!getShouldShowBadge()}
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  async function handleBeginCreateDocumentFromTemplate(
    templateId: Guid | undefined | null
  ) {
    if (!templateId) return;
    setCreatingDocumentFromTemplate(templateId);
    handleDownloadDocumentById(templateId);
  }

  function renderDocumentSelector() {
    let selectedTemplateIds: Array<Guid> = [];
    proposalBuilder.currentSpec.conflictsDocuments.forEach((doc) => {
      if (doc instanceof Document) {
        selectedTemplateIds = [...selectedTemplateIds, ...doc.templateIds];
      }
    });

    return (
      <DocumentSelector
        documentType="conflicts"
        selectedDocumentIds={proposalBuilder.currentSpec.conflictsDocuments.map(
          (doc) => doc.id
        )}
        selectedTemplateIds={selectedTemplateIds}
        onDocumentSelectionChanged={handleDocumentSelectionChanged}
        onCreateDocumentFromTemplate={handleBeginCreateDocumentFromTemplate}
      />
    );
  }

  async function handleConflictsCheckWaiverChanged(checked: AHBoolean) {
    if (proposalBuilder.currentSpec.conflictsDocuments?.length) {
      const response = await confirm({
        title: "Remove Conflicts Documents?",
        message:
          "Waiving the conflicts check will remove the documents that are already on the proposal",
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    onProposalBuilderUpdated(
      proposalBuilder.setConflictsCheckWaived(checked).setConflictsDocuments([])
    );
    onCommentsClicked(ProposalField.Conflicts, undefined, true);
  }

  if (activeTab !== ProposalFieldCategory.Conflicts) return null;

  return (
    <>
      <HeadControls>
        <FormControlLabel
          control={
            <Switch
              disabled={disableEditing}
              checked={
                proposalBuilder.currentSpec?.conflictsCheckWaived.value ?? false
              }
              onChange={(_event, checked) =>
                handleConflictsCheckWaiverChanged(new AHBoolean(checked))
              }
              color="primary"
            />
          }
          label={
            proposalBuilder.currentSpec?.conflictsCheckWaived.value ? (
              <strong>Conflicts Check Waived</strong>
            ) : (
              "Conflicts Check Not Waived"
            )
          }
        />
        {renderCommentsButton()}
      </HeadControls>
      <ListContainer
        waived={
          proposalBuilder.currentSpec?.conflictsCheckWaived.value ?? false
        }
        container
        direction="row"
      >
        <DocumentSelectorContainer size="grow">
          {!disableEditing && renderDocumentSelector()}
        </DocumentSelectorContainer>
        <Grid>
          <Divider orientation="vertical" />
        </Grid>
        <Grid>
          <SelectDocs
            documents={proposalBuilder.currentSpec.conflictsDocuments ?? []}
            documentType={WorkDocumentType.Conflicts}
            downloadingFileId={isDownloadingFile}
            disableCommenting={disableCommenting}
            commentThreads={commentThreads}
            disableEditing={disableEditing}
            onCommentsClicked={onCommentsClicked}
            onDownload={handleDownloadDocumentById}
            onDocumentRemoved={handleConflictsDocumentRemoved}
            onDocumentAdded={handleConflictsDocumentAdded}
          />
        </Grid>
      </ListContainer>
      {!disableEditing && creatingDocumentFromTemplate && (
        <Uploader
          session={session}
          documentType={WorkDocumentType.Conflicts}
          templateId={creatingDocumentFromTemplate}
          onDocumentUploaded={handleConflictsDocumentAdded}
          onClose={() => setCreatingDocumentFromTemplate(null)}
        />
      )}
    </>
  );
}
