import { jwtDecode, InvalidTokenError } from "jwt-decode";
import { Moment } from "moment";

export default class UserAuthToken {
  _value: string;
  _data: any;
  _expiry: Moment;

  constructor(value: string, expiry: Moment) {
    this._value = value;
    this._data = jwtDecode(value);
    this._expiry = expiry;
  }

  public get hasSuperUserScope(): boolean {
    try {
      if(!this._data.SuperUser) return false;
      return JSON.parse(this._data.SuperUser.toLowerCase());
    } catch (e) {
      if (e instanceof InvalidTokenError) {
        return false;
      } else {
        throw e;
      }
    }
  }

  public get isExpired(): boolean {
    return this._expiry.isBefore();
  }

  public get value(): string {
    return this._value;
  }

  public static fromJSON(obj: any) {
    return new UserAuthToken(obj._value, obj._expiry);
  }

  public toJSON() {
    return {
      _value: this._value,
      _data: this._data,
      _expiry: this._expiry
    };
  }
}
