import AddIcon from "@mui/icons-material/Add";
import AddCommentIcon from "@mui/icons-material/AddComment";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import CommentIcon from "@mui/icons-material/Comment";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceIndividualPendingInvitation from "marketplace/entities/marketplace-individual-pending-invitation/marketplace-individual-pending-invitation";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import InvitationMessagePopover from "marketplace/values/team-profile/view/invitation-message-popover";
import TeamMemberSelection from "marketplace/values/team-profile/view/team-member-selection";
import IndividualAvatar from "marketplace/view/individual-avatar";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { NavigateFunction } from "react-router";
import { SetURLSearchParams } from "react-router-dom";
import { useSession } from "users/session/session-context";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const TeamContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const TeamList = styled(List)(({ theme }) => ({
  margin: 0,
  marginTop: 0,
  minWidth: theme.spacing(40),
  paddingBottom: theme.spacing(3),
}));
const TeamListSubheader = styled(ListSubheader)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  display: "flex",
  fontSize: "1.4em",
  justifyContent: "space-between",
  padding: 0,
  "& > button": {
    height: "fit-content",
    width: "fit-content",
  },
}));
const NoRowsPlaceholder = styled(Typography)(({ theme }) => ({
  fontSize: "1.3em",
  paddingTop: theme.spacing(1),
}));
const TeamMemberLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
}));
const RemoveIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export interface TeamMemberInvite extends Individual {
  message?: string;
}

type EditTeamProps = {
  className?: string;
  leader?: Individual;
  members?: Individual[];
  invited?: TeamMemberInvite[];
  pending?: MarketplaceIndividualPendingInvitation[];
  removedUserIds?: Guid[];
  navigate: NavigateFunction;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  onLeaderReplaced?: (leader: Individual, promoted: boolean) => void;
  onMemberAdded: (member: TeamMemberInvite) => void;
  onMemberUpdated: (member: TeamMemberInvite) => void;
  onMemberRemoved: (userId: Guid) => void;
};

export default function EditTeam(props: Readonly<EditTeamProps>) {
  const {
    className,
    leader,
    members,
    invited,
    pending,
    removedUserIds,
    navigate,
    searchParams,
    setSearchParams,
    onLeaderReplaced,
    onMemberAdded,
    onMemberUpdated,
    onMemberRemoved,
  } = props;

  const [currentMemberEditing, setCurrentMemberEditing] =
    React.useState<TeamMemberInvite>();
  const [selectedLeader, setSelectedLeader] = React.useState<
    Individual | undefined
  >(props.leader);
  const [selectedMembers, setSelectedMembers] = React.useState<
    Individual[] | undefined
  >(props.members);
  const [invitedMembers, setInvitedMembers] = React.useState<
    TeamMemberInvite[] | undefined
  >(props.invited);
  const [pendingInvites, setPendingInvites] = React.useState<
    MarketplaceIndividualPendingInvitation[] | undefined
  >(props.pending);
  const [popoverAnchorElement, setPopoverAnchorElement] =
    React.useState<HTMLElement | null>(null);

  const confirm = useConfirmDialog();
  const { openDialog, closeDialog } = useDialog();
  const session = useSession();

  useEffect(() => {
    setSelectedLeader(leader);
    setSelectedMembers(members);
    setInvitedMembers(invited);
    setPendingInvites(pending);
  }, [leader, members, invited, pending]);

  /**
   * Checks if the user with the given id is already selected or invited to the team
   *
   * @param userId Id of the user to check
   * @returns True if the user is already selected or invited to the team, false otherwise
   */
  function getIsMemberAlreadyOnTeam(userId?: Guid): boolean {
    if (!userId) return false;

    const memberUserIds = [
      ...(selectedMembers ?? []),
      ...(invitedMembers ?? []),
    ].map((member) => member.userId);

    return (
      memberUserIds.some((member) => member?.isEqualTo(userId)) ||
      selectedLeader?.userId?.value === userId.value
    );
  }

  function getSelectedTeamMemberIds(): Guid[] {
    const userIds: Guid[] = selectedMembers?.map((member) => member.userId) ?? [];
    if (selectedLeader?.userId) {
      userIds.push(selectedLeader.userId);
    }
    return userIds;
  }

  /**
   * Handles what happens when add leader is clicked
   */
  function beginLeaderSelection() {
    openDialog({
      title: "Select Leader",
      component: (
        <TeamMemberSelection
          navigate={navigate}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onNewSelection={handleNewLeaderSelected}
          selectLeader={true}
          selectMember={false}
          selectedTeamMemberUserIds={getSelectedTeamMemberIds()}
          closeDialog={closeDialog}
        />
      ),
      contentSxProps: {
        display: "flex",
        minHeight: "60vh",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
    });
  }

  /**
   * Handles when a new leader has been selected
   *
   * @param leader The new leader to replace the current leader with
   */
  async function handleNewLeaderSelected(leader: Individual) {
    if (selectedLeader) {
      const response = await confirm({
        title: "Replace Leader?",
        message: `Do you want to replace the current team leader with ${leader.profile?.firstName} ${leader.profile?.lastName}?`,
        okButtonText: "Replace",
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    setSelectedLeader(leader);

    if (
      selectedMembers?.find((member) => member.userId?.isEqualTo(leader.userId))
    ) {
      setSelectedMembers(
        selectedMembers?.filter(
          (member) => !member.userId?.isEqualTo(leader.userId)
        )
      );
      if (onLeaderReplaced) onLeaderReplaced(leader, false);
      return;
    }

    if (onLeaderReplaced) onLeaderReplaced(leader, false);
  }

  /**
   * Handles what happens when add member is clicked
   */
  function beginMemberSelection() {
    openDialog({
      title: "Select Member",
      component: (
        <TeamMemberSelection
          navigate={navigate}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onNewSelection={handleNewMemberSelected}
          selectLeader={false}
          selectMember={true}
          selectedTeamMemberUserIds={getSelectedTeamMemberIds()}
          closeDialog={closeDialog}
        />
      ),
      contentSxProps: {
        display: "flex",
        minHeight: "60vh",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
    });
  }

  /**
   * Handles when a new member has been selected
   *
   * @param teamMember The new member to add to the team
   */
  function handleNewMemberSelected(teamMember: Individual) {
    if (getIsMemberAlreadyOnTeam(teamMember.userId ?? undefined)) {
      closeDialog();
      enqueueSnackbar("Selected user is already on team", {
        variant: "info",
        preventDuplicate: true,
        autoHideDuration: 7500,
      });
      return;
    }

    setInvitedMembers([...(invitedMembers ?? []), teamMember]);
    onMemberAdded(teamMember);

    closeDialog();
  }

  /**
   * Handles what happens when a member is removed from the team
   *
   * @param userId The userId of the member to remove
   */
  async function handleMemberRemoved(userId?: Guid) {
    if (!userId) return;

    const response = await confirm({
      title: "Remove Member?",
      message: `Do you want to remove the member from the team?`,
      okButtonText: "Remove",
    });

    if (response === ConfirmResponse.Cancel) return;

    const newMembers = selectedMembers?.filter(
      (member) => !member.userId?.isEqualTo(userId)
    );
    setSelectedMembers(newMembers);
    onMemberRemoved(userId);
  }

  /**
   * Handles what happens when the add/edit message popover is opened
   *
   * @param event The event that triggered the popover to open
   * @param member The member to edit the message for
   */
  function handleMessagePopoverOpened(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    member?: TeamMemberInvite
  ) {
    setCurrentMemberEditing(member);
    setPopoverAnchorElement(event?.currentTarget);
  }

  /**
   * Handles what happens when an team member's invitation message is changed
   *
   * @param userId The userId of the member whose message was changed
   * @param message The new message
   */
  function handleInvitationMessageChanged(userId?: Guid, message?: string) {
    setPopoverAnchorElement(null);

    if (!userId || !invitedMembers) return;

    const userIsMember: boolean =
      invitedMembers?.find((c) => c.userId?.isEqualTo(userId)) !== undefined;

    if (!userIsMember) return;

    let targetMember: TeamMemberInvite | undefined;

    const updatedMembers: TeamMemberInvite[] = invitedMembers.map((member) => {
      if (member.userId?.isEqualTo(userId)) {
        const updatedInvite = {
          ...member,
          message: message,
        } as TeamMemberInvite;
        targetMember = updatedInvite;
        return updatedInvite;
      }
      return member;
    });

    if (!targetMember) return;

    setInvitedMembers(updatedMembers);
    onMemberUpdated(targetMember);
  }

  /**
   * Opens the user profile dialog for the given individual
   *
   * @param individualId The id of the individual to open the dialog for
   */
  function openUserProfileDialog(individualId: Guid) {
    openDialog({
      component: (
        <ViewIndividualProfile
          individualId={individualId}
        />
      ),
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
  }

  /**
   * Renders a single individual's info as a list item
   *
   * @param individual The individual's info to render
   * @param session The current session
   * @param allowDelete Whether or not to allow the user to be removed from the team
   */
  function renderIndividual(individual: Individual, allowDelete?: boolean) {
    const isRemoved =
      removedUserIds?.some((id) => id.isEqualTo(individual.userId)) ?? false;

    return (
      <ListItem 
        key={individual.userId?.toString()} 
        disableGutters
        secondaryAction={
          <>
          {!isRemoved && allowDelete && (
            <Tooltip title="Remove Member">
              <span>
                <IconButton
                  edge="end"
                  onClick={() =>
                    handleMemberRemoved(individual.userId ?? undefined)
                  }
                  size="medium"
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          </>
        }
      >
        <ListItemAvatar>
          <IndividualAvatar 
            avatarId={individual.profile?.avatarId}
            individualId={individual.id} 
            session={session} 
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink
                onClick={() => openUserProfileDialog(individual.id)}
              >
                {individual.profile?.firstName} {individual.profile?.lastName}
              </TeamMemberLink>
            </Typography>
          }
          secondary={isRemoved ? "Removing From Team" : "Active Member"}
        ></ListItemText>
      </ListItem>
    );
  }

  /**
   * Renders a single individual's invitation info as a list item
   */
  function renderIndividualInvitation(
    invitation: MarketplaceIndividualPendingInvitation
  ) {
    const isRemoved =
      removedUserIds?.some((id) => id.isEqualTo(invitation.userId)) ?? false;

    return (
      <ListItem 
        key={invitation.userId.value} 
        disableGutters
        secondaryAction={
          <>
          {!isRemoved && (
            <Tooltip title="Cancel Invitation">
              <span>
                <IconButton
                  edge="end"
                  onClick={() => handleMemberRemoved(invitation.userId)}
                  size="medium"
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          </>
        }
      >
        <ListItemAvatar>
          <IndividualAvatar 
            avatarId={invitation.avatarId}
            individualId={invitation.id} 
            session={session} 
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink
                onClick={() => openUserProfileDialog(invitation.id)}
              >
                {invitation.name.toString()}
              </TeamMemberLink>
            </Typography>
          }
          secondary={isRemoved ? "Revoking Invitation" : "Invited"}
        ></ListItemText>
      </ListItem>
    );
  }

  /**
   * Renders a single team member invite as a list item
   *
   * @param member The member to render
   * @returns
   */
  function renderTeamMemberInvite(member: TeamMemberInvite) {
    const isRemoved =
      removedUserIds?.some((id) => id.isEqualTo(member.userId)) ?? false;

    return (
      <ListItem 
        key={member.userId?.toString()} 
        disableGutters
        secondaryAction={
          <>
          {!isRemoved && (
            <>
            <Tooltip
              title={`${
                member.message ? "Edit" : "Add"
              } Custom Invitation Message`}
            >
              <IconButton
                size="medium"
                onClick={(event) => handleMessagePopoverOpened(event, member)}
              >
                {member.message ? (
                  <CommentIcon color="secondary" />
                ) : (
                  <AddCommentIcon color="secondary" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove Invitation">
              <span>
                <IconButton
                  edge="end"
                  onClick={() =>
                    handleMemberRemoved(member.userId ?? undefined)
                  }
                  size="medium"
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>
            </>
          )}
          </>
        }
      >
        <ListItemAvatar>
          <IndividualAvatar 
            avatarId={member.profile?.avatarId}
            individualId={member.id} 
            session={session} 
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink onClick={() => openUserProfileDialog(member.id)}>
                {member.profile?.firstName} {member.profile?.lastName}
              </TeamMemberLink>
            </Typography>
          }
          secondary={isRemoved ? "Revoking Invitation" : "Sending invitation"}
        />
      </ListItem>
    );
  }

  return (
    <MainContainer className={className}>
      <TeamContainer>
        <TeamList>
          <TeamListSubheader>
            <span>Team Leader</span>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              size="small"
              sx={{ paddingRight: 0 }}
              disabled={!onLeaderReplaced}
              onClick={beginLeaderSelection}
            >
              Select Leader
            </Button>
          </TeamListSubheader>
          {!selectedLeader && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {selectedLeader && renderIndividual(selectedLeader)}
        </TeamList>
        <TeamList>
          <TeamListSubheader>
            <span>Team Members</span>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              size="small"
              sx={{ paddingRight: 0 }}
              disabled={!selectedLeader}
              onClick={beginMemberSelection}
            >
              Add Member
            </Button>
          </TeamListSubheader>
          {(!selectedMembers || selectedMembers.length < 1) &&
            (!invitedMembers || invitedMembers.length < 1) &&
            (!pendingInvites || pendingInvites.length < 1) && (
              <ListItem disableGutters>
                <NoRowsPlaceholder>None</NoRowsPlaceholder>
              </ListItem>
            )}
          {selectedMembers?.map((member: Individual) =>
            renderIndividual(member, true)
          )}
          {invitedMembers?.map((member: TeamMemberInvite) =>
            renderTeamMemberInvite(member)
          )}
          {pendingInvites?.map(
            (invitation: MarketplaceIndividualPendingInvitation) =>
              renderIndividualInvitation(invitation)
          )}
        </TeamList>
      </TeamContainer>
      <InvitationMessagePopover
        userId={currentMemberEditing?.userId ?? undefined}
        anchorEl={popoverAnchorElement}
        message={currentMemberEditing?.message}
        onCanceled={() => setPopoverAnchorElement(null)}
        onMessageChanged={(userId?: Guid, message?: string) => {
          handleInvitationMessageChanged(userId, message);
        }}
      />
    </MainContainer>
  );
}
