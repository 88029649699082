import Guid from "common/values/guid/guid";
import { Moment } from "moment";
import Action from "notifications/values/action/action";
import NotificationMessage from "notifications/values/notification-message/notification-message";
import NotificationTopic from "notifications/values/notification-topic/notification-topic";


export default class Notification {
  id: Guid;
  recipientUserId?: Guid;
  message?: NotificationMessage;
  topic?: NotificationTopic | null;
  actionRequired?: Action;
  seenAt?: Moment | null;
  createdDate?: Moment;
  isSeen?: boolean;
  isCanceled?: boolean;

  constructor(
    id: Guid,
    recipientUserId?: Guid,
    message?: NotificationMessage,
    topic?: NotificationTopic | null,
    actionRequired?: Action,
    seenAt?: Moment | null,
    createdDate?: Moment,
    isSeen?: boolean,
    isCanceled?: boolean
  ) {
    this.id = id;
    this.recipientUserId = recipientUserId;
    this.message = message;
    this.topic = topic;
    this.actionRequired = actionRequired;
    this.seenAt = seenAt;
    this.createdDate = createdDate;
    this.isSeen = isSeen;
    this.isCanceled = isCanceled;
  }
}
