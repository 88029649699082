import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Guid from "common/values/guid/guid";
import BookmarkedIndividual from "marketplace/entities/bookmarked-individual/bookmarked-individual";
import Individual from "marketplace/entities/individual/individual";
import { BookmarkedIndividuals } from "marketplace/view/bookmarked-individuals";
import React from "react";
import { NavigateFunction } from "react-router";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import NetworkedIndividuals from "users/entities/user-network-connection/view/components/networked-individuals";
import Marketplace from "work/values/team/view/marketplace";

const PageContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: "auto 1fr",
  width: "100%"
}));
const TabsContainer = styled("div")(({ theme }) => ({
  gridRowStart: 1
}));
const TabContent = styled("div")(({ theme }) => ({
  gridRowStart: 2,
  gridRowEnd: 'none'
}));

type TeamMemberSelectionProps = {
  selectLeader: boolean;
  selectMember: boolean;
  selectedTeamMemberUserIds: Guid[];
  navigate?: NavigateFunction;
  onNewSelection: (teamMember: Individual) => void;
  closeDialog: () => void;
};

export default function TeamMemberSelection(props: Readonly<TeamMemberSelectionProps>) {
  const {
    selectLeader,
    selectMember,
    selectedTeamMemberUserIds,
    navigate,
    onNewSelection,
    closeDialog
  } = props;

  const [activeTab, setActiveTab] = React.useState("marketplace");

  function marketplaceIndividualSelected(individual: Individual) {
    if (!individual.profile) {
      throw new Error("Individual profile is required.");
    }
    onNewSelection(individual);
    closeDialog();
  }

  function bookmarkedIndividualSelected(individual: BookmarkedIndividual) {
    if (!individual.vendor.profile) {
      throw new Error("Bookmarked individual must have a profile");
    }

    onNewSelection(individual.vendor);
    closeDialog();
  }

  async function networkedIndividualSelected(connection: NetworkedUserInfo) {
    if (!connection.individualId || !connection.userId) {
      throw new Error("Networked individual must have an individualId and userId");
    }

    onNewSelection(connection.asIndividual());
    closeDialog();
  }

  return (
    <PageContainer>
      <TabsContainer>
        <Tabs
          indicatorColor="primary"
          variant="scrollable"
          value={activeTab}
          onChange={(event, tab) => setActiveTab(tab)}
          aria-label="Attorney selection nav">
          <Tab value="marketplace" label="Marketplace" />
          <Tab value="network" label="Network" />
          <Tab value="indvBookmarks" label="Bookmarked Individuals" />
        </Tabs>
      </TabsContainer>
      {activeTab === "network" && (
        <TabContent>
          <NetworkedIndividuals 
            selectedTeamMemberUserIds={selectedTeamMemberUserIds}
            onSelected={networkedIndividualSelected} 
          />
        </TabContent>
      )}
      {activeTab === "indvBookmarks" && (
        <TabContent>
          <BookmarkedIndividuals 
            selectedTeamMemberUserIds={selectedTeamMemberUserIds}
            onSelected={bookmarkedIndividualSelected} 
          />
        </TabContent>
      )}
      {activeTab === "marketplace" && (
        <TabContent>
          <Marketplace
            navigate={navigate}
            selectingLeader={selectLeader}
            selectingMember={selectMember}
            selectedTeamMemberUserIds={selectedTeamMemberUserIds}
            selectingTeam={false}
            onTeamMemberSelected={marketplaceIndividualSelected}
            onTeamSelected={() => { }}
          />
        </TabContent>
      )}
    </PageContainer>
  );
}
