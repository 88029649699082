import SendIcon from "@mui/icons-material/Send";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CanceledError } from "axios";
import Loader from "common/components/loader";
import LoadingButton from "common/components/loading-button";
import Guid from "common/values/guid/guid";
import Company from "marketplace/entities/company/company";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import Proposal from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import SelectedRepresentative from "work/values/selected-representative/selected-representative";
import SelectedTeam from "work/values/selected-team/selected-team";
import DetailedTeam from "work/values/team/detailed-team";

const Content = styled("section")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
}));
const ProposalActions = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  flexWrap: "nowrap",
  columnGap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));
const ProposalActionButton = styled(LoadingButton)(({ theme }) => ({
  width: "100%",
  whiteSpace: "nowrap",
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26) !important",
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
  },
}));

type ProposalRequestDraftDialogProps = {
  preSelectedRep?: SelectedRepresentative;
  preSelectedTeam?: SelectedTeam;
  onProposalSubmitted?: (proposal: Proposal) => void;
  onCancel: () => void;
};

export default function ProposalRequestDraftDialog(
  props: Readonly<ProposalRequestDraftDialogProps>
) {
  const { preSelectedRep, preSelectedTeam, onProposalSubmitted, onCancel } =
    props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = React.useState<string>("");

  const session = useSession();

  const proposalBuilder = React.useRef<ProposalBuilder>(
    new ProposalBuilder(session.user)
  );

  useEffect(() => {
    let abortController = new AbortController();
    initProposal(abortController);

    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  async function initProposal(abortController: AbortController) {
    try {
      setIsLoading(true);

      if (preSelectedRep) {
        buildProposalWithPreselectedRep(preSelectedRep);
      }
      if (preSelectedTeam) {
        await buildProposalWithPreselectedTeam(
          preSelectedTeam,
          abortController
        );
      }
    } catch (error: any) {
      if (console.error instanceof CanceledError) return;
      console.error(error);
    } finally {
      if (!abortController.signal.aborted) {
        setIsLoading(false);
      }
    }
  }

  function buildProposalWithPreselectedRep(
    preSelectedRep: SelectedRepresentative
  ) {
    if (preSelectedRep.isClientRep) {
      const clientRep = new EntityClientRepresentative(
        preSelectedRep.userId,
        preSelectedRep.entityId,
        preSelectedRep.name
      );
      proposalBuilder.current = proposalBuilder.current.setClient(clientRep);
    } else {
      const profile = new IndividualProfile(preSelectedRep.userId, preSelectedRep.name.firstName ?? "", preSelectedRep.name.lastName ?? "");
      profile.entityId = preSelectedRep.entityId;
      const company = new Company(Guid.generate(), preSelectedRep.entityId);
      const leader = new Individual(preSelectedRep.userId, preSelectedRep.userId, profile, company);
      if (!session.user?.asClientRepresentative) {
        throw new Error("User is not a client representative");
      }
      proposalBuilder.current = proposalBuilder.current
        .setClient(session.user?.asClientRepresentative)
        .setName(new ProjectName("New Proposal"))
        .setTeam(new DetailedTeam(leader, []));
    }
  }
  async function buildProposalWithPreselectedTeam(
    preSelectedTeam: SelectedTeam,
    abortController: AbortController
  ) {
    try {
      const marketplaceTeamAPIService = new MarketplaceTeamAPIService(session);
      const team = await marketplaceTeamAPIService.getTeamById(
        preSelectedTeam.targetTeamId,
        abortController
      );
      if (
        !session.user?.asClientRepresentative ||
        !team.leader?.userId ||
        !team.leader?.entityId
      )
        return;

      proposalBuilder.current = proposalBuilder.current
        .setClient(session.user?.asClientRepresentative)
        .setName(new ProjectName("New Proposal"))
        .setTeam(team.toDetailedTeam());
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
  }

  async function saveProposal(): Promise<Proposal | undefined> {
    try {
      return proposalBuilder.current.buildDraft(session).save(session);
    } catch (error: any) {
      console.error("Failed to save proposal draft: ", error);
      enqueueSnackbar("Failed to save draft", { variant: "error" });
    }
  }

  async function handleSubmitClicked() {
    try {
      setIsSubmitting(true);
      const issues = ProposalIssues.fromBuilder(proposalBuilder.current);
      if (!issues.canSubmit) return;

      const draftProposal = await saveProposal();
      if (!draftProposal) {
        throw new Error("Failed to save draft proposal prior to submission");
      }

      const proposal = await draftProposal.submit(session.user?.id);
      onProposalSubmitted?.(proposal);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to submit proposal", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleDescriptionChanged(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setDescriptionValue(event.target.value);

    proposalBuilder.current = proposalBuilder.current.setDescription(
      new ProjectDescription(event.target.value)
    );
  }

  const issues = ProposalIssues.fromBuilder(proposalBuilder.current);

  return (
    <>
      <Content>
        {isLoading && <Loader />}
        {!isLoading && (
          <TextField
            fullWidth
            placeholder="Enter a brief description of what you need"
            value={descriptionValue}
            onChange={handleDescriptionChanged}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmitClicked();
              }
            }}
          />
        )}
      </Content>
      <ProposalActions>
        <ProposalActionButton
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          loading={isSubmitting}
          disabled={isLoading || !issues.canSubmit}
          onClick={handleSubmitClicked}
        >
          Submit
        </ProposalActionButton>
        <ProposalActionButton
          variant="contained"
          loading={false}
          disabled={isLoading}
          onClick={onCancel}
        >
          Cancel
        </ProposalActionButton>
      </ProposalActions>
    </>
  );
}
