import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { RootState } from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import OpenEntityMemberInvitationAPIService from "legal-entities/entities/open-entity-member-invitation/api/open-entity-member-invitation-api-service";
import OpenEntityMemberInvitation from "legal-entities/entities/open-entity-member-invitation/open-entity-member-invitation";

import Session from "users/session/session";

type OpenEntityMemberInvitationsState = {
    byId: {
        entries: Record<string, OpenEntityMemberInvitation>;
        loading: Record<string, boolean>;
        error: Record<string, SerializedError | null>;
    
    }
    byEntityId: {
        entries: Record<string, OpenEntityMemberInvitation[]>;
        loading: Record<string, boolean>;
        error: Record<string, SerializedError | null>;
    }
};

const initialState: OpenEntityMemberInvitationsState = {
    byId: {
        entries: {},
        loading: {},
        error: {}
    },
    byEntityId: {
        entries: {},
        loading: {},
        error: {}
    }
};

const populateOpenEntityMemberInvitationById = createAsyncThunk(
  "openEntityMemberInvitations/getOpenEntityMemberInvitationById",
  async ({session, id}: {session: Session, id: Guid}, thunkAPI) => {
    try {
      const apiService = new OpenEntityMemberInvitationAPIService(session);
      const invitation = await apiService.getOpenMemberInvitationById(id);
      return invitation;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateOpenEntityMemberInvitationsByEntity = createAsyncThunk(
    "openEntityMemberInvitations/getOpenEntityMemberInvitationsForLegalEntity",
    async ({session, entity}: {session: Session, entity: LegalEntity}, thunkAPI) => {
      try {
        const apiService = new OpenEntityMemberInvitationAPIService(session);
        const invitations = await apiService.getAllOpenEntityMemberInvitationAsync(entity);
        return invitations;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
const openEntityMemberInvitationsSlice = createSlice({
  name: "openEntityMemberInvitations",
  initialState,
  reducers: {
    addOpenEntityMemberInvitation: (state, action: PayloadAction<OpenEntityMemberInvitation>) => {
        if(!action.payload.id?.value){
            return;
        }
        state.byId.entries[action.payload.id?.value] = action.payload;

        const entityId = action.payload.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = state.byEntityId.entries[entityId]?.filter((existingInvitation) => !existingInvitation.id?.isEqualTo(action.payload.id)) ?? [];
        state.byEntityId.entries[entityId].push(action.payload);
        
    },
    removeOpenEntityMemberInvitation: (state, action: PayloadAction<OpenEntityMemberInvitation>) => {
        if(!action.payload.id?.value){
            return;
        }
        delete state.byId.entries[action.payload.id?.value];

        const entityId = action.payload.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = state.byEntityId.entries[entityId]?.filter((existingInvitation) => !existingInvitation.id?.isEqualTo(action.payload.id)) ?? [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(populateOpenEntityMemberInvitationById.pending, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
          return;
        }
        state.byId.loading[invitationId] = true;
        state.byId.error[invitationId] = null;
    });
    builder.addCase(populateOpenEntityMemberInvitationById.fulfilled, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.byId.entries[invitationId] = action.payload;
        state.byId.loading[invitationId] = false;
        state.byId.error[invitationId] = null;

        const entityId = action.payload.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = state.byEntityId.entries[entityId]?.filter((existingInvitation) => !existingInvitation.id?.isEqualTo(action.payload.id)) ?? [];
        state.byEntityId.entries[entityId].push(action.payload);
    });
    builder.addCase(populateOpenEntityMemberInvitationById.rejected, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.byId.loading[invitationId] = false;
        state.byId.error[invitationId] = action.error;
    });
    
    builder.addCase(populateOpenEntityMemberInvitationsByEntity.pending, (state, action) => {
        const entityId = action.meta.arg.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.loading[entityId] = true;
        state.byEntityId.error[entityId] = null;
    });
    builder.addCase(populateOpenEntityMemberInvitationsByEntity.fulfilled, (state, action) => {
        const entityId = action.meta.arg.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = action.payload;
        state.byEntityId.loading[entityId] = false;
        state.byEntityId.error[entityId] = null;

        action.payload.forEach((invitation) => {
            if(!invitation.id?.value){
                return;
            }
            state.byId.entries[invitation.id?.value] = invitation;
        });
    });
    builder.addCase(populateOpenEntityMemberInvitationsByEntity.rejected, (state, action) => {
        const entityId = action.meta.arg.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.loading[entityId] = false;
        state.byEntityId.error[entityId] = action.error;
    });
  }
});

export const {
    addOpenEntityMemberInvitation,
    removeOpenEntityMemberInvitation,
  } = openEntityMemberInvitationsSlice.actions;
  
  export const getOpenEntityMemberInvitationById = (id: Guid) =>
      (state: RootState) =>
        state.openEntityMemberInvitations.byId.entries[id.value]
  export const getIsLoadingOpenEntityMemberInvitationById = (id: Guid) =>
      (state: RootState) =>
        state.openEntityMemberInvitations.byId.loading[id.value]
  export const getErrorLoadingOpenEntityMemberInvitationById = (id: Guid) =>
      (state: RootState) => state.openEntityMemberInvitations.byId.error[id.value]
  
  export const getOpenEntityMemberInvitationsForLegalEntity = (
    entity: LegalEntity
  ) =>
      (state: RootState) =>
        state.entityOfficerInvitations.entries[entity.id?.value]
  export const getIsLoadingOpenEntityMemberInvitationsForLegalEntity = (
    entity: LegalEntity
  ) =>
      (state: RootState) =>
        state.entityOfficerInvitations.loading[entity.id?.value]
  export const getErrorLoadingOpenEntityMemberInvitationsForLegalEntity = (
    entity: LegalEntity
  ) =>
      (state: RootState) => state.entityOfficerInvitations.error[entity.id?.value]
  
  export const getUserEntityMemberInvitationById = (id: Guid) =>
      (state: RootState) =>
        state.userEntityMemberInvitations.byId.entries[id.value]
  export const getIsLoadingUserEntityMemberInvitationById = (id: Guid) =>
      (state: RootState) =>
        state.userEntityMemberInvitations.byId.loading[id.value]
  export const getErrorLoadingUserEntityMemberInvitationById = (id: Guid) =>
      (state: RootState) => state.userEntityMemberInvitations.byId.error[id.value]

export default openEntityMemberInvitationsSlice;