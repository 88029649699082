import Guid from 'common/values/guid/guid';
import Individual from 'marketplace/entities/individual/individual';
import CompanyProfile from 'marketplace/values/company-profile/company-profile';
import TeamProfile from "marketplace/values/team-profile/team-profile";
import DetailedTeam from 'work/values/team/detailed-team';

export default class MarketplaceTeam {

  id?: Guid;
  leader: Individual;
  profile: TeamProfile;
  isVisible: boolean;
  memberships: Individual[];
  associatedFirms: CompanyProfile[];

  constructor(
    leader: Individual,
    profile: TeamProfile,
    isVisible: boolean,
    memberships: Individual[],
    associatedFirms?: CompanyProfile[],
  ) {
    this.leader = leader;
    this.profile = profile;
    this.isVisible = isVisible;
    this.memberships = memberships;
    this.associatedFirms = associatedFirms ?? [];
  }

  toDetailedTeam(): DetailedTeam {
    return new DetailedTeam(
      this.leader,
      this.memberships,
      this.id,
      this.profile.description
    );
  }

  public isEqualTo(other: MarketplaceTeam | null | undefined): boolean {
    if (!other) return false;
    return this.leader.isEqualTo(other.leader) &&
      this.memberships.every((member, index) => member.isEqualTo(other.memberships[index]));
  }

  public clone(): MarketplaceTeam {
    return new MarketplaceTeam(
      this.leader.clone(),
      this.profile.clone(),
      this.isVisible,
      this.memberships.map((member) => member.clone()),
      this.associatedFirms.map((firm) => firm.clone())
    );
  }
}
