import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExitIcon from "@mui/icons-material/TransitEnterexit";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Badge,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, lightBlue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import { enqueueSnackbar } from "notistack";
import { useSession } from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import { ProposalField } from "work/entities/proposal/proposal";
import DiffText from "work/entities/proposal/redlining/_diff/view/diff-text";
import FieldRedline, {
  FieldRedlineArray,
} from "work/entities/proposal/redlining/field-redline";
import TeamMemberSelection from "work/values/team/view/team-member-selection";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const TeamContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const TeamList = styled(List)(({ theme }) => ({
  margin: 0,
  marginTop: 0,
  minWidth: theme.spacing(40),
  paddingBottom: theme.spacing(3),
  "&:first-of-type": {
    marginRight: theme.spacing(3),
  },
  [theme.breakpoints.down("lg")]: {
    "&:first-of-type": {
      marginRight: 0,
    },
  },
}));
const TeamListSubheader = styled(ListSubheader)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  display: "flex",
  fontSize: "1.4em",
  justifyContent: "space-between",
  padding: 0,
  "& > button": {
    height: "fit-content",
    width: "fit-content",
  },
}));
const NoRowsPlaceholder = styled(Typography)(({ theme }) => ({
  fontSize: "1.3em",
  paddingTop: theme.spacing(1),
}));
const TeamMemberLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  cursor: "pointer",
  textDecoration: "underline",
  textDecorationColor: theme.palette.common.black,
}));
const MemberAvatar = styled(IndividualAvatar, {
  shouldForwardProp: (prop) => !["removed", "isnewlyremoved", "added", "resolved"].includes(prop.toString()),
})<{
  removed?: boolean;
  isnewlyremoved: boolean;
  added?: boolean;
  resolved?: boolean;
}>(({ removed, isnewlyremoved, added, resolved, theme }) => ({
  backgroundColor: (function () {
    if ((isnewlyremoved || added) && resolved) return lightBlue[700];
    if (removed && !resolved) return theme.palette.error.main;
    if (removed && resolved) return theme.palette.common.black;
    if (added) return green[300];
    return theme.palette.grey[400];
  })(),
}));
const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));
const RedlinedLink = styled(DiffText)(({ theme }) => ({
  "&:hover span": {
    textDecoration: "underline",
    textDecorationColor: theme.palette.common.black,
  },
}));
const RemovedMemberDiff = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isnewlyremoved",
})<{ isnewlyremoved: boolean }>(
  ({ theme, isnewlyremoved }) => ({
    color: theme.palette.text.disabled,
    textDecoration: "line-through",
    textDecorationColor: isnewlyremoved
      ? lightBlue[700]
      : theme.palette.common.black,
  })
);
const MemberListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "focused"
})<{ focused: boolean }>(
  ({ theme, focused }) => ({
    border: focused ? "2px solid" : 0,
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
  })
);

type TeamRedlineSelectionProps = {
  className?: string;
  activeReviewField?: ProposalField;
  teamLeader?: Individual;
  teamMembersRedline: FieldRedlineArray<Individual>;
  disableEditing?: boolean;
  commentThreads?: CommentThread[];
  onCommentsClicked: (memberId?: Guid, name?: string) => void;
  onTeamMembersRedlineChange: (
    newTeamMembersRedline: FieldRedlineArray<Individual>,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onMemberQuitTeam: (memberId: Guid) => void;
  onMemberClicked: (id: Guid) => void;
};

export default function TeamRedlineSelection(
  props: Readonly<TeamRedlineSelectionProps>
) {
  const {
    className,
    activeReviewField,
    teamLeader,
    teamMembersRedline,
    disableEditing,
    commentThreads,
    onCommentsClicked,
    onTeamMembersRedlineChange,
    onMemberQuitTeam,
    onMemberClicked,
  } = props;

  const confirm = useConfirmDialog();
  const { openDialog, closeDialog } = useDialog();
  const session = useSession();

  /**
   * Checks if the user with the given id is already selected or invited to the team
   *
   * @param userId Id of the user to check
   * @returns True if the user is already selected or invited to the team, false otherwise
   */
  function getIsMemberAlreadyOnTeam(userId?: Guid): boolean {
    if (!userId) return false;

    const memberUserIds = teamMembersRedline?.redlines.map((memberRedline) =>
      memberRedline.currentEntry ? memberRedline.currentEntry.userId : undefined
    );

    return (
      (memberUserIds?.some((memberUserId) => memberUserId?.isEqualTo(userId)) ??
        false) ||
      (teamLeader?.userId?.isEqualTo(userId) ?? false)
    );
  }

  function getSelectedTeamMemberUserIds(): Guid[] {
    const userIds: Guid[] = teamMembersRedline?.redlines.map(
      (memberRedline) => memberRedline.currentEntry?.userId
    ).filter(id => id != undefined) ?? [];
    if (teamLeader?.userId) {
      userIds.push(teamLeader.userId);
    }
    return userIds;
  }

  /**
   * Handles what happens when add member is clicked
   */
  function beginMemberSelection() {
    openDialog({
      title: "Select Member",
      component: (
        <TeamMemberSelection
          onNewSelection={handleNewMemberSelected}
          selectLeader={false}
          selectMember={true}
          selectedTeamMemberUserIds={getSelectedTeamMemberUserIds()}
          closeDialog={closeDialog}
        />
      ),
      contentSxProps: {
        display: "flex",
        minHeight: "60vh",
      },
      MuiProps: {
        fullWidth: true,
        maxWidth: "lg",
      },
    });
  }

  /**
   * Handles when a new member has been selected
   *
   * @param newMember The new member to add to the team
   */
  function handleNewMemberSelected(newMember: Individual) {
    if (getIsMemberAlreadyOnTeam(newMember.userId)) {
      closeDialog();
      enqueueSnackbar("Selected user is already on team", {
        variant: "info",
        preventDuplicate: true,
        autoHideDuration: 7500,
      });
      return;
    }

    try {
      onTeamMembersRedlineChange?.(
        teamMembersRedline.addEntry(newMember),
        ProposalField.TeamMember(newMember.id)
      );
    } catch (error) {
      console.error("Error adding new member to team", error);
      enqueueSnackbar("Error adding new member to team", {
        variant: "error",
      });
    }

    closeDialog();
  }

  function handleMemberRemoved(memberId: Guid) {
    try {
      onTeamMembersRedlineChange?.(
        teamMembersRedline.removeEntryByFieldId(memberId),
        null
      );
    } catch (error) {
      console.error("Error removing member from team", error);
      enqueueSnackbar("Error removing member from team", {
        variant: "error",
      });
    }
  }

  async function handleQuitTeam(memberId?: Guid) {
    if (!memberId) return;

    const response = await confirm({
      title: "Leave Proposal?",
      message: `Do you want to leave this proposal?`,
      okButtonText: "Leave",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      onMemberQuitTeam(memberId);
    } catch (error) {
      console.error("Error quitting team", error);
      enqueueSnackbar("Error quitting team", {
        variant: "error",
      });
    }
  }

  function openUserProfileDialog(individualId?: Guid) {
    if (!individualId) return;
    openDialog({
      component: (
        <ViewIndividualProfile
          individualId={individualId}
        />
      ),
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
  }

  function handleUndoChanges(teamMemberRedline: FieldRedline<Individual>) {
    if (!teamMemberRedline.field.id) {
      console.error("Field ID is missing for team member redline");
      enqueueSnackbar("Error undoing changes", { variant: "error" });
      return;
    }
    try {
      onTeamMembersRedlineChange(
        teamMembersRedline.undoRedlineById(teamMemberRedline.field.id),
        !teamMemberRedline.isNewlyAdded ? teamMemberRedline.field : null
      );
    } catch (error) {
      console.error("Error undoing changes", error);
      enqueueSnackbar("Error undoing changes", { variant: "error" });
    }
  }

  function handleUndoAllChanges() {
    try {
      onTeamMembersRedlineChange(
        teamMembersRedline.undoAll(),
        null
      );
    } catch (error) {
      console.error("Error undoing all changes", error);
      enqueueSnackbar("Error undoing all changes", { variant: "error" });
    }
  }

  async function handleRemoveAllMembers() {
    const response = await confirm({
      title: "Remove All Members?",
      message: "Do you want to remove all members from the team?",
      okButtonText: "Remove All",
    });

    if (response === ConfirmResponse.Cancel) return;
    try {
      onTeamMembersRedlineChange?.(
        teamMembersRedline.removeAll(),
        null
      );
    } catch (error) {
      console.error("Error removing all members from team", error);
      enqueueSnackbar("Error removing all members from team", {
        variant: "error",
      });
    }
  }

  function handleRejectAllChanges() {
    try {
      onTeamMembersRedlineChange(teamMembersRedline.rejectAll());
    } catch (error) {
      console.error("Error rejecting all changes", error);
      enqueueSnackbar("Error rejecting all changes", { variant: "error" });
    }
  }

  function handleAcceptAllChanges() {
    try {
      onTeamMembersRedlineChange(teamMembersRedline.acceptAll());
    } catch (error) {
      console.error("Error accepting all changes", error);
      enqueueSnackbar("Error accepting all changes", { variant: "error" });
    }
  }

  function getActionButtonTooltipText(
    isAdded: boolean,
    isRemoved: boolean,
    accept: boolean
  ) {
    const verb = accept ? "Accept " : "Reject ";

    if (isAdded) return `${verb} New Member`;
    if (isRemoved) return `${verb} Removed Member`;
    return `${verb} All Changes`;
  }

  function renderTeamMemberRedline(
    teamMemberRedline: FieldRedline<Individual>
  ) {
    if (!teamMemberRedline.field.id) {
      console.error("Field ID is missing for team member redline");
      return;
    }
    return (
      <MemberListItem
        key={teamMemberRedline.field.id.value}
        focused={
          activeReviewField?.id?.isEqualTo(teamMemberRedline.field.id) ?? false
        }
        disableGutters
        onClick={(event) => {
          if (!teamMemberRedline.field.id) return;
          onMemberClicked(teamMemberRedline.field.id);
        }}
        secondaryAction={
          <>
          {teamMemberRedline.currentEntry?.userId?.isEqualTo(
            session.user?.id
          ) && (
              <Tooltip title="Quit Team">
                <span>
                  <ActionButton
                    onClick={() =>
                      handleQuitTeam(
                        teamMemberRedline.currentEntry?.userId ?? undefined
                      )
                    }
                  >
                    <ExitIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
          {!disableEditing && (
            <>
              {teamMemberRedline.isResolved &&
                !teamMemberRedline.isRemoved &&
                !teamMemberRedline.canBeUndone && (
                  <Tooltip title="Remove Member">
                    <span>
                      <ActionButton
                        onClick={() => {
                          if (teamMemberRedline.currentEntry) {
                            handleMemberRemoved(
                              teamMemberRedline.currentEntry?.id ?? undefined
                            );
                          }
                        }}
                      >
                        <DeleteOutlineIcon />
                      </ActionButton>
                    </span>
                  </Tooltip>
                )}
              {teamMemberRedline.canBeUndone && (
                <Tooltip title="Undo Changes">
                  <span>
                    <ActionButton
                      onClick={() => {
                        try {
                          handleUndoChanges(teamMemberRedline);
                        } catch (error) {
                          console.error("Error undoing changes", error);
                          enqueueSnackbar("Error undoing changes", {
                            variant: "error",
                          });
                        }
                      }}
                    >
                      <UndoIcon />
                    </ActionButton>
                  </span>
                </Tooltip>
              )}
              {!teamMemberRedline.isResolved &&
                (teamMemberRedline.isAdded || teamMemberRedline.isRemoved) && (
                  <>
                    <Tooltip
                      title={getActionButtonTooltipText(
                        !!teamMemberRedline.isAdded,
                        !!teamMemberRedline.isRemoved,
                        true
                      )}
                    >
                      <span>
                        <ActionButton
                          onClick={() => {
                            if (!teamMemberRedline.field.id) {
                              console.error(
                                "Field ID is missing for team member redline"
                              );
                              return;
                            }
                            try {
                              onTeamMembersRedlineChange(
                                teamMembersRedline.acceptRedlineById(
                                  teamMemberRedline.field.id
                                )
                              );
                            } catch (error) {
                              console.error("Error accepting changes", error);
                              enqueueSnackbar("Error accepting changes", {
                                variant: "error",
                              });
                            }
                          }}
                        >
                          <CheckIcon color="success" />
                        </ActionButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={getActionButtonTooltipText(
                        !!teamMemberRedline.isAdded,
                        !!teamMemberRedline.isRemoved,
                        false
                      )}
                    >
                      <span>
                        <ActionButton
                          onClick={() => {
                            if (!teamMemberRedline.field.id) {
                              console.error(
                                "Field ID is missing for team member redline"
                              );
                              enqueueSnackbar("Error rejecting changes", {
                                variant: "error",
                              });
                              return;
                            }
                            try {
                              onTeamMembersRedlineChange(
                                teamMembersRedline.rejectRedlineById(
                                  teamMemberRedline.field.id
                                )
                              );
                            } catch (error) {
                              console.error("Error rejecting changes", error);
                              enqueueSnackbar("Error rejecting changes", {
                                variant: "error",
                              });
                            }
                          }}
                        >
                          <CloseIcon color="error" />
                        </ActionButton>
                      </span>
                    </Tooltip>
                  </>
                )}
            </>
          )}
          {renderCommentButton(teamMemberRedline)}
          </>
        }
      >
        <ListItemAvatar>
          <MemberAvatar
            avatarId={teamMemberRedline.currentEntry?.profile?.avatarId}
            individualId={teamMemberRedline.currentEntry?.id}
            added={teamMemberRedline.isAdded}
            isnewlyremoved={teamMemberRedline.isNewlyRemoved}
            removed={teamMemberRedline.isRemoved}
            resolved={teamMemberRedline.isResolved}
            session={session}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            teamMemberRedline.isRemoved && teamMemberRedline.isResolved ? (
              <RemovedMemberDiff
                isnewlyremoved={teamMemberRedline.isNewlyRemoved}
              >
                {teamMemberRedline.revisedEntry?.getFullName() ??
                  teamMemberRedline.originalEntry?.getFullName()}
              </RemovedMemberDiff>
            ) : (
              <TeamMemberLink
                onClick={() =>
                  openUserProfileDialog(
                    teamMemberRedline.currentEntry?.id ??
                    teamMemberRedline.revisedEntry?.id ??
                    teamMemberRedline.originalEntry?.id ??
                    undefined
                  )
                }
              >
                <RedlinedLink
                  changes={teamMemberRedline.changes}
                  allResolved={teamMemberRedline.isResolved}
                  disablePopover={true}
                />
              </TeamMemberLink>
            )
          }
        ></ListItemText>
      </MemberListItem>
    );
  }

  function renderLeader() {
    if (!teamLeader) return null;

    return (
      <ListItem 
        disableGutters
        secondaryAction={
          renderLeaderCommentButton()
        }
      >
        <ListItemAvatar>
          <IndividualAvatar
            avatarId={teamLeader.profile?.avatarId}
            individualId={teamLeader.id}
            session={session}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <TeamMemberLink
              onClick={() => openUserProfileDialog(teamLeader?.id ?? undefined)}
            >
              {`${teamLeader.profile?.firstName} ${teamLeader.profile?.lastName}`}
            </TeamMemberLink>
          }
        ></ListItemText>
      </ListItem>
    );
  }

  function renderCommentButton(memberRedline?: FieldRedline<Individual>) {
    return (
      <Tooltip title="Comments">
        <span>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked?.(
                memberRedline?.field.id ?? undefined,
                memberRedline?.currentEntry?.profile?.getFullName() ??
                memberRedline?.revisedEntry?.profile?.getFullName() ??
                memberRedline?.originalEntry?.profile?.getFullName()
              );
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={
                !commentThreads?.some((thread) => thread.field.isEqualTo(memberRedline?.field))
              }
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderLeaderCommentButton() {
    return (
      <Tooltip title="Comments">
        <span>
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked?.(
                teamLeader?.id,
                teamLeader?.profile?.getFullName()
              );
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={
                !teamLeader ||
                !commentThreads?.some((thread) => thread.field.isEqualTo(ProposalField.TeamLeader))
              }
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <MainContainer className={className}>
      <TeamContainer>
        <TeamList>
          <TeamListSubheader>Team Leader</TeamListSubheader>
          {renderLeader()}
        </TeamList>
        <TeamList>
          <TeamListSubheader>
            <span>Team Members</span>
            <div>
              {!disableEditing && (
                <>
                  {teamMembersRedline.isResolved &&
                    !teamMembersRedline.isEmpty && (
                      <Tooltip title="Remove All Members">
                        <span>
                          <ActionButton onClick={handleRemoveAllMembers}>
                            <DeleteOutlineIcon />
                          </ActionButton>
                        </span>
                      </Tooltip>
                    )}
                  {!teamMembersRedline.isResolved && (
                    <>
                      <Tooltip title="Accept All Changes">
                        <span>
                          <ActionButton
                            onClick={() => handleAcceptAllChanges()}
                          >
                            <CheckIcon color="success" />
                          </ActionButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Reject All Changes">
                        <span>
                          <ActionButton
                            onClick={() => handleRejectAllChanges()}
                          >
                            <CloseIcon color="error" />
                          </ActionButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                  {teamMembersRedline.redlines.some((r) => r.canBeUndone) && (
                    <Tooltip title="Undo All">
                      <span>
                        <ActionButton onClick={() => handleUndoAllChanges()}>
                          <UndoIcon />
                        </ActionButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
              {renderCommentButton()}
            </div>
          </TeamListSubheader>
          {!disableEditing && (
            <ListItemButton disableGutters onClick={beginMemberSelection}>
              <ListItemAvatar>
                <AddIcon color="primary" />
              </ListItemAvatar>
              <ListItemText>
                <Typography color="primary">Add Member</Typography>
              </ListItemText>
            </ListItemButton>
          )}
          {(!teamMembersRedline || teamMembersRedline.redlines.length < 1) && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {teamMembersRedline?.redlines.map(
            (member: FieldRedline<Individual>) =>
              renderTeamMemberRedline(member)
          )}
        </TeamList>
      </TeamContainer>
    </MainContainer>
  );
}
