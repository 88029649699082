import Guid from "common/values/guid/guid";
import moment from "moment";
import { Moment } from "moment";
import UserNetworkConnectionProfile from "users/entities/user-network-connection/user-network-connection-profile";
import UserProfile from "users/values/user-profile/user-profile";

export default class UserNetworkConnection {
    public readonly id: Guid;
    public readonly createdDate: Moment;
    public readonly userA: UserProfile;
    public readonly userB: UserProfile;

    constructor(
        id: Guid,
        createdDate: Moment,
        userA: UserProfile,
        userB: UserProfile
    ) {
        this.id = id;
        this.createdDate = createdDate;
        this.userA = userA;
        this.userB = userB;
    }

    toProfileConnection(localUserId?: Guid): UserNetworkConnectionProfile {
        if (!localUserId)
            throw new InvalidUserNetworkConnectionError({
                localUserId: 'Local user id is required to create conneciton profile'
            });
        const otherUserProfile = this.userA.id !== localUserId ? this.userA : this.userB;
        return new UserNetworkConnectionProfile(
            this.id,
            otherUserProfile,
            this.createdDate
        );
    }

    static fromJSON(connection: any): UserNetworkConnection {
        if (!connection.id)
            throw new InvalidUserNetworkConnectionError({ id: 'Id is required.' });
        if (!connection.userA)
            throw new InvalidUserNetworkConnectionError({ userA: 'User A is required.' });
        if (!connection.userB)
            throw new InvalidUserNetworkConnectionError({ userB: 'User B is required.' });

        const id = Guid.fromJSON(connection.id);
        if (!id)
            throw new InvalidUserNetworkConnectionError({ id: 'Invalid id.' });

        return new UserNetworkConnection(
            id,
            moment(connection.createdDate),
            UserProfile.fromJSON(connection.userA),
            UserProfile.fromJSON(connection.userB)
        );

    }
}

export class InvalidUserNetworkConnectionError extends Error {
    issues: any;

    constructor(issues: any) {
        super(`Invalid user network connection.`);
        this.issues = issues;
    }
}
