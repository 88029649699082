import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from "@reduxjs/toolkit";
import { add } from "lodash";
import BookmarkAPIService from "marketplace/entities/bookmark/api/bookmark-api-service";
import BookmarkedCompany from "marketplace/entities/bookmarked-company/bookmarked-company";
import BookmarkedIndividual from "marketplace/entities/bookmarked-individual/bookmarked-individual";
import BookmarkedTeam from "marketplace/entities/bookmarked-team/bookmarked-team";

import Session from "users/session/session";

type BookmarksState = {
  companyBookmarks: {
    entries: BookmarkedCompany[];
    loading: boolean;
    error: SerializedError | null;
  };
  individualBookmarks: {
    entries: BookmarkedIndividual[];
    loading: boolean;
    error: SerializedError | null;
  };
  teamBookmarks: {
    entries: BookmarkedTeam[];
    loading: boolean;
    error: SerializedError | null;
  };
  loading: boolean;
  error: SerializedError | null;
};

const initialState: BookmarksState = {
  companyBookmarks: {
    entries: [],
    loading: false,
    error: null,
  },
  individualBookmarks: {
    entries: [],
    loading: false,
    error: null,
  },
  teamBookmarks: {
    entries: [],
    loading: false,
    error: null,
  },
  loading: false,
  error: null,
};

const populateAllBookmarksForUser = createAsyncThunk(
  "bookmarks/getAllBookmarksForUser",
  async ({ session }: { session: Session }, thunkAPI) => {
    try {
      const apiService = new BookmarkAPIService(session);
      const bookmarks = await apiService.getAllBookmarksForUser();
      return bookmarks;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const populateCompanyBookmarksForUser = createAsyncThunk(
  "bookmarks/getAllCompanyBookmarksForUser",
  async ({ session }: { session: Session }, thunkAPI) => {
    try {
      const apiService = new BookmarkAPIService(session);
      const bookmarks = await apiService.getAllCompanyBookmarksForUser();
      return bookmarks;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const populateIndividualBookmarksForUser = createAsyncThunk(
  "bookmarks/getAllIndividualBookmarksForUser",
  async ({ session }: { session: Session }, thunkAPI) => {
    try {
      const apiService = new BookmarkAPIService(session);
      const bookmarks = await apiService.getAllIndividualBookmarksForUser();
      return bookmarks;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const populateTeamBookmarksForUser = createAsyncThunk(
  "bookmarks/getAllTeamBookmarksForUser",
  async ({ session }: { session: Session }, thunkAPI) => {
    try {
      const apiService = new BookmarkAPIService(session);
      const bookmarks = await apiService.getAllTeamBookmarksForUser();
      return bookmarks;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const bookmarksSlice = createSlice({
  name: "userEntityMemberInvitations",
  initialState,
  reducers: {
    addCompanyBookmark: (state, action) => {
        state.companyBookmarks.entries.push(action.payload);
    },
    removeCompanyBookmark: (state, action) => {
        state.companyBookmarks.entries = state.companyBookmarks.entries.filter((bookmark) => bookmark.id !== action.payload.id);
    },
    addIndividualBookmark: (state, action) => {
        state.individualBookmarks.entries.push(action.payload);
    },
    removeIndividualBookmark: (state, action) => {
        state.individualBookmarks.entries = state.individualBookmarks.entries.filter((bookmark) => bookmark.id !== action.payload.id);
    },
    addTeamBookmark: (state, action) => {
        state.teamBookmarks.entries.push(action.payload);
    },
    removeTeamBookmark: (state, action) => {
        state.teamBookmarks.entries = state.teamBookmarks.entries.filter((bookmark) => bookmark.id !== action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateAllBookmarksForUser.pending, (state, action) => {
      state.loading = true;
      state.error = null;

      state.companyBookmarks.loading = true;
      state.companyBookmarks.error = null;

      state.individualBookmarks.loading = true;
      state.individualBookmarks.error = null;

      state.teamBookmarks.loading = true;
      state.teamBookmarks.error = null;
    });
    builder.addCase(populateAllBookmarksForUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      state.companyBookmarks.entries = action.payload.company;
      state.companyBookmarks.loading = false;
      state.companyBookmarks.error = null;

      state.individualBookmarks.entries = action.payload.individual;
      state.individualBookmarks.loading = false;
      state.individualBookmarks.error = null;

      state.teamBookmarks.entries = action.payload.team;
      state.teamBookmarks.loading = false;
      state.teamBookmarks.error = null;
    });
    builder.addCase(populateAllBookmarksForUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;

      state.companyBookmarks.loading = false;
      state.companyBookmarks.error = action.error;

      state.individualBookmarks.loading = false;
      state.individualBookmarks.error = action.error;

      state.teamBookmarks.loading = false;
      state.teamBookmarks.error = action.error;
    });

    builder.addCase(
      populateCompanyBookmarksForUser.pending,
      (state, action) => {
        state.companyBookmarks.loading = true;
        state.companyBookmarks.error = null;
      }
    );
    builder.addCase(
      populateCompanyBookmarksForUser.fulfilled,
      (state, action) => {
        state.companyBookmarks.entries = action.payload;
        state.companyBookmarks.loading = false;
        state.companyBookmarks.error = null;
      }
    );
    builder.addCase(
      populateCompanyBookmarksForUser.rejected,
      (state, action) => {
        state.companyBookmarks.loading = false;
        state.companyBookmarks.error = action.error;
      }
    );

    builder.addCase(
      populateIndividualBookmarksForUser.pending,
      (state, action) => {
        state.individualBookmarks.loading = true;
        state.individualBookmarks.error = null;
      }
    );
    builder.addCase(
      populateIndividualBookmarksForUser.fulfilled,
      (state, action) => {
        state.individualBookmarks.entries = action.payload;
        state.individualBookmarks.loading = false;
        state.individualBookmarks.error = null;
      }
    );
    builder.addCase(
      populateIndividualBookmarksForUser.rejected,
      (state, action) => {
        state.individualBookmarks.loading = false;
        state.individualBookmarks.error = action.error;
      }
    );

    builder.addCase(populateTeamBookmarksForUser.pending, (state, action) => {
      state.teamBookmarks.loading = true;
      state.teamBookmarks.error = null;
    });
    builder.addCase(populateTeamBookmarksForUser.fulfilled, (state, action) => {
      state.teamBookmarks.entries = action.payload;
      state.teamBookmarks.loading = false;
      state.teamBookmarks.error = null;
    });
    builder.addCase(populateTeamBookmarksForUser.rejected, (state, action) => {
      state.teamBookmarks.loading = false;
      state.teamBookmarks.error = action.error;
    });
  },
});

export default bookmarksSlice;
