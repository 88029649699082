import axios, { AxiosHeaders, CanceledError } from "axios";
import PaginationParameters from "common/contracts/pagination-parameters";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import MessageNotificationAPIResponse from "notifications/entities/message-notification/api/response-contracts/message-notification-api-response";
import MessageNotification from "notifications/entities/message-notification/message-notification";
import NotificationGroupAPIRequest from "notifications/entities/notification/api/request-contracts/notification-group-api-request";
import NotificationParameters from "notifications/entities/notification/api/request-contracts/notification-parameters";
import NotificationAPIResponse from "notifications/entities/notification/api/response-contracts/notification-api-response";
import Notification from "notifications/entities/notification/notification";
import Session from "users/session/session";

export default class NotificationsAPIService {
  private readonly headers: AxiosHeaders = new AxiosHeaders();

  private headersWithJson(): AxiosHeaders {
    return this.headers.concat({ "Content-Type": "application/json" });
  }

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create NotificationsAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async markNotificationAsSeen(notification: Partial<Notification>): Promise<Notification> {
    if (!notification.id) throw new Error('Notification id is required');

    try {
      const url = new URL(`/notifications/${notification.id.value}/mark-seen`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const responseContract: NotificationAPIResponse = Object.assign(new NotificationAPIResponse(), response.data);
      return responseContract.deserialize();
    }
    catch (error: any) {
      if (error?.response?.status === 404) {
        throw new NotificationNotFoundError(notification.id);
      }
      throw new NotificationsAPIServiceError("markNotificationAsSeen", error);
    }
  }

  async markAllNotificationsAsSeen(): Promise<void> {
    const url = new URL(`/notifications/mark-all-seen`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    }
    catch (error: any) {
      if(error?.response?.status === 404) {
        throw new UserNotificationsNotFoundError();
      }
      throw new NotificationsAPIServiceError("markAllNotificationsAsSeen", error);
    }
  }

  async markNotificationAsUnSeen(notification: Partial<Notification>): Promise<Notification> {
    if (!notification.id) throw new Error('Notification id is required');

    try {
      const url = new URL(`/notifications/${notification.id.value}/mark-unseen`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const responseContract: NotificationAPIResponse = Object.assign(new NotificationAPIResponse(), response.data);
      return responseContract.deserialize();
    }
    catch (error: any) {
      if (error?.response?.status === 404) {
        throw new NotificationNotFoundError(notification.id);
      }
      throw new NotificationsAPIServiceError("markNotificationAsUnSeen", error);
    }
  }

  async markNotificationGroupAsSeen(groupInfo: NotificationGroupAPIRequest): Promise<void> {
    const url = new URL(`/notifications/mark-group-seen`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        groupInfo,
        {
          headers: this.headersWithJson()
        }
      );
    }
    catch (error: any) {
      throw new NotificationsAPIServiceError("markNotificationGroupAsSeen", error);
    }
  }

  async archiveNotification(notification: Partial<Notification>): Promise<void> {
    if (!notification.id) throw new Error('Notification id is required');

    try {
      const url = new URL(`/notifications/${notification.id.value}/archive`, AttorneyHubAPIService.apiBaseUrl);
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    }
    catch (error: any) {
      if(error?.response?.status === 404) {
        throw new NotificationNotFoundError(notification.id);
      }
      throw new NotificationsAPIServiceError("archiveNotification", error);
    }
  }

  async unArchiveNotification(notification: Partial<Notification>): Promise<void> {
    if (!notification.id) throw new Error('Notification id is required');

    try {
      const url = new URL(`/notifications/${notification.id.value}/unarchive`, AttorneyHubAPIService.apiBaseUrl);
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    }
    catch (error: any) {
      if(error?.response?.status === 404) {
        throw new NotificationNotFoundError(notification.id);
      }
      throw new NotificationsAPIServiceError("unArchiveNotification", error);
    }
  }

  async getNotificationsForUser(
    paginationParameters: PaginationParameters,
    notificationParameters: NotificationParameters,
    abortController?: AbortController
  ): Promise<Array<Notification | MessageNotification>> {
    try {
      let url = new URL('notifications', AttorneyHubAPIService.apiBaseUrl);
      url.searchParams.append('pageIndex', paginationParameters.pageIndex.toString());
      url.searchParams.append('pageSize', paginationParameters.pageSize.toString());
      url.searchParams.append('boxType', notificationParameters.boxType.toString());
      url.searchParams.append('filterByActionRequired', notificationParameters.filterByActionRequired.toString());
      url.searchParams.append('filterByUnread', notificationParameters.filterByUnread.toString());
      url.searchParams.append('filterByMessageNotifications', notificationParameters.filterByMessageNotifications.toString());

      const response = await axios.get(
        url.toString(), 
        { 
          headers: this.headers,
          signal: abortController?.signal
        });

      return response.data.map(
        (notification: Partial<MessageNotificationAPIResponse>) => {
          if (!notification?.messageInfo?.id) {
            return Object.assign(new NotificationAPIResponse(), notification).deserialize();
          } else {
            return Object.assign(new MessageNotificationAPIResponse(), notification).deserializeAsMessageNotification();
          }
        }
      );
    }
    catch (error: any) {
      if (error?.response?.status === 404) {
        throw new UserNotificationsNotFoundError();
      }
      if(error instanceof CanceledError) {
        throw error;
      }
      throw new NotificationsAPIServiceError("getNotificationsForUser", error);
    }
  }

  async getNotificationById(notificationId: Guid, abortController?: AbortController): Promise<Notification> {
    try {
      const url = new URL(`/notifications/${notificationId.value}`, AttorneyHubAPIService.apiBaseUrl);
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const responseContract: NotificationAPIResponse = Object.assign(new NotificationAPIResponse(), response.data);
      return responseContract.deserialize();
    }
    catch (error: any) {
      if (error?.response?.status === 404) {
        throw new NotificationNotFoundError(notificationId);
      }
      throw new NotificationsAPIServiceError("getNotificationById", error);
    }
  }

  async getArchivedNotificationsForUser(
    paginationParameters: PaginationParameters,
    notificationParameters: NotificationParameters,
    abortController?: AbortController
  ): Promise<Notification[]> {

    try {
      let url = new URL('notifications/archived', AttorneyHubAPIService.apiBaseUrl);
      url.searchParams.append('pageIndex', paginationParameters.pageIndex.toString());
      url.searchParams.append('pageSize', paginationParameters.pageSize.toString());
      url.searchParams.append('boxType', notificationParameters.boxType.toString());
      url.searchParams.append('filterByActionRequired', notificationParameters.filterByActionRequired.toString());

      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return response.data.map(
        (response: Partial<NotificationAPIResponse>) => Object.assign(new NotificationAPIResponse(), response).deserialize()
      );
    }
    catch (error: any) {
      if (error?.response?.status === 404)
        throw new UserNotificationsNotFoundError();
      if(error instanceof CanceledError)
        throw error;
      throw new NotificationsAPIServiceError("getArchivedNotificationsForUser", error);
    }
  }
}

// #region Exceptions

export class NotificationsAPIServiceError extends Error {
  methodName: string;
  error: any;
  constructor(methodName: string, error: any) {
    super(`Error in NotificationsAPIService.${methodName}: ${error}`);
    this.methodName = methodName;
    this.error = error;
  }
}

export class NotificationNotFoundError extends Error {
  public readonly id: Guid;
  constructor(id: Guid) {
    super(`Unable to find notification by id: ${id}`);
    this.id = id;
  }
}

export class UserNotificationsNotFoundError extends Error {
  constructor() {
    super(`User does not have any notifications`);
  }
}

// #endregion
