import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import MessagingAPIService from "messaging/api/messaging-api-service";
import Forum from "messaging/entities/forum/forum";
import Message from "messaging/entities/message/message";
import Session from "users/session/session";

type MessageStoreState = {
  entries: Record<string, Message[]>;
  loading: Record<string, boolean>;
  error: Record<string, SerializedError | null>;
};

const initialState: MessageStoreState = {
  entries: {},
  loading: {},
  error: {},
};
export const populateForumMessages = createAsyncThunk(
  "messages/getMessagesByForum",
  async ({session, forum}: {session: Session, forum: Forum}, thunkAPI) => {
    try {
      const apiService = new MessagingAPIService(session);
      const messages = await apiService.getMessagesByForum(forum);
      return messages;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      const forumId = action.payload.forum.id?.value;
      if(!forumId){
        return;
      }

      let existingForumMessages = state.entries[forumId] || [];
      if(existingForumMessages.find((existingMessage) => existingMessage.id?.isEqualTo(action.payload.id))){
        existingForumMessages = existingForumMessages.filter((existingMessage) => !existingMessage.id?.isEqualTo(action.payload.id));
      }
      existingForumMessages.push(action.payload);
      state.entries[forumId] = existingForumMessages;
    },
    removeMessage: (state, action: PayloadAction<Message>) => {
      const forumId = action.payload.forum.id?.value;
      if(!forumId){
        return;
      }

      let existingForumMessages = state.entries[forumId] || [];
      existingForumMessages = existingForumMessages.filter((existingMessage) => !existingMessage.id?.isEqualTo(action.payload.id));
      state.entries[forumId] = existingForumMessages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateForumMessages.pending, (state, action) => {
      const forumId = action.meta.arg.forum.id?.value;
      if(!forumId){
        return;
      }
      state.loading[forumId] = true;
      state.error[forumId] = null;
    });
    builder.addCase(populateForumMessages.fulfilled, (state, action) => {
      const forumId = action.meta.arg.forum.id?.value;
      if(!forumId){
        return;
      }

      state.loading[forumId] = false;

      let existingForumMessages = state.entries[forumId] || [];

      action.payload.forEach((message) => {
        if(existingForumMessages.find((existingMessage) => existingMessage.id?.isEqualTo(message.id))){
         existingForumMessages = existingForumMessages.filter((existingMessage) => !existingMessage.id?.isEqualTo(message.id)); 
        } 
        existingForumMessages.push(message); 
        state.entries[forumId] = existingForumMessages;
      });
    });
    builder.addCase(populateForumMessages.rejected, (state, action) => {
      const forumId = action.meta.arg.forum.id?.value;
      if(!forumId){
        return;
      }

      state.loading[forumId] = false;
      state.error[forumId] = action.error;
    });
  }
});

export default messagesSlice;