import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import * as Constants from "common/helpers/constants";
import reduxSignalRMiddleware, {
  withCallbacks,
} from "common/realtime/redux-signalr-middleware";
import Guid from "common/values/guid/guid";
import Session from "users/session/session";
import { populateCommentThreadsByProposal } from "work/entities/comment-thread/store/comment-thread-redux-slice";
import ProposalAPIService from "work/entities/proposal/api/proposal-api-service";
import {
  addProposal,
  removeProposal,
  replaceProposalBuilder,
} from "work/entities/proposal/store/proposals-redux-slice";

export const proposalHubConnection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(`${Constants.apiBaseUrl}/proposalHub`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () =>
      Session.loadFromStorage(() => {}).authToken?.value ?? "",
  })
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add("proposal-created", (apiResponseObject: any) => (dispatch, getState) => {
    const session = Session.loadFromStorage(() => {});
    const existingProposal =
      getState().proposals.byId.entries[apiResponseObject.id];
    if (existingProposal) return;
    const apiService = new ProposalAPIService(session);
    apiService
      .getProposalById(new Guid(apiResponseObject.id))
      .then((proposal) => dispatch(addProposal(proposal)));
  })
  .add("proposal-updated", (apiResponseObject: any) => (dispatch, getState) => {
    const session = Session.loadFromStorage(() => {});
    const apiService = new ProposalAPIService(session);

    apiService
      .getProposalById(new Guid(apiResponseObject.id))
      .then((proposal) => {
        const existingProposal = getState().proposals.byId.entries[proposal.id?.value ?? "none"];
        // if (existingProposal.redline) {
        //   dispatch(addProposal(proposal.mergeRedline(existingProposal.redline)));
        // } else {
          dispatch(addProposal(proposal));
        // }

        const existingBuilder = proposal.id?.value
          ? getState().proposals.builders.entries[proposal.id?.value ?? "none"]
          : undefined;
        if (proposal.id && existingBuilder) {
          dispatch(
            replaceProposalBuilder({
              proposalId: proposal.id,
              builder: existingBuilder.mergeUpdatedSpec(proposal.spec),
            })
          );
        }
      });
  })
  .add("proposal-deleted", (apiResponseObject: any) => (dispatch) => {
    const id = new Guid(apiResponseObject.id);
    dispatch(removeProposal(id));
  });

export const proposalsSignalRReduxMiddleware = reduxSignalRMiddleware({
  callbacks,
  connection: proposalHubConnection,
  shouldConnectionStartImmediately: true,
});
