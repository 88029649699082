import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { WritableDraft } from "immer";
import FeeScheduleAPIService from "marketplace/entities/fee-schedule/api/fee-schedule-api-service";
import FeeSchedule from "marketplace/entities/fee-schedule/fee-schedule";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import Session from "users/session/session";

type FeeScheduleStoreState = {
  byTeamId: {
    entries: Record<string, FeeSchedule[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
};

const initialState: FeeScheduleStoreState = {
  byTeamId: {
    entries: {},
    loading: {},
    error: {},
  },
};
export const populateTeamFeeSchedules = createAsyncThunk<
  FeeSchedule[],
  { session: Session; team: MarketplaceTeam }
>(
  "feeSchedules/getFeeSchedulesForTeam",
  async (
    { session, team }: { session: Session; team: MarketplaceTeam },
    thunkAPI
  ) => {
    if (!team.id) {
      return thunkAPI.rejectWithValue(new Error("Team id not found"));
    }
    try {
      const apiService = new FeeScheduleAPIService(session);
      const feeSchedules = await apiService.getFeeSchedulesForTeam(team.id);
      return feeSchedules;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const feeSchedulesSlice = createSlice({
  name: "feeSchedules",
  initialState,
  reducers: {
    addFeeSchedule: (
      state: WritableDraft<FeeScheduleStoreState>,
      action: PayloadAction<{ team: MarketplaceTeam; feeSchedule: FeeSchedule }>
    ) => {
      if (!action.payload.team.id) {
        return;
      }
      state.byTeamId.entries[action.payload.team.id.value].push(
        action.payload.feeSchedule
      );
    },
    removeFeeSchedule: (
      state: WritableDraft<FeeScheduleStoreState>,
      action: PayloadAction<{ team: MarketplaceTeam; feeSchedule: FeeSchedule }>
    ) => {
      if (!action.payload.team.id) {
        return;
      }
      state.byTeamId.entries[action.payload.team.id.value] =
        state.byTeamId.entries[action.payload.team.id.value].filter(
          (feeSchedule) => feeSchedule.id !== action.payload.feeSchedule.id
        );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateTeamFeeSchedules.pending, (state, action) => {
      const teamId = action.meta.arg.team.id?.value;
      if (!teamId) {
        return;
      }
      state.byTeamId.loading[teamId] = true;
      state.byTeamId.error[teamId] = null;
    });
    builder.addCase(populateTeamFeeSchedules.fulfilled, (state, action) => {
      const teamId = action.meta.arg.team.id?.value;
      if (!teamId) {
        return;
      }

      action.payload.forEach((feeSchedule) => {
        state.byTeamId.entries[teamId].push(feeSchedule);
        state.byTeamId.loading[teamId] = false;
        state.byTeamId.error[teamId] = null;
      });
    });

    builder.addCase(populateTeamFeeSchedules.rejected, (state, action) => {
      const teamId = action.meta.arg.team.id?.value;
      if (!teamId) {
        return;
      }
      state.byTeamId.loading[teamId] = false;
      state.byTeamId.error[teamId] = action.error;
    });
  },
});
