import Guid from "common/values/guid/guid";
import moment, { Moment } from "moment";
import UserProfile from "users/values/user-profile/user-profile";

export default class UserNetworkConnectionProfile {
    public readonly id: Guid;
    public readonly createdDate: Moment;
    public readonly user: UserProfile;

    constructor(id: Guid, user: UserProfile, createdDate: Moment) {
        this.id = id;
        this.user = user;
        this.createdDate = createdDate;
    }

    public static fromJSON(object: any) {
        if(!object.id) 
            throw new InvalidUserNetworkConnectionProfileError({ id: 'Id is required.' });
        if(!object.user)
            throw new InvalidUserNetworkConnectionProfileError({ user: 'User is required.' });
        
        const id = Guid.fromJSON(object.id);
        if(!id) 
            throw new InvalidUserNetworkConnectionProfileError({ id: 'Invalid id.' });
        return new UserNetworkConnectionProfile(
            id,
            UserProfile.fromJSON(object.user),
            moment(object.createdDate)
        );
    }

    public toJSON() {
        return {
            id: this.id.toJSON(),
            user: this.user.toJSON(),
            createdDate: this.createdDate.toISOString()
        };
    }
}

export class InvalidUserNetworkConnectionProfileError extends Error {
    issues: any;
    
    constructor(issues : any) {
        super(`Invalid user network connection profile.`);
        this.issues = issues;
    }
}
