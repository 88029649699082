import { configureStore, createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import Session from "users/session/session";
import MessagingAPIService from "messaging/api/messaging-api-service";
import Forum from "messaging/entities/forum/forum";
import { useDispatch, useSelector } from "react-redux";
import Topic from "messaging/values/topic";
import { RootState } from "app/realtime-store/redux-store";
import Message from "messaging/entities/message/message";
import messagesSlice from "messaging/entities/message/realtime-store/messages-redux-slice";

type ForumsStoreState = {
    byId: {
        entries: Record<string, Forum>;
        loading: Record<string, boolean>;
        error: Record<string, SerializedError | null>;
    },
    byTopic: {
        entries: Record<string, Forum[]>;
        loading: Record<string, boolean>;
        error: Record<string, SerializedError | null>;
    }
};

const initialState: ForumsStoreState = {
    byId: {
        entries: {},
        loading: {},
        error: {},
    },
    byTopic: {
        entries: {},
        loading: {},
        error: {},
    },
};
export const populateForumsByTopic = createAsyncThunk(
  "forums/getForumsByTopic",
  async ({session, topic}: {session: Session, topic: Topic}, thunkAPI) => {
    try {
      const apiService = new MessagingAPIService(session);
      const forums = await apiService.getForums(topic.entityClass, topic.entityId, topic.context);
      return forums;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const forumsSlice = createSlice({
  name: "forums",
  initialState,
  reducers: {
    addForum: (state, action: PayloadAction<Forum>) => {
        if(action.payload.id){
            state.byId.entries[action.payload.id.value] = action.payload;
        }
        if(action.payload.topic){
            state.byTopic.entries[action.payload.topic.toString()] = action.payload as any;
        }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateForumsByTopic.pending, (state, action) => {
      const topic = action.meta.arg.topic.toString();
      if (!topic) return;
      state.byTopic.loading[topic] = true;
      state.byTopic.error[topic] = null;
    });
    builder.addCase(populateForumsByTopic.fulfilled, (state, action) => {
        const topic = action.meta.arg.topic.toString();
        if (!topic) return;
    
        state.byTopic.loading[topic] = false;
        state.byTopic.entries[topic] = action.payload as any;

        action.payload.forEach(forum => {
            if(forum.id){
                state.byId.entries[forum.id.value] = forum;
            }
        });
    });
    builder.addCase(populateForumsByTopic.rejected, (state, action) => {
        const topic = action.meta.arg.topic.toString();
        if (!topic) return;
        state.byTopic.loading[topic] = false;
        state.byTopic.error[topic] = action.error;
    });
  }
});

export const { addForum } = forumsSlice.actions;

export const getForumsByTopic = (topic: Topic) =>
  useSelector(
    (state: RootState) => state.forums.byTopic.entries[topic.toString()]
  );
export const getIsLoadingForumsByTopic = (topic: Topic) =>
  useSelector(
    (state: RootState) => state.forums.byTopic.loading[topic.toString()]
  );
export const getErrorLoadingForumsByTopic = (topic: Topic) =>
  useSelector(
    (state: RootState) => state.forums.byTopic.error[topic.toString()]
  );

export const { addMessage, removeMessage } = messagesSlice.actions;
export const getForumMessages = (forum: Forum) =>
  useSelector((state: RootState) =>
    selectMessagesForForum(state, forum.id?.value)
  );
export const getIsLoadingMessagesForForum = (forum: Forum) =>
  useSelector(
    (state: RootState) => state.messages.loading[forum.id?.value ?? ""]
  );
export const getErrorLoadingMessagesForForum = (forum: Forum) =>
  useSelector(
    (state: RootState) => state.messages.error[forum.id?.value ?? ""]
  );

const selectMessagesForForum = (
  state: RootState,
  forumId: string | undefined
): Message[] => state.messages.entries[forumId ?? ""] ?? [];

export default forumsSlice;
