import SaveIcon from "@mui/icons-material/Save";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { CanceledError } from "axios";
import LoadingButton from "common/components/loading-button";
import TagInput from "common/components/tag-input";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategories from "work/values/fee-schedule-category/view/fee-schedule-categories";
import FeeScheduleTag from "work/values/fee-schedule-tag/fee-schedule-tag";

const PageContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const DetailsContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(5),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(1),
  width: '100%',
  "& > .MuiTextField-root": {
    width: "50%",
  }
}));
const SaveButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type CreateTemplateFeeScheduleDialogProps = {
  feeSchedule?: FeeScheduleTemplate;
  templateId?: Guid;
  name?: string;
  categories?: FeeScheduleCategory[];
  entityId?: Guid;
  viewOnly?: boolean;
  onSave?: (feeSchedule: FeeScheduleTemplate) => void;
  onDirty?: (dirty: boolean) => void;
}

export default function CreateTemplateFeeScheduleDialog(props: Readonly<CreateTemplateFeeScheduleDialogProps>) {
  const {
    feeSchedule,
    templateId,
    viewOnly,
    onSave,
    onDirty
  } = props;

  const nameFieldRef = React.useRef<HTMLInputElement>();

  const [name, setName] = React.useState<string>(props.name ?? "");
  const [tags, setTags] = React.useState<FeeScheduleTag[]>([]);
  const [categories, setCategories] = React.useState<Array<FeeScheduleCategory>>(props.categories ?? []);
  const [originalTemplate, setOriginalTemplate] = React.useState<
    FeeScheduleTemplate | undefined
  >(undefined);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [formDirty, setFormDirty] = React.useState<boolean>(false);

  const session = useSession();

  useEffect(() => {
    let abortController = new AbortController();
    loadExistingFeeSchedule(abortController);
    return () => {
      abortController.abort();
      abortController = new AbortController();
    };
  }, []);

  /**
     * Loads a fee schedule from a template id
     * @param templateId The id of the template to load
     * @returns The fee schedule template or undefined if not found
     */
  async function loadFromFeeScheduleTemplateId(
    templateId: Guid,
    abortController: AbortController
  ): Promise<FeeScheduleTemplate | undefined> {
    try {
      const entityId = props.entityId ?? session.currentEntity.entityId;
      const accountType = session.accountType;
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const templates =
        await feeScheduleService.getFeeScheduleTemplates(
          entityId,
          accountType,
          abortController
        );

      if (templates.length === 0) return;

      return templates.find(
        (template: FeeScheduleTemplate) => template.id?.isEqualTo(templateId)
      );
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
      enqueueSnackbar(
        "Couldn't fetch fee schedule from template. Please try again",
        { variant: "error" }
      );
      return;
    }
  }

  /**
   * Loads the fee schedule from the templateId provided in props
   * or the fee schedule provided in props
   */
  async function loadExistingFeeSchedule(abortController: AbortController) {
    if (!feeSchedule && !templateId) return;

    // templateId was provided, try to load
    if (templateId) {
      const templateFeeSchedule = await loadFromFeeScheduleTemplateId(templateId, abortController);

      if (!templateFeeSchedule) {
        setName(name ?? "");
        setTags([]);
        setCategories(categories ?? []);
        return;
      }

      setOriginalTemplate(templateFeeSchedule);
      setName(templateFeeSchedule?.name);
      setTags(templateFeeSchedule?.tags);
      setCategories(templateFeeSchedule?.categories);
      return;
    }

    // The fee schedule was already provided via prop
    setOriginalTemplate(feeSchedule);
    setName(feeSchedule?.name ?? "");
    setTags(feeSchedule?.tags ?? []);
    setCategories(feeSchedule?.categories ?? []);
  }

  function isFormValid(): boolean {
    return !_.isEmpty(name) && !_.isEmpty(categories);
  }

  function getIsCategoryAlreadySelected(category: FeeScheduleCategory): boolean {
    return categories.some(c => c.name?.isEqualTo(category.name));
  }

  async function handleSaveClicked() {
    setSaving(true);

    let newTemplate = new FeeScheduleTemplate(
      name,
      undefined,
      tags,
      categories
    );

    try {
      if (!session.user?.id) throw new Error("User id not found");
      if (!session.currentEntity.entityId) throw new Error("Entity id not found");
      if (session.context?.viewingAsVendor) {
        newTemplate.creator = new EntityVendorRepresentative(session.user?.id, session.currentEntity.entityId);
      } else {
        newTemplate.creator = new EntityClientRepresentative(session.user?.id, session.currentEntity.entityId, session.user.name);
      }
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      newTemplate = await feeScheduleService.createFeeScheduleTemplate(newTemplate, session.accountType);
      enqueueSnackbar("Created fee schedule", { variant: "success" });
      onSave?.(newTemplate);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't save fee schedule. Please try again", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  async function handleSaveChangesClicked() {
    if (!originalTemplate?.id) {
      console.warn("Attempted to save invalid update: missing originalTemplate.id");
      return;
    }
    setSaving(true);

    let updatedTemplate = new FeeScheduleTemplate(
      name,
      originalTemplate.id,
      tags,
      categories
    );

    try {
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      updatedTemplate =
        await feeScheduleService.updateFeeScheduleTemplate(
          originalTemplate,
          updatedTemplate
        );
      enqueueSnackbar("Updated fee schedule", { variant: "success" });

      onSave?.(updatedTemplate);
      onDirty?.(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't save fee schedule changes. Please try again", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  /**
   * Handles when a category is added
   * @param category The category to add
   */
  async function handleCategoryAdded(category: FeeScheduleCategory) {
    if (getIsCategoryAlreadySelected(category)) {
      enqueueSnackbar('Fee category with the same name is already in the fee schedule', { variant: 'warning' });
      return;
    }
    onDirty?.(true);
    setCategories((prevCategories) => [...prevCategories, category]);
    setFormDirty(true);
  }

  /**
   * Handles when a category is removed
   * @param name The name of the category to remove
   */
  async function handleCategoryRemoved(name: FeeScheduleCategoryName) {
    onDirty?.(true);
    const updatedCategories = categories.filter(
      (category) => category.name?.isEqualTo(name) === false
    );
    setCategories(updatedCategories);
    setFormDirty(true);
  }

  /**
   * Handles when a category is updated
   * @param originalName The original name of the category
   * @param updatedCategory The updated fee category
   */
  async function handleCategoryUpdated(
    originalName: FeeScheduleCategoryName | null,
    updatedCategory: FeeScheduleCategory
  ) {
    if (
      getIsCategoryAlreadySelected(updatedCategory) &&
      originalName?.toString() !== updatedCategory.name?.toString()
    ) {
      enqueueSnackbar('Fee category with the same name is already in the fee schedule', { variant: 'warning' });
      return;
    }

    onDirty?.(true);

    let updatedCategories = [...categories];
    let category = updatedCategories.find((c) => c.name === originalName);

    if (!category) return;

    category.name = updatedCategory.name;
    category.description = updatedCategory.description;
    category.billingCode = updatedCategory.billingCode;
    category.fee = updatedCategory.fee;

    // HACK: There is a bug in MaterialTable that requires the list
    // to be cleared before setting the new value
    setCategories([]);
    setCategories(updatedCategories);
    setFormDirty(true);
  }

  function handleExistingTemplateSelected(template: FeeScheduleTemplate, replace: boolean) {
    onDirty?.(true);

    if (replace) {
      setCategories(template.categories);
    } else {
      const duplicateCategories = template.categories.filter(
        (category: FeeScheduleCategory) => getIsCategoryAlreadySelected(category)
      );
      setCategories((prevCategories) => [
        ...prevCategories,
        ...template.categories.filter((category: FeeScheduleCategory) => !duplicateCategories.includes(category))
      ]);
      if (duplicateCategories.length > 0) {
        enqueueSnackbar(
          'Some categories were not added because categories with the same name already exist in the fee schedule',
          { variant: 'warning' }
        );
      }
    }

    setFormDirty(true);
    // The focus call will fail if there isn't a slight delay
    setTimeout(() => nameFieldRef.current?.focus(), 100);
  }

  return (
    <React.Fragment>
      <PageContainer container direction="row">
        {!viewOnly && (
          <DetailsContainer>
            <TextField
              inputRef={nameFieldRef}
              required
              label="Name"
              disabled={viewOnly}
              helperText="The template's name"
              slotProps={{
                htmlInput: { maxLength: 50 }
              }}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setFormDirty(true);
                onDirty?.(true);
              }}
            />
            <TagInput
              helperText={`Tags to help you find this template later (ex. US, ${new Date().getFullYear()})`}
              viewOnly={viewOnly}
              onTagsUpdated={(tagValues) => {
                setTags(tagValues.map((tagValue) => new FeeScheduleTag(tagValue)));
                setFormDirty(true);
                onDirty?.(true);
              }}
              onTagRemoved={(tagValue) => {
                setTags((prevTags) =>
                  prevTags.filter((prevTag) => prevTag.value !== tagValue)
                );
                setFormDirty(true);
                onDirty?.(true);
              }}
              tags={tags.map((tag) => tag.value)}
            />
          </DetailsContainer>
        )}
        <Grid>
          <FeeScheduleCategories
            categories={categories}
            disableEditing={viewOnly}
            disableCommenting={true}
            hideCommentButton={true}
            onCategoryAdded={handleCategoryAdded}
            onCategoryRemoved={handleCategoryRemoved}
            onCategoryUpdated={handleCategoryUpdated}
            onExistingTemplateSelected={handleExistingTemplateSelected}
          />
        </Grid>
      </PageContainer>
      {!viewOnly && (
        <SaveButton
          color="primary"
          startIcon={<SaveIcon />}
          loading={saving}
          variant="contained"
          disabled={!formDirty || !isFormValid() || saving}
          onClick={
            !originalTemplate ? handleSaveClicked : handleSaveChangesClicked
          }>
          {originalTemplate ? "Save Changes" : "Save"}
        </SaveButton>
      )}
    </React.Fragment>
  );
}
