export default class MessageContent {
    private _value: string;

    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: MessageContent): boolean {
        return this.value === other?.value;
    }

    public get value(): string {
        return this._value;
    }

    public static get Prototype(): MessageContent {
        return new MessageContent("");
    }

    public fromJSON(obj: any): MessageContent {
        return new MessageContent(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): MessageContent {
        return new MessageContent(this.value);
    }
}
