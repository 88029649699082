import Guid from "common/values/guid/guid";
import _ from "lodash";
import Forum from "messaging/entities/forum/forum";
import Topic from "messaging/values/topic";
import { Moment } from "moment";
import Proposal, { ProposalField } from "work/entities/proposal/proposal";

export enum Audience {
  VendorReviewers = "vendorReviewers",
  ClientReviewers = "clientReviewers",
  AllReviewers = "allReviewers",
}

export default class CommentThread {
  id?: Guid;
  proposalId: Guid;
  field: ProposalField;
  isExternal: boolean;
  subscriberIds: Guid[];
  createdDate?: Moment;
  isResolved?: boolean;

  constructor(
    proposalId: Guid,
    field: ProposalField,
    subscriberIds: Guid[] = [],
    isExternal = false,
    id?: Guid,
    createdDate?: Moment
  ) {
    this.id = id;
    this.proposalId = proposalId;
    this.field = field;
    this.isExternal = isExternal;
    this.subscriberIds = subscriberIds;
    if (createdDate) {
      this.createdDate = createdDate;
    }
  }

  static fromForum(forum: Forum): CommentThread {
    if (forum.topic?.entityClass !== "Work.Proposal") {
      throw new InvalidCommentThreadError("Forum is not a proposal forum");
    }
    if (!forum.topic?.entityId) {
      throw new InvalidCommentThreadError(
        "Proposal id is missing on comment forum"
      );
    }
    if (!forum.topic?.context) {
      throw new InvalidCommentThreadError(
        "Proposal context is missing on comment forum"
      );
    }
    const contextJSON = JSON.parse(forum.topic.context);
    const field = ProposalField.fromJSON(contextJSON.field);
    if(!field){
      console.warn(`Invalid proposal field ${contextJSON.field}`);
    }

    // this likes to crash using array tools like contextJSON.audience.some
    const audiences = contextJSON.audience;
    let isExternal = false;
    for(const audience of audiences){
      if(audience.includes("all")){
        isExternal = true;
        break;
      }
    }

    return new CommentThread(
      forum.topic.entityId,
      field ?? contextJSON.field,
      forum.subscriberIds,
      isExternal,
      forum.id,
      forum.createdDate
    );
  }

  public getAudience(isViewingAsVendor: boolean): Audience {
    if (this.isExternal) {
      return Audience.AllReviewers;
    }
    return isViewingAsVendor
      ? Audience.VendorReviewers
      : Audience.ClientReviewers;
  }

  public toForum(isViewingAsVendor: boolean): Forum {
    let audience = isViewingAsVendor ? "vendorReviewers" : "clientReviewers";
    if(this.isExternal){
      audience = "allReviewers";
    }
    return new Forum(
      `Proposal ${this.getAudience(
        isViewingAsVendor
      )} ${this.field.toString()} comment thread`,
      new Topic(
        "Work.Proposal",
        this.proposalId,
        JSON.stringify({
          field: this.field,
          audience: [audience],
        })
      ),
      this.subscriberIds,
      this.id,
      undefined,
      this.createdDate
    );
  }

  public static fromProposalField(
    proposal: Proposal,
    field: ProposalField,
    isViewingAsVendor: boolean,
    isExternal: boolean = false,
  ): CommentThread {
    if(!proposal.id){
      throw new InvalidCommentThreadError("Proposal id is missing");
    }
    const subscriberIds = CommentThread.getSubscriberIdsForProposal(
      isExternal,
      proposal,
      isViewingAsVendor
    );
    return new CommentThread(proposal.id, field, subscriberIds, isExternal);
  }

  private static getSubscriberIdsForProposal(
    isExternal: boolean,
    proposal: Proposal,
    isViewingAsVendor: boolean
  ): Guid[] {
    let subscriberIds: Guid[] = [];
    if (isExternal) {
      subscriberIds = CommentThread.getAllSubscriberIds(proposal);
    } else if (isViewingAsVendor) {
      subscriberIds = CommentThread.getVendorSubscriberIds(proposal);
    } else {
      subscriberIds = CommentThread.getClientSubscriberIds(proposal);
    }
    subscriberIds = _.uniqBy(subscriberIds, (id) => id.value);
    return subscriberIds;
  }

  private static getClientSubscriberIds(proposal: Proposal) : Guid[] {
    const clientSubscriberIds: Guid[] = [];
    if (proposal.client?.userId) clientSubscriberIds.push(proposal.client.userId);
    clientSubscriberIds.push(
      ...proposal.clientReviewers.map((reviewer) => reviewer.userId)
    );
    return clientSubscriberIds
  }

  private static getVendorSubscriberIds(proposal: Proposal): Guid[] {
    const vendorSubscriberIds: Guid[] = [];
    if (proposal.team?.leader?.userId)
      vendorSubscriberIds.push(proposal.team.leader.userId);
    vendorSubscriberIds.push(
      ...proposal.vendorReviewers.map((reviewer) => reviewer.userId)
    );
    return vendorSubscriberIds;
  }

  private static getAllSubscriberIds(proposal: Proposal): Guid[] {
    const allSubscriberIds: Guid[] = [];
    if (proposal.client?.userId) allSubscriberIds.push(proposal.client.userId);
    allSubscriberIds.push(
      ...proposal.clientReviewers.map((reviewer) => reviewer.userId)
    );

    if (proposal.team?.leader?.userId)
      allSubscriberIds.push(proposal.team.leader.userId);
    allSubscriberIds.push(
      ...proposal.vendorReviewers.map((reviewer) => reviewer.userId)
    );
    return allSubscriberIds;
  }
}

export class InvalidCommentThreadError extends Error {
  constructor(message: string) {
    super(message || `Invalid comment thread`);
  }
}

export class CommentThreadDoesNotExistError extends Error {
  constructor(id?: Guid | string) {
    if (id instanceof Guid) {
      super(`Forum ${id} does not exist`);
    } else {
      super(`Forum does not exist: ${id}`);
    }
  }
}
