import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import Guid from "common/values/guid/guid";
import UserEntityMemberInvitationAPIService from "legal-entities/entities/user-entity-member-invitation/api/user-entity-member-invitation-api-service";
import UserEntityMemberInvitation from "legal-entities/entities/user-entity-member-invitation/user-entity-member-invitation";


import Session from "users/session/session";

type UserEntityMemberInvitationsState = {
    byId: {
        entries: Record<string, UserEntityMemberInvitation>;
        loading: Record<string, boolean>;
        error: Record<string, SerializedError | null>;
    
    }
};

const initialState: UserEntityMemberInvitationsState = {
    byId: {
        entries: {},
        loading: {},
        error: {}
    }
};

const populateUserEntityMemberInvitationById = createAsyncThunk(
  "userEntityMemberInvitations/getUserEntityMemberInvitationById",
  async ({session, id}: {session: Session, id: Guid}, thunkAPI) => {
    try {
      const apiService = new UserEntityMemberInvitationAPIService(session);
      const invitation = await apiService.getUserEntityMemberInvitationById(id);
      return invitation;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const userEntityMemberInvitationsSlice = createSlice({
  name: "userEntityMemberInvitations",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(populateUserEntityMemberInvitationById.pending, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
          return;
        }
        state.byId.loading[invitationId] = true;
        state.byId.error[invitationId] = null;
    });
    builder.addCase(populateUserEntityMemberInvitationById.fulfilled, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.byId.entries[invitationId] = action.payload;
        state.byId.loading[invitationId] = false;
        state.byId.error[invitationId] = null;
    });
    builder.addCase(populateUserEntityMemberInvitationById.rejected, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.byId.loading[invitationId] = false;
        state.byId.error[invitationId] = action.error;
    });
  }
});

export default userEntityMemberInvitationsSlice;