import moment, { Moment } from "moment";

export default class Date {
    private readonly _value: Moment;

    constructor(value?: Moment) {
        this._value = value ?? moment();
    }

    public get isEmpty(): boolean {
        return false;
    }

    get value(): Moment {
        return this._value;
    }
    
    get isValid(): boolean {
        return this._value.isValid();
    }

    isAfter(other: Date): boolean {
        return this._value.isAfter(other._value, 'day');
    }

    isEqualTo(other: Date | null | undefined): boolean {
        return this.isSame(other);
    }

    toString(): string {
        return this._value.format('MM/DD/YYYY');
    }

    isSame(other?: Date | null): boolean {
        if (!other) {
            return false;
        }
        return this._value.isSame(other._value, 'day');
    }

    toISOString(): string {
        return this._value.toISOString();
    }

    format(format: string): string {
        return this._value.format(format);
    }

    public clone(): Date {
        return new Date(this._value);
    }

    public fromJSON(obj: object): Date | null {
        if (!obj) return null;
        return new Date(moment(obj));
    }
    public toJSON(): string {
        return this._value.toISOString();
    }

    public static get Prototype(): Date {
        return new Date();
    }
}