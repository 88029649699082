import DetailedWorkClientRepresentativeAPIResponse from "work/entities/entity-client-representative/api/response-contracts/detailed-work-client-representative-api-response";
import DetailedWorkTeamAPIResponse from "work/values/team/api/response-contracts/detailed-work-team-api-response";
import WorkProposalAPIResponse, {
  InvalidProposalAPIResponse,
} from "work/entities/proposal/api/response-contracts/work-proposal-api-response";
import Proposal, {
  IProposalStore,
  ProposalDetails,
} from "work/entities/proposal/proposal";
import Guid from "common/values/guid/guid";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import User from "users/entities/user/user";

export default class DetailedWorkProposalAPIResponse extends WorkProposalAPIResponse {
  declare client?: DetailedWorkClientRepresentativeAPIResponse;
  declare supersedes?: WorkProposalAPIResponse;
  declare supersededById?: string;
  declare redlining?: string;
  declare team?: DetailedWorkTeamAPIResponse;

  deserialize(apiService: IProposalStore, currentUser: User | null): Proposal {
    if(!currentUser) throw new Error("Current user not provided.");
    const undetailedProposal = super.deserialize(
      apiService,
      currentUser,
      this.team
        ? Object.assign(
            new DetailedWorkTeamAPIResponse(),
            this.team
          ).deserializeDetailed()
        : undefined
    );
    if (!this.client) {
      throw new InvalidProposalAPIResponse("Client not returned by api.");
    }
    const client = Object.assign(
      new DetailedWorkClientRepresentativeAPIResponse(),
      this.client
    );
    const clientDetails = client.deserialize();
    if (!clientDetails.company?.entityId)
      throw new InvalidProposalAPIResponse(
        "Client entity id not returned by api."
      );

    const team = this.team
      ? Object.assign(new DetailedWorkTeamAPIResponse(), this.team)
      : undefined;
    const teamDetails = team ? team.deserializeDetailed() : undefined;

    const details: ProposalDetails = {
      team: teamDetails,
      client: clientDetails,
      redlining: this.redlining
        ? ProposalRedline.fromJSON(this.redlining)
        : undefined,
      supersedes: this.supersedes
        ? Object.assign(
            new DetailedWorkProposalAPIResponse(),
            this.supersedes
          ).deserialize(apiService, currentUser)
        : undefined,
      supersededById: this.supersededById
        ? new Guid(this.supersededById)
        : undefined,
    };

    const detailedProposal = new Proposal(
      apiService,
      currentUser,
      undetailedProposal.spec,
      undefined,
      undetailedProposal.metaInfo,
      details,
      true
    );

    return detailedProposal;
  }
}
