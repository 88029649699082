import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from '@mui/icons-material/FilterList';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Chip, Container, IconButton, Link, Theme, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ConfirmResponse, useConfirmDialog } from 'app/providers/confirm-dialog';
import { CanceledError } from 'axios';
import LoadingButton from 'common/components/loading-button';
import PaginationParameters from "common/contracts/pagination-parameters";
import { downloadFile } from "common/helpers/utils";
import Guid from 'common/values/guid/guid';
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import DocumentFilterParameters from "documents/entities/document/api/request-contracts/document-filter-parameters";
import DocumentOrderParameters from "documents/entities/document/api/request-contracts/document-order-parameters";
import DocumentTopicParameters from "documents/entities/document/api/request-contracts/document-topic-parameters";
import Document from "documents/entities/document/document";
import DocumentTopic from "documents/values/document-topic";
import { filesize } from "filesize";
import _ from "lodash";
import { createMRTColumnHelper, MaterialReactTable, MRT_ColumnFiltersState, MRT_PaginationState, MRT_ShowHideColumnsButton, MRT_SortingState, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { useNavigate } from 'react-router';
import { Params, useLoaderData, useSearchParams } from "react-router-dom";
import { useSession } from 'users/session/session-context';
import { PageTab } from "./tabs";

const ToolbarButtonsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  display: "flex",
  alignItems: "end",
  flexDirection: "column",
  gap: theme.spacing(1),
}));
const ToolbarButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  flexWrap: "nowrap"
}));
const RowActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  width: "inherit"
}));
const NameLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  fontSize: "1em",
  fontWeight: "500",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  '&:hover': {
    textDecoration: 'underline'
  }
}));
const NameContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  columnGap: theme.spacing(1),
  display: 'flex',
  'svg': {
    width: '24px'
  }
}));
const LabelChip = styled(Chip)(({ theme }) => ({
  color: '#777',
  marginRight: theme.spacing(0.4),
}));
const TableTopToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));
const LowerTopToolbarContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "left",
  width: "100%",
}));
const TableTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
const FilterButtons = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& > .MuiChip-root": {
    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.spacing(0.5),
    },
    marginLeft: theme.spacing(0.5),
  }
}));
const NewButton = styled(IconButton)(({ theme }) => ({
  padding: 0
}));

type ContractsProps = {};

export default function Contracts(props: Readonly<ContractsProps>) {
  const documentFileUploaderRef = React.useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [data, setData] = React.useState<Document[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [wasErrorLoading, setWasErrorLoading] = React.useState(false);
  const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [activeTab, setActiveTab] = React.useState(PageTab.Conflicts);
  const [uploading, setUploading] = React.useState(false);
  const [mine, setMine] = React.useState(true);
  const [clientVendor, setClientVendor] = React.useState(false);

  const navigate = useNavigate();
  const session = useSession();
  const routeParams = useLoaderData() as Params<string>;
  const [searchParams, setSearchParams] = useSearchParams();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // Handle URL changes
  useEffect(() => {
    if (!session.user?.companyEntityId) return;

    let abortController = new AbortController();

    if (routeParams.tab) {
      setActiveTab(routeParams.tab as PageTab);
    } else {
      navigate(`/contracts/${PageTab.Conflicts}`);
    }

    let context = searchParams.get("context");
    if (context === null && routeParams.tab === PageTab.Policies) {
      setSearchParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set("context", "mine");
        return params;
      });
      context = "mine";
    }
    setMine(context === "mine");
    setClientVendor(context === "clientVendor");

    fetchData(
      routeParams.tab as PageTab,
      context,
      abortController
    );

    return () => {
      abortController.abort();
      abortController = new AbortController();
    }
  }, [routeParams, searchParams]);

  const fetchData = async (
    tab: PageTab,
    context: string | null,
    abortController?: AbortController
  ) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      setIsLoading(true);

      let contexts: string[] = [];
      if ((tab ?? activeTab) === PageTab.Conflicts) {
        contexts.push('Conflicts');
      }
      if ((tab ?? activeTab) === PageTab.Policies) {
        if (context === 'mine') {
          contexts.push('Policy');
        }
        if (context === 'clientVendor' && session.context?.viewingAsVendor) {
          contexts.push('VendorPolicies');
        } else if (context === 'clientVendor' && !session.context?.viewingAsVendor) {
          contexts.push('ClientPolicies');
        }
      }
      const apiService = new DocumentAPIService(session);
      const paginationParams = new PaginationParameters(pagination.pageIndex, pagination.pageSize);

      // const order = query.orderByCollection.find(order => order.sortOrder !== undefined);
      // const orderParams = new DocumentOrderParameters(
      //   order?.orderByField === 'name' ? 'name' : 'created',
      //   order?.orderDirection === 'asc' ? 'asc' : 'desc'
      // );

      const order = [];
      const orderParams = new DocumentOrderParameters('name', 'asc');
      const topicParams = new DocumentTopicParameters(undefined, undefined, contexts.join(','));
      const filterParams = new DocumentFilterParameters();
      // const filterParams = new DocumentFilterParameters(
      //   query.filters.find(filter => filter.column.field === 'name')?.value
      // );
      const documentsResponse = await apiService.getDocumentsInfo(
        abortController,
        paginationParams,
        topicParams,
        orderParams,
        filterParams
      );

      setData(documentsResponse.data);
      setRowCount(documentsResponse.data.length);
    } catch (error) {
      if (error instanceof CanceledError) return;
      setWasErrorLoading(true);
      console.error(error);
      return;
    }

    setWasErrorLoading(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  /**
   * Uploads the document file
   * @param {React.ChangeEvent<HTMLInputElement>} event The file upload event
   * @returns {Promise<void>}
   */
  async function handleDocumentFileUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!event.currentTarget.files) return;

    const file: File = event.currentTarget.files[0];

    const templateResponse = await confirm({
      title: "Create Template?",
      message: "Do you want to create a template or just upload the document?",
      okButtonText: "Create Template",
      alternativeOkText: "Just Upload",
      cancelButtonText: "Cancel"
    });

    if (templateResponse === ConfirmResponse.Cancel) return;

    try {
      setUploading(true);

      let context: string | undefined;
      if (templateResponse === ConfirmResponse.Ok) {
        context = activeTab === PageTab.Conflicts ? "ConflictsTemplate" : "PolicyTemplate";
      } else {
        context = activeTab === PageTab.Conflicts ? "Conflicts" : "Policy";
      }
      const service = new DocumentAPIService(session);
      await service.createDocument(
        file,
        file.name,
        [DocumentTopic.fromJSON({ context })]
      );
      enqueueSnackbar("Uploaded document file", { variant: "success" });
      fetchData(routeParams.tab as PageTab, searchParams.get("context"));
    } catch (error: any) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(error.response.data, { variant: "error" });
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar("Unsupported file type", { variant: "error" });
      } else {
        enqueueSnackbar("Unknown error occurred during upload", {
          variant: "error",
        });
      }
    } finally {
      event.target.value = "";
      setUploading(false);
    }
  }

  /**
   * Downloads the document file with the given id
   * @param {Guid} id The id of the document file to download
   * @returns {Promise<void>}
   */
  async function handleDownload(id: Guid): Promise<void> {
    try {
      if (!id) Promise.reject(new Error("No document id provided"));
      const service = new DocumentAPIService(session);
      const response = await service.downloadDocument(id);
      downloadFile(response);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to download file", { variant: "error" });
    }
  }

  /**
   * Deletes the document file with the given id
   * @param {Guid} id The id of the document file to delete
   * @returns {Promise<void>}
   */
  async function handleDelete(id: Guid): Promise<void> {
    if (!id) Promise.reject(new Error("No document id provided"));

    const response = await confirm({
      title: "Delete Document?",
      message: "Are you sure you want to delete this document?",
      okButtonText: "Delete",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const service = new DocumentAPIService(session);
      await service.deleteDocument(id);
      fetchData(routeParams.tab as PageTab, searchParams.get("context"));
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete file", { variant: "error" });
    }
  }

  const renderNameColumn = (document: Document): React.ReactNode => {
    const fileType = document.fileType.toString() as keyof typeof DocumentType;

    return (
      <NameContainer>
        <FileIcon
          extension={fileType}
          {...defaultStyles[fileType.toLowerCase() as keyof typeof defaultStyles]}
        />
        <NameLink onClick={() => handleDownload(document.id)}>
          {document.name?.value}
        </NameLink>
      </NameContainer>
    );
  }

  const renderLabelsColumn = (document: Document) => {
    const labels = _.uniq(document.topics.map((topic) => topic.context));
    return labels.map((label) => {
      if (!label) return null;
      return (
        <LabelChip
          key={label}
          size="small"
          label={label}
        />
      );
    });
  }

  const columnHelper = createMRTColumnHelper<Document>();
  const columns = [
    columnHelper.accessor(renderNameColumn, {
      header: "Name",
      id: "name",
    }),
    columnHelper.accessor(renderLabelsColumn, {
      header: "Labels",
      id: "labels",
    }),
    columnHelper.accessor((document) => document.topics.reduce((acc, topic) => acc + (topic.entityClass === 'Work.Proposal' ? 1 : 0), 0), {
      header: "# of Proposals",
      id: "numberOfProposals",
    }),
    columnHelper.accessor((document) => document.created?.format('MM/DD/YY hh:mm A'), {
      header: "Created",
      id: "created",
    }),
    columnHelper.accessor((document) => filesize(document.fileSize), {
      header: "Size",
      id: "fileSize",
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableTableHead: true,
    enableRowActions: true,
    enableColumnPinning: true,
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
            <LoadingButton
              loading={uploading}
              startIcon={<UploadIcon />}
              onClick={() => documentFileUploaderRef.current?.click()}>
              {`Upload ${activeTab}`}
            </LoadingButton>
          </ToolbarButtonsContainer>
        )}
        {isMediumDisplaySize && (
          <NewButton
            aria-label="Upload"
            color="primary"
            onClick={() => documentFileUploaderRef.current?.click()}
          >
            <UploadIcon fontSize="large" />
          </NewButton>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Contracts
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/contracts/${newValue}/`)}
            >
              <Tab value={PageTab.Conflicts} label="Conflicts" />
              <Tab value={PageTab.Policies} label="Policies" />
            </Tabs>
            {activeTab === PageTab.Policies && (
              <FilterButtons>
                <Chip
                  icon={<FilterListIcon />}
                  label="My Policies"
                  color="primary"
                  variant={mine ? 'filled' : 'outlined'}
                  onClick={() => {
                    if (mine) return;
                    setMine(prevValue => !prevValue);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!mine) {
                        params.set('context', 'mine');
                      } else {
                        params.set('context', 'clientVendor');
                      }
                      return params;
                    });
                  }}
                />
                <Chip
                  icon={<FilterListIcon />}
                  label={`My ${session.context?.viewingAsVendor ? "Clients'" : "Vendors'"} Policies`}
                  color="primary"
                  variant={clientVendor ? 'filled' : 'outlined'}
                  onClick={() => {
                    if (clientVendor) return;
                    setClientVendor(prevValue => !prevValue);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!clientVendor) {
                        params.set('context', 'clientVendor');
                      } else {
                        params.set('context', 'mine');
                      }
                      return params;
                    });
                  }}
                />
              </FilterButtons>
            )}
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer >
      );
    },
    renderRowActions: ({ row, table }) => (
      <RowActionsContainer>
        <Tooltip title="Download">
          <span>
            <IconButton onClick={() => handleDownload(row.original.id)}>
              <DownloadIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Delete">
          <span>
            <IconButton onClick={() => handleDelete(row.original.id)}>
              <DeleteIcon color="error" />
            </IconButton>
          </span>
        </Tooltip>
      </RowActionsContainer>
    ),
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilters: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setQuery,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    rowCount,
    state: {
      columnFilters,
      globalFilter: query,
      isLoading,
      pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <MaterialReactTable table={table} />
      <input
        type="file"
        hidden={true}
        ref={documentFileUploaderRef}
        onChange={handleDocumentFileUpload}
      />
    </>
  );
}
