export default class MessageSubject {
    private _value: string;
    
    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: MessageSubject): boolean {
        return this.value === other?.value;
    }
    
    public get value(): string {
        return this._value;
    }
    
    public static get Prototype(): MessageSubject {
        return new MessageSubject("");
    }
    
    public fromJSON(obj: any): MessageSubject {
        return new MessageSubject(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): MessageSubject {
        return new MessageSubject(this.value);
    }
}
