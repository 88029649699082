export default class FeeScheduleCategoryDescription {
    private _value: string;

    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: FeeScheduleCategoryDescription): boolean {
        return this.value === other?.value;
    }

    public get value(): string {
        return this._value;
    }

    public static get Prototype(): FeeScheduleCategoryDescription {
        return new FeeScheduleCategoryDescription("");
    }

    public fromJSON(obj: any): FeeScheduleCategoryDescription {
        return new FeeScheduleCategoryDescription(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): FeeScheduleCategoryDescription {
        return new FeeScheduleCategoryDescription(this.value);
    }
}