import { Dictionary } from 'lodash';

export default class MarketplaceSearchAPIRequest {
  query?: string;
  hideCompanies?: boolean;
  hideTeams?: boolean;
  hideIndividuals?: boolean;
  category?: string;
  sortBy?: string;
  name?: string;
  yearEstablished?: number | null;
  numberOfEmployees?: number | null;
  contactPersonUserId?: string;
  description?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  website?: string;
  firstName?: string;
  lastName?: string;
  school?: string;
  professionalRegistration?: string;
  technicalBackground?: string;
  applicationsDrafted?: number | null;
  allowedApplications?: number | null;
  barAdmissionYear?: number | null;
  isOnCall?: boolean;
  insuredAmount?: number | null;
  billingRate?: string;
  potentialDiversityCandidate?: boolean;
  collaborates?: boolean;
  additionalInfo?: string;
  fluentLanguages?: string[];
  technicalLanguages?: string[];
  regionsRegistered?: string[];
  regionsLicensed?: string[];
  limitToVendorReps?: boolean;
  from: number = 0;
  size: number = 10;

  constructor(filters?: Dictionary<string>){
    if(!filters) return;

    this.query = filters['query'];
    this.category = filters['category'];
    this.hideCompanies = filters['hideCompanies'] === 'true';
    this.hideTeams = filters['hideTeams'] === 'true';
    this.hideIndividuals = filters['hideIndividuals'] === 'true';
    this.sortBy = filters['sortBy'];
    this.name = filters['name'];
    this.yearEstablished = filters['yearEstablished'] ? parseInt(filters['yearEstablished']) : null;
    this.numberOfEmployees = filters['numberOfEmployees'] ? parseInt(filters['numberOfEmployees']) : null;
    this.contactPersonUserId = filters['contactPersonUserId'];
    this.description = filters['description'];
    this.address1 = filters['address1'];
    this.address2 = filters['address2'];
    this.city = filters['city'];
    this.state = filters['state'];
    this.postalCode = filters['postalCode'];
    this.website = filters['website'];
    this.firstName = filters['firstName'];
    this.lastName = filters['lastName'];
    this.school = filters['school'];
    this.professionalRegistration = filters['professionalRegistration'];
    this.technicalBackground = filters['technicalBackground'];
    this.applicationsDrafted = filters['applicationsDrafted'] ? parseInt(filters['applicationsDrafted']) : null;
    this.allowedApplications = filters['allowedApplications'] ? parseInt(filters['allowedApplications']) : null;
    this.barAdmissionYear = filters['barAdmissionYear'] ? parseInt(filters['barAdmissionYear']) : null;
    this.isOnCall = filters['isOnCall'] === 'true';
    this.insuredAmount = filters['insuredAmount'] ? parseInt(filters['insuredAmount']) : null;
    this.billingRate = filters['billingRate'];
    this.potentialDiversityCandidate = filters['potentialDiversityCandidate'] === 'true';
    this.collaborates = filters['collaborates'] === 'true';
    this.additionalInfo = filters['additionalInfo'];
    this.fluentLanguages = filters['fluentLanguages'] ? filters['fluentLanguages'].split(',') : [];
    this.technicalLanguages = filters['technicalLanguages'] ? filters['technicalLanguages'].split(',') : [];
    this.regionsRegistered = filters['regionsRegistered'] ? filters['regionsRegistered'].split(',') : [];
    this.regionsLicensed = filters['regionsLicensed'] ? filters['regionsLicensed'].split(',') : [];  
  }

  public asSearchParams(): URLSearchParams {
    const params = new URLSearchParams();
    if (this.query) params.append("query", this.query);
    if (this.hideCompanies) params.append("hideCompanies", this.hideCompanies.toString());
    if (this.hideTeams) params.append("hideTeams", this.hideTeams.toString());
    if (this.hideIndividuals) params.append("hideIndividuals", this.hideIndividuals.toString());
    if (this.category) params.append("category", this.category);
    if (this.sortBy) params.append("sortBy", this.sortBy);
    if (this.name) params.append("name", this.name);
    if (this.yearEstablished) params.append("yearEstablished", this.yearEstablished.toString());
    if (this.numberOfEmployees) params.append("numberOfEmployees", this.numberOfEmployees.toString());
    if (this.contactPersonUserId) params.append("contactPersonUserId", this.contactPersonUserId);
    if (this.description) params.append("description", this.description);
    if (this.address1) params.append("address1", this.address1);
    if (this.address2) params.append("address2", this.address2);
    if (this.city) params.append("city", this.city);
    if (this.state) params.append("state", this.state);
    if (this.postalCode) params.append("postalCode", this.postalCode);
    if (this.website) params.append("website", this.website);
    if (this.firstName) params.append("firstName", this.firstName);
    if (this.lastName) params.append("lastName", this.lastName);
    if (this.school) params.append("school", this.school);
    if (this.professionalRegistration) params.append("professionalRegistration", this.professionalRegistration);
    if (this.technicalBackground) params.append("technicalBackground", this.technicalBackground);
    if (this.applicationsDrafted) params.append("applicationsDrafted", this.applicationsDrafted.toString());
    if (this.allowedApplications) params.append("allowedApplications", this.allowedApplications.toString());
    if (this.barAdmissionYear) params.append("barAdmissionYear", this.barAdmissionYear.toString());
    if (this.isOnCall) params.append("isOnCall", this.isOnCall.toString());
    if (this.insuredAmount) params.append("insuredAmount", this.insuredAmount.toString());
    if (this.billingRate) params.append("billingRate", this.billingRate);
    if (this.additionalInfo) params.append("additionalInfo", this.additionalInfo);
    if (this.potentialDiversityCandidate) params.append("potentialDiversityCandidate", this.potentialDiversityCandidate.toString());
    if (this.collaborates) params.append("collaborates", this.collaborates.toString());
    if (this.fluentLanguages) params.append("fluentLanguages", this.fluentLanguages.join(','));
    if (this.technicalLanguages) params.append("technicalLanguages", this.technicalLanguages.join(','));
    if (this.regionsRegistered) params.append("regionsRegistered", this.regionsRegistered.join(','));
    if (this.regionsLicensed) params.append("regionsLicensed", this.regionsLicensed.join(','));
    if (this.from) params.append("from", this.from.toString());
    if (this.size) params.append("size", this.size.toString());
    return params;
  }
}