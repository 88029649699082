import Guid from "common/values/guid/guid";
import UserProfile from "users/values/user-profile/user-profile";

export default class NetworkInvitationInfo {
  id: Guid;
  status: string;
  inviter: boolean;
  individualId: Guid | undefined;
  avatarId?: Guid;
  user: UserProfile;
  created: Date | string;
  message: string;

  constructor(
    id: Guid,
    status: string,
    inviter: boolean,
    individualId: Guid | undefined,
    avatarId: Guid | undefined,
    user: UserProfile,
    created: Date | string,
    message: string
  ) {
    this.id = id;
    this.status = status.toLowerCase();
    this.inviter = inviter;
    this.individualId = individualId;
    this.avatarId = avatarId;
    this.user = user;
    this.created = created;
    this.message = message ?? "";
  }

  static fromJSON(object: any): NetworkInvitationInfo {
    if (!object.id) 
      throw new Error("NetworkInvitationInfo must have an id");
    if (!object.status)
      throw new Error("NetworkInvitationInfo must have a status");
    if (object.inviter === undefined)
      throw new Error("NetworkInvitationInfo must indicate whether you are the inviter");
    if (!object.user) 
      throw new Error("NetworkInvitationInfo must have a user");
    if (!object.created)
      throw new Error("NetworkInvitationInfo must have a created");

    const id = Guid.fromJSON(object.id);
    if(!id) throw new Error("NetworkInvitationInfo must have an id");
    const status = object.status;
    const inviter = object.inviter;
    let individualId: Guid | undefined;
    try {
      individualId = Guid.fromJSON(object.individualId);
    } catch {
      console.warn("NetworkInvitationInfo individualId is not a valid Guid");
    }
    
    const avatarId = Guid.fromJSON(object.avatarId);
    const user = UserProfile.fromJSON(object.user);
    const created = object.created;
    const message = object.message ?? "";
    return new NetworkInvitationInfo(
      id,
      status,
      inviter,
      individualId,
      avatarId,
      user,
      created,
      message
    );
  }
  public toJSON() : object {
    return {
      id: this.id.toJSON(),
      status: this.status,
      inviter: this.inviter,
      individualId: this.individualId?.toJSON(),
      avatarId: this.avatarId?.toJSON(),
      user: this.user.toJSON(),
      created: this.created,
      message: this.message,
    }
  }
}
