import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import SearchResults from "common/contracts/search-results";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import MarketplaceForumResultAPIResponse from "marketplace/api/response-contracts/marketplace-forum-result-api-response";
import MarketplaceIndividualPendingInvitationAPIResponse from "marketplace/entities/marketplace-individual-pending-invitation/api/response-contracts/marketplace-individual-pending-invitation-api-response";
import MarketplaceIndividualPendingInvitation from "marketplace/entities/marketplace-individual-pending-invitation/marketplace-individual-pending-invitation";
import MarketplaceTeamAPIRequest from "marketplace/entities/marketplace-team/api/request-contracts/marketplace-team-api-request";
import MarketplaceTeamSearch from "marketplace/entities/marketplace-team/api/request-contracts/marketplace-team-search";
import UpdateMarketplaceTeamAPIRequest from "marketplace/entities/marketplace-team/api/request-contracts/update-marketplace-team-api-request";
import MarketplaceTeamAPIResponse from "marketplace/entities/marketplace-team/api/response-contracts/marketplace-team-api-response";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import MarketplaceTeamInvitationAPIRequest from "marketplace/entities/team-invitation/api/request-contracts/marketplace-team-invitation-api-request";
import MarketplaceTeamInvitationAPIResponse from "marketplace/entities/team-invitation/api/response-contracts/marketplace-team-invitation-api-response";
import TeamInvitation from "marketplace/entities/team-invitation/team-invitation";
import MarketplaceTeamInfoAPIResponse from "marketplace/values/team-profile/api/response-contracts/marketplace-team-info-api-response";
import Forum from "messaging/entities/forum/forum";
import Session from "users/session/session";


export default class MarketplaceTeamAPIService {
  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create MarketplaceTeamAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getTeamVendors(abortController?: AbortController): Promise<MarketplaceTeam[]> {
    const url = new URL("/marketplace/team-vendors", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const vendors: Array<MarketplaceTeam> = [];
      response.data.forEach((responseElement: any) => {
        const vendor: MarketplaceTeamAPIResponse = Object.assign(new MarketplaceTeamAPIResponse(), responseElement);
        vendors.push(vendor.deserializeToTeam());
      });
      return vendors;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new MarketplaceTeamServiceError("getTeamVendors", error);
    }
  }

  async searchTeams(search: MarketplaceTeamSearch, abortController?: AbortController):
    Promise<SearchResults<MarketplaceTeamInfoAPIResponse>> {
    let params = new URLSearchParams();
    for (const [key, value] of Object.entries(search)) {
      if (value) {
        params.append(key, value.toString());
      }
    }
    const url = new URL(`/marketplace/teams?${params.toString()}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const result = new SearchResults<MarketplaceTeamInfoAPIResponse>(response.data);
      return result;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new MarketplaceTeamServiceError("searchTeams", error);
    }
  }

  async getTeamById(teamId: Guid, abortController?: AbortController): Promise<MarketplaceTeam> {
    const url = new URL(`/marketplace/teams/${teamId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const team: MarketplaceTeamAPIResponse = Object.assign(new MarketplaceTeamAPIResponse(), response.data);
      return team.deserializeToTeam();
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 404: throw new MarketplaceTeamNotFoundError("Requested team was not found");
        case 403: throw new HiddenMarketplaceTeamError("Requested team is not visible");
        default: throw new MarketplaceTeamServiceError("getTeamById", error);
      }
    }
  }

  async getTeamAvatar(teamId: Guid, abortController?: AbortController): Promise<string> {
    const url = new URL(`/marketplace/teams/${teamId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 404: throw new MarketplaceTeamAvatarError(error.data);
        case 403: throw new HiddenMarketplaceTeamError("Requested team is not visible");
        default: throw new MarketplaceTeamServiceError("getTeamAvatar", error);
      }
    }
  }

  async getTeamInvitationByInvitationId(invitationId: Guid, abortController?: AbortController): Promise<TeamInvitation> {
    const url = new URL(`/marketplace/team-invitations/${invitationId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const invitation = new MarketplaceTeamInvitationAPIResponse(response.data);
      return invitation.deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 404: throw new MarketplaceTeamInvitationNotFoundError("Requested team invitation was not found");
        case 403: throw new MarketplaceTeamInvitationError("Caller does not have permission to view this invitation");
        default: throw new MarketplaceTeamServiceError("getTeamInvitationByInvitationId", error);
      }
    }
  }

  async getTeamInvitationsByTeamId(teamId: Guid, abortController?: AbortController, open: boolean = true):
    Promise<MarketplaceIndividualPendingInvitation[]> {
    const url = new URL(`/marketplace/team-invitations?teamId=${teamId.value}&open=${open}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const invitations: MarketplaceIndividualPendingInvitation[] = [];
      response.data.forEach((responseElement: any) => {
        const invitation = new MarketplaceIndividualPendingInvitationAPIResponse(responseElement);
        invitations.push(invitation.deserialize());
      });
      return invitations;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 404: throw new MarketplaceTeamInvitationError("Requested team invitations were not found");
        case 403: throw new MarketplaceTeamInvitationError("Caller does not have permission to view these invitations");
        default: throw new MarketplaceTeamServiceError("getTeamInvitationsByTeamId", error);
      }
    }
  }

  async createTeam(team: MarketplaceTeam): Promise<MarketplaceTeam> {
    const url = new URL("/marketplace/teams", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new MarketplaceTeamAPIRequest(team),
        {
          headers: this.headers.concat({"Content-Type": "application/json"})
        }
      );
      const returnedTeam = Object.assign(new MarketplaceTeamAPIResponse(), response.data);
      return returnedTeam.deserializeToTeam();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new MarketplaceTeamServiceError("createTeam", error);
        case 403: throw new MarketplaceTeamServiceError("createTeam", "User not authorized to create this team");
        default: throw new MarketplaceTeamServiceError("createTeam", error);
      }
    }
  }

  async updateTeam(originalTeam: MarketplaceTeam, updatedTeam: MarketplaceTeam): Promise<MarketplaceTeam> {
    if(!originalTeam.id) throw new Error("Original team must have an id to update");
    const url = new URL(`/marketplace/teams/${originalTeam.id.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.patch(
        url.toString(),
        new UpdateMarketplaceTeamAPIRequest(originalTeam, updatedTeam).payload,
        {
          headers: this.headers.concat({"Content-Type": "application/json"})
        }
      );
      const team = Object.assign(new MarketplaceTeamAPIResponse(), response.data);
      return team.deserializeToTeam();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new MarketplaceTeamUpdateError("Unable to update the team");
        case 404: throw new MarketplaceTeamServiceError("updateTeam", "Requested team was not found");
        default: throw new MarketplaceTeamServiceError("updateTeam", error);
      }
    }
  }

  async deleteTeam(teamId: Guid): Promise<void> {
    const url = new URL(`/marketplace/teams/${teamId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new MarketplaceTeamNotFoundError("Requested team was not found");
        case 403: throw new MarketplaceTeamServiceError("deleteTeam", "User not authorized to delete this team");
        default: throw new MarketplaceTeamServiceError("deleteTeam", error);
      }
    }
  }

  async createTeamAvatar(teamId: Guid, file: File): Promise<void> {
    const url = new URL(`/marketplace/teams/${teamId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const formData = new FormData();
      formData.append("file", file);
      await axios.post(
        url.toString(),
        formData,
        {
          headers: this.headers.concat({ "Content-Type": "multipart/form-data" })
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new MarketplaceTeamNotFoundError("Requested team was not found");
        case 422: throw new MarketplaceTeamAvatarError("Unable to upload avatar");
        default: throw new MarketplaceTeamServiceError("createTeamAvatar", error);
      }
    }
  }

  async deleteTeamAvatar(teamId: Guid): Promise<void> {
    const url = new URL(`/marketplace/teams/${teamId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if(error.response?.status === 404)
        throw new MarketplaceTeamNotFoundError("Requested team was not found");
      throw new MarketplaceTeamServiceError("deleteTeamAvatar", error);
    }
  }

  async requestToJoinTeam(teamId: Guid): Promise<void> {
    const url = new URL(`/marketplace/teams/${teamId.value}/request-to-join`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new MarketplaceTeamNotFoundError("Requested team to join was not found");
      throw new MarketplaceTeamServiceError("requestToJoinTeam", error);
    }
  }

  async promoteTeamMember(teamId: Guid, memberUserId: Guid): Promise<void> {
    const url = new URL(`/marketplace/team-member/${teamId.value}/${memberUserId.value}/promote`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if(error.response?.status === 404)
        throw new MarketplaceTeamNotFoundError("Requested team was not found");
      throw new MarketplaceTeamServiceError("promoteTeamMember", error);
    }
  }

  async removeTeamMember(teamId: Guid, memberUserId: Guid): Promise<void> {
    const url = new URL(`/marketplace/team-member/${teamId.value}/${memberUserId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if(error.response?.status === 404)
        throw new MarketplaceTeamNotFoundError("Requested team was not found");
      throw new MarketplaceTeamServiceError("removeTeamMember", error);
    }
  }

  async cancelTeamInvitation(invitationId: Guid): Promise<void> {
    const url = new URL(`/marketplace/team-invitations/${invitationId.value}/cancel`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if(error.response?.status === 404)
        throw new MarketplaceTeamInvitationNotFoundError("Requested team invitation was not found");
      throw new MarketplaceTeamServiceError("cancelTeamInvitation", error);
    }
  }

  async acceptTeamInvitation(invitationId: Guid): Promise<void> {
    const url = new URL(`/marketplace/team-invitations/${invitationId.value}/accept`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if(error.response?.status === 404)
        throw new MarketplaceTeamInvitationNotFoundError("Requested team invitation was not found");
      throw new MarketplaceTeamServiceError("acceptTeamInvitation", error);
    }
  }

  async rejectTeamInvitation(invitationId: Guid): Promise<void> {
    const url = new URL(`/marketplace/team-invitations/${invitationId.value}/reject`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if(error.response?.status === 404)
        throw new MarketplaceTeamInvitationNotFoundError("Requested team invitation was not found");
      throw new MarketplaceTeamServiceError("rejectTeamInvitation", error);
    }
  }

  async createTeamInvitation(inviteeId: Guid, teamId: Guid, message?: string): Promise<TeamInvitation> {
    const url = new URL("/marketplace/team-invitations", AttorneyHubAPIService.apiBaseUrl);
    const request = new MarketplaceTeamInvitationAPIRequest();
    request.inviteeId = inviteeId.value;
    request.teamId = teamId.value;
    request.message = message;
    try {    
      const response = await axios.post(
        url.toString(),
        request,
        {
          headers: this.headers.concat({"Content-Type": "application/json"})
        }
      );
      const invitation = new MarketplaceTeamInvitationAPIResponse(response.data);
      return invitation.deserialize();
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new MarketplaceTeamNotFoundError("Requested team invitation was not found");
      throw new MarketplaceTeamServiceError("createTeamInvitation", error);
    }
  }

  async contactTeam(teamId: Guid): Promise<Forum> {
    const url = new URL(`/marketplace/teams/${teamId.value}/contact`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const forum = Object.assign(new MarketplaceForumResultAPIResponse(), response.data);
      return forum.deserialize();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new MarketplaceTeamServiceError("contactTeam", "Unable to contact team");
        case 404: throw new MarketplaceTeamNotFoundError("Requested team was not found");
        default: throw new MarketplaceTeamServiceError("contactTeam", error);
      }
    }
  }
}

export class MarketplaceTeamServiceError extends Error {
  methodName: string;
  error: any
  constructor(method: string, error: any) {
    super(error);
    this.error = error;
    this.methodName = method;
  }
}
export class MarketplaceTeamNotFoundError extends Error { }
export class MarketplaceTeamInvitationError extends Error { }
export class MarketplaceTeamInvitationNotFoundError extends Error { }
export class HiddenMarketplaceTeamError extends Error { }
export class MarketplaceTeamAvatarError extends Error { }
export class MarketplaceTeamUpdateError extends Error { }