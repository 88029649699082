export default class FeeScheduleCategoryName {
    private _value: string;
    
    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: FeeScheduleCategoryName): boolean {
        return this.value === other?.value;
    }
    
    public get value(): string {
        return this._value;
    }
    
    public static get Prototype(): FeeScheduleCategoryName {
        return new FeeScheduleCategoryName("");
    }
    
    public fromJSON(obj: any): FeeScheduleCategoryName {
        return new FeeScheduleCategoryName(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): FeeScheduleCategoryName {
        return new FeeScheduleCategoryName(this.value);
    }
}