import { Currency } from "common/values/currency/currency";
import Money from "common/values/money/money";

import FeeScheduleCategory from "marketplace/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoryName from "marketplace/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategoryDescription from "marketplace/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleBillingCode from "marketplace/values/fee-schedule-billing-code/fee-schedule-billing-code";
import Fee from "marketplace/values/fee/fee";

export default class MarketplaceFeeScheduleCategoryAPIResponse {
    name?: string;
    description?: string;
    billingCode?: string;
    feeRate?: number;
    feeCurrency?: string;
    feeUnit?: string;
    fixedFee?: boolean;

    constructor(values: Partial<MarketplaceFeeScheduleCategoryAPIResponse>) {
        Object.assign(this, values);
    }

    deserialize(): FeeScheduleCategory {
        if (!this.name) throw new Error("Name is required, but was not provided.");
        if (!this.feeRate) throw new Error("Fee rate is required, but was not provided.");
        if (!this.feeCurrency) throw new Error("Fee currency is required, but was not provided.");
        if (!this.billingCode) throw new Error("Billing code is required, but was not provided.");

        const rate = new Money(this.feeRate, Currency.USD);
        const fee = new Fee(rate, this.feeUnit, this.fixedFee);
        return new FeeScheduleCategory(
            new FeeScheduleCategoryName(this.name),
            this.description ? new FeeScheduleCategoryDescription(this.description) : undefined,
            fee,
            new FeeScheduleBillingCode(this.billingCode)
        )
    }
}
