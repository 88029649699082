import MarketplaceCompanyInfoAPIResponse from 'marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response';
import MarketplaceIndividualInfoAPIResponse from 'marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response';
import MarketplaceResponseAPIResponse from 'marketplace/api/response-contracts/marketplace-response-api-response';
import Company from 'marketplace/entities/company/company';
import Guid from 'common/values/guid/guid';
import CompanyProfile from 'marketplace/values/company-profile/company-profile';


export default class MarketplaceSearchInfoAPIResponse extends MarketplaceResponseAPIResponse {
    name?: string;
    yearEstablished?: number;
    numberOfEmployees?: number;
    contact?: MarketplaceIndividualInfoAPIResponse;
    description?: string;
    location?: string;
    website?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    school?: string;
    avatar?: string;
    resume?: string;
    professionalRegistration?: string;
    technicalBackground?: string;
    numberOfApplicationsDraftedProsecuted?: number;
    numberOfAllowedApplications?: number;
    barAdmissionYear?: number;
    isOnCall?: boolean;
    insuredAmount?: number;
    billingRate?: string;
    potentialDiversityCandidate?: boolean;
    collaborates?: boolean;
    additionalInfo?: string;
    links?: string[];
    professionalPublications?: string[];
    sampleApplications?: string[];
    fluentLanguages?: string[];
    technicalLanguages?: string[];
    regionsRegistered?: string[];
    leader?: MarketplaceIndividualInfoAPIResponse;
    members?: MarketplaceIndividualInfoAPIResponse[];
    totalMembers?: number;
    associatedFirms?: MarketplaceCompanyInfoAPIResponse[];
    totalFirms?: number;
    companyId?: string;
    companyEntityId?: string;
    companyName?: string;
    userId?: string;
    isVendorRep?: boolean;

    deserializeToCompany(): Company{
        if (!this.id) throw new Error("Company id is required, was not returned by the API");
        if (!this.companyEntityId) throw new Error("Company entity id is required, was not returned by the API");
        if (!this.name) throw new Error("Company name is required, was not returned by the API");

        const profile = new CompanyProfile(
            this.name,
            this.description,
            this.location,
            this.yearEstablished,
            this.website,
            this.numberOfEmployees,
            undefined,
            this.avatar ? new Guid(this.avatar) : undefined,
            this.contact?.id ? new Guid(this.contact.id) : undefined,
            this.contact?.userId ? new Guid(this.contact.userId) : undefined
        )

        return new Company(
            new Guid(this.id),
            new Guid(this.companyEntityId),
            profile,
            this.contact?.id ? new Guid(this.contact.id) : undefined,
            this.isVisible ?? false
        );
    }
}
