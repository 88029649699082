import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "app/realtime-store/redux-store";
import PaginationParameters from "common/contracts/pagination-parameters";
import Guid from "common/values/guid/guid";
import moment from "moment";
import NotificationsAPIService from "notifications/entities/notification/api/notifications-api-service";
import NotificationParameters from "notifications/entities/notification/api/request-contracts/notification-parameters";
import Notification from "notifications/entities/notification/notification";
import { BoxType } from "notifications/values/box-type/box-type";
import { useSelector } from "react-redux";
import Session from "users/session/session";

type NotificationStoreState = {
  byId: {
    entries: Record<string, Notification>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  byQuery: {
    entries: Record<string, Notification[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  interfaceQueries: {
    entries: Record<string, NotificationQuery>;
  };
};

const initialState: NotificationStoreState = {
  byId: {
    entries: {},
    loading: {},
    error: {},
  },
  byQuery: {
    entries: {},
    loading: {},
    error: {},
  },
  interfaceQueries: {
    entries: {},
  },
};

export class NotificationQuery {
  constructor(
    public notificationParams: NotificationParameters,
    public pagination: PaginationParameters
  ) {}

  public asSearchParams(): URLSearchParams {
    return new URLSearchParams([
      ...this.notificationParams.asSearchParams(),
      ...this.pagination.asSearchParams(),
    ]);
  }
}

export const populateNotificationsByQuery = createAsyncThunk(
  "notifications/getNotificationsByQuery",
  async (
    { session, query }: { session: Session; query: NotificationQuery },
    thunkAPI
  ) => {
    try {
      const apiService = new NotificationsAPIService(session);
      let notifications: Notification[] = [];
      if(query.notificationParams.boxType === BoxType.Archived){
        notifications = await apiService.getArchivedNotificationsForUser(
          query.pagination,
          query.notificationParams
        );
        return notifications;
      } else {
        notifications = await apiService.getNotificationsForUser(
          query.pagination,
          query.notificationParams
        );
      }
      return notifications;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateNotificationsById = createAsyncThunk(
  "notifications/getNotificationsById",
  async ({ session, id }: { session: Session; id: Guid }, thunkAPI) => {
    try {
      const apiService = new NotificationsAPIService(session);
      const notifications = await apiService.getNotificationById(id);
      return notifications;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      const id = action.payload.id?.value;
      if (!id) {
        return;
      }
      state.byId.entries[id] = action.payload;
      state.byQuery = {
        entries: {},
        loading: {},
        error: {},
      };
    },
    removeNotification: (state, action: PayloadAction<Guid>) => {
      const id = action.payload.value;
      delete state.byId.entries[id];
      state.byQuery = {
        entries: {},
        loading: {},
        error: {},
      };
    },
    markAllNotificationsAsSeen: (state) => {
      const updatedNotifications = [];
      for (const notification of Object.values(state.byId.entries)) {
        if (!notification.isSeen) {
          updatedNotifications.push({ ...notification, seenAt: moment() });
        }
      }
      updatedNotifications.forEach((notification) => {
        state.byId.entries[notification.id.value] = notification;
      });
      state.byQuery = {
        entries: {},
        loading: {},
        error: {},
      };
    },
    updateNotificationInterfaceQuery: (
      state,
      action: PayloadAction<{
        interfaceName: string;
        query: NotificationQuery;
      }>
    ) => {
      state.interfaceQueries.entries[action.payload.interfaceName] =
        action.payload.query;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateNotificationsByQuery.pending, (state, action) => {
      if (!action.meta.arg.query?.notificationParams) {
        return;
      }
      const query = action.meta.arg.query.notificationParams
        .asSearchParams()
        .toString();
      state.byQuery.loading[query] = true;
      state.byQuery.error[query] = null;
    });
    builder.addCase(populateNotificationsByQuery.fulfilled, (state, action) => {
      if (!action.meta.arg.query?.notificationParams) {
        return;
      }
      const query = action.meta.arg.query.asSearchParams().toString();
      state.byQuery.loading[query] = false;
      state.byQuery.entries[query] = action.payload;
    });
    builder.addCase(populateNotificationsByQuery.rejected, (state, action) => {
      if (!action.meta.arg.query?.notificationParams) {
        return;
      }
      const query = action.meta.arg.query.notificationParams
        .asSearchParams()
        .toString();
      state.byQuery.loading[query] = false;
      state.byQuery.error[query] = action.error;
    });

    builder.addCase(populateNotificationsById.pending, (state, action) => {
      if (!action.meta.arg.id) {
        return;
      }
      const id = action.meta.arg.id.value;
      state.byId.loading[id] = true;
      state.byId.error[id] = null;
    });
    builder.addCase(populateNotificationsById.fulfilled, (state, action) => {
      if (!action.meta.arg.id) {
        return;
      }
      const id = action.meta.arg.id.value;
      state.byId.loading[id] = false;
      state.byId.entries[id] = action.payload;
    });
    builder.addCase(populateNotificationsById.rejected, (state, action) => {
      if (!action.meta.arg.id) {
        return;
      }
      const id = action.meta.arg.id.value;
      state.byId.loading[id] = false;
      state.byId.error[id] = action.error;
    });
  },
});

export const {
  addNotification,
  removeNotification,
  markAllNotificationsAsSeen,
  updateNotificationInterfaceQuery,
} = notificationsSlice.actions;

export const getNotificationsByQuery = (query: NotificationQuery) => {
  return useSelector((state: RootState) => {
    if (!query) {
      return undefined;
    }
    return state.notifications.byQuery.entries[
      query.asSearchParams().toString()
    ];
  });
};
export const getIsLoadingNotificationsByQuery = (query: NotificationQuery) =>
  useSelector((state: RootState) => {
    if (query === undefined) {
      return undefined;
    }
    return state.notifications.byQuery.loading[
      query.asSearchParams().toString()
    ];
  });
export const getErrorLoadingNotificationsByQuery = (
  query: NotificationQuery
) =>
  useSelector(
    (state: RootState) =>
      state.notifications.byQuery.error[query.asSearchParams().toString()]
  );

export const getNotificationById = (id: Guid) =>
  useSelector((state: RootState) => state.notifications.byId.entries[id.value]);
export const getIsLoadingNotificationById = (id: Guid) =>
  useSelector((state: RootState) => state.notifications.byId.loading[id.value]);
export const getErrorLoadingNotificationById = (id: Guid) =>
  useSelector((state: RootState) => state.notifications.byId.error[id.value]);

export const getNotificationQueryByInterface = (interfaceName: string) =>
  useSelector(
    (state: RootState) =>
      state.notifications.interfaceQueries.entries[interfaceName]
  );

export default notificationsSlice;
