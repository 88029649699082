import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import { Moment } from "moment";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";

export default class User {
  id?: Guid;
  name: Name;
  fullName: string;
  initials: string;
  email: EmailAddress;
  created?: Moment;
  isSuperUser: boolean = false;
  userIsDisabled: boolean = false;
  individualId?: Guid;
  companyName?: string;
  companyEntityId?: Guid;
  isCompanyManager: boolean = false;
  companyId?: Guid;

  constructor(name: Name, email: EmailAddress, id?: Guid, isSuperUser: boolean = false) {
    this.name = name;
    this.fullName = name.toString();
    this.initials = name.toInitials();
    this.email = email;
    this.id = id;
    this.isSuperUser = isSuperUser;
  }

  public get asClientRepresentative(): EntityClientRepresentative | null {
    if (!this.id || !this.companyEntityId) return null;
    return new EntityClientRepresentative(this.id, this.companyEntityId, this.name);
  }

  public get asVendorRepresentative(): EntityVendorRepresentative | null {
    if (!this.id || !this.companyEntityId) return null;
    return new EntityVendorRepresentative(this.id, this.companyEntityId);
  }

  public static fromJSON(object: any): User {
    if(!object?.email || !object?.name || !object?.id) throw new Error('User object is missing required fields');
    const user = new User(
      Name.fromJSON(object.name),
      EmailAddress.fromJSON(object.email),
      object?.id ? Guid.fromJSON(object.id) : undefined
    );
    user.fullName = object.fullName;
    user.initials = object.initials;
    user.created = object.created;
    user.isSuperUser = object.isSuperUser;
    user.userIsDisabled = object.userIsDisabled;
    user.individualId = object.individualId ? Guid.fromJSON(object.individualId) : undefined;
    user.companyName = object.companyName;
    user.companyEntityId = object.companyEntityId ? Guid.fromJSON(object.companyEntityId) : undefined;
    user.isCompanyManager = object.isCompanyManager;
    user.companyId = object.companyId ? Guid.fromJSON(object.companyId) : undefined;
    return user;
  }

  public toJSON() : object {
    return {
      id: this.id?.toJSON(),
      name: this.name.toJSON(),
      fullName: this.fullName,
      initials: this.initials,
      email: this.email.toJSON(),
      created: this.created?.toJSON(),
      isSuperUser: this.isSuperUser,
      userIsDisabled: this.userIsDisabled,
      individualId: this.individualId?.toJSON(),
      companyName: this.companyName,
      companyEntityId: this.companyEntityId?.toJSON(),
      isCompanyManager: this.isCompanyManager,
      companyId: this.companyId?.toJSON()
    }
  }
}
