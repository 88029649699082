import { BoxType } from "notifications/values/box-type/box-type";

export default class NotificationParameters {
  boxType: BoxType;
  filterByActionRequired: boolean;
  filterByUnread: boolean;
  filterByMessageNotifications: boolean;
  topicId?: string;

  constructor(
    boxType: BoxType, 
    filterByActionRequired: boolean,
    filterByUnread: boolean,
    filterByMessageNotifications: boolean,
    topicId?: string
    ) {
    this.boxType = boxType;
    this.filterByActionRequired = filterByActionRequired;
    this.filterByUnread = filterByUnread;
    this.filterByMessageNotifications = filterByMessageNotifications;
    this.topicId = topicId;
  }

  public asSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    searchParams.append("boxType", this.boxType.toString());
    searchParams.append("filterByActionRequired", this.filterByActionRequired.toString());
    searchParams.append("filterByUnread", this.filterByUnread.toString());
    searchParams.append("filterByMessageNotifications", this.filterByMessageNotifications.toString());
    if(this.topicId){
      searchParams.append("topicId", this.topicId);
    }
    return searchParams;
  }
}
