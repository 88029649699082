import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { RootState } from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import EntityOfficerInvitationAPIService from "legal-entities/entities/entity-officer-invitation/api/entity-officer-invitation-api-service";
import EntityOfficerInvitation from "legal-entities/entities/entity-officer-invitation/entity-officer-invitation";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";


import Session from "users/session/session";

type OfficerInvitationState = {
    entries: Record<string, EntityOfficerInvitation>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
};

const initialState: OfficerInvitationState = {
    entries: {},
    loading: {},
    error: {},
};

const populateEntityOfficerInvitation = createAsyncThunk(
  "officerInvitations/getEntityOfficerInvitationById",
  async ({session, id}: {session: Session, id: Guid}, thunkAPI) => {
    try {
      const apiService = new EntityOfficerInvitationAPIService(session);
      const invitation = await apiService.getOfficerInvitationById(id);
      return invitation;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateOpenOfficerInvitations = createAsyncThunk(
    "officerInvitations/getOfficerInvitationsForLegalEntity",
    async ({session}: {session: Session}, thunkAPI) => {
      try {
        const apiService = new EntityOfficerInvitationAPIService(session);
        const invitations = await apiService.getAllOpenOfficerInvitations();
        return invitations;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
const officerInvitationsSlice = createSlice({
  name: "officerInvitations",
  initialState,
  reducers: {
    addEntityOfficerInvitation: (state, action: PayloadAction<EntityOfficerInvitation>) => {
        const invitation = action.payload;
        if(!invitation.id?.value){
            return;
        }
        state.entries[invitation.id.value] = invitation;
    },
    removeEntityOfficerInvitation: (state, action: PayloadAction<EntityOfficerInvitation>) => {
        const invitation = action.payload;
        if(!invitation.id?.value){
            return;
        }
        delete state.entries[invitation.id.value];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(populateEntityOfficerInvitation.pending, (state, action) => {
      const invitationId = action.meta.arg.id?.value;
      if(!invitationId){
        return;
      }
        state.loading[invitationId] = true;
        state.error[invitationId] = null;
    });
    builder.addCase(populateEntityOfficerInvitation.fulfilled, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.entries[invitationId] = action.payload;
        state.loading[invitationId] = false;
        state.error[invitationId] = null;
    });
    builder.addCase(populateEntityOfficerInvitation.rejected, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.loading[invitationId] = false;
        state.error[invitationId] = action.error;
    });
    
    builder.addCase(populateOpenOfficerInvitations.pending, (state, action) => {
        state.loading["allOpen"] = true;
        state.error["allOpen"] = null;
    });
    builder.addCase(populateOpenOfficerInvitations.fulfilled, (state, action) => {
        state.loading["allOpen"] = false;
        action.payload.forEach((invitation) => {
            if(!invitation.id?.value){
                return;
            }
            state.entries[invitation.id.value] = invitation;
        });
    });
    builder.addCase(populateOpenOfficerInvitations.rejected, (state, action) => {
        state.loading["allOpen"] = false;
        state.error["allOpen"] = action.error;
    });
  }
});

export const { addEntityOfficerInvitation, removeEntityOfficerInvitation } =
  officerInvitationsSlice.actions;
  
export const getEntityOfficerInvitationById = (id: Guid) =>
    (state: RootState) => state.entityOfficerInvitations.entries[id.value]
export const getIsLoadingEntityOfficerInvitationById = (id: Guid) =>
    (state: RootState) => state.entityOfficerInvitations.loading[id.value]
export const getErrorLoadingEntityOfficerInvitationById = (id: Guid) =>
    (state: RootState) => state.entityOfficerInvitations.error[id.value]

export const getOpenEntityOfficerInvitations = () =>
  (state: RootState) => state.entityOfficerInvitations.entries;
export const getIsLoadingOpenEntityOfficerInvitations = () =>
  (state: RootState) => state.entityOfficerInvitations.loading;
export const getErrorLoadingOpenEntityOfficerInvitations = () =>
  (state: RootState) => state.entityOfficerInvitations.error;

export default officerInvitationsSlice;
