export default class DocumentName {
    private _value: string;
    
    constructor(value: string) {
        this._value = value;
    }
    
    isEqualTo(other: DocumentName): boolean {
        return this.value === other?.value;
    }
    
    public get value(): string {
        return this._value;
    }
    
    public static get Prototype(): DocumentName {
        return new DocumentName("");
    }
    
    public static fromJSON(obj: any): DocumentName {
        return new DocumentName(obj);
    }
    public toJSON(): any {
        return this.value;
    }
    public clone(): DocumentName {
        return new DocumentName(this.value);
    }

    public toString(): string {
        return this.value;
    }
}