import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import * as Constants from "common/helpers/constants";
import reduxSignalRMiddleware, {
  withCallbacks,
} from "common/realtime/redux-signalr-middleware";
import MessageAPIResponse from "messaging/entities/message/api/response-contracts/message-api-response";
import Session from "users/session/session";
import { addCommentThread } from "work/entities/comment-thread/store/comment-thread-redux-slice";
import Comment from "work/entities/comment/comment";
import { addComment, removeComment } from "work/entities/comment/store/comments-redux-slice";

export const commentsHubConnection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(`${Constants.apiBaseUrl}/messageHub`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () =>
      Session.loadFromStorage(() => {}).authToken?.value ?? "",
  })
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add("message-received", (apiResponseObject: object) => (dispatch) => {
    const message = Object.assign(
      new MessageAPIResponse(),
      apiResponseObject
    ).deserialize();
    if (message.forum.topic?.entityClass !== "Work.Proposal") {
      return;
    }
    const comment = Comment.fromMessage(message);
    dispatch(addComment(comment));
    dispatch(addCommentThread(comment.thread));
  })
  .add("message-updated", (apiResponseObject: object) => (dispatch) => {
    const message = Object.assign(
      new MessageAPIResponse(),
      apiResponseObject
    ).deserialize();
    if (message.forum.topic?.entityClass !== "Work.Proposal") {
      return;
    }
    const comment = Comment.fromMessage(message);
    dispatch(addComment(comment));
    dispatch(addCommentThread(comment.thread));
  })
  .add("message-deleted", (apiResponseObject: object) => (dispatch) => {
    const message = Object.assign(
      new MessageAPIResponse(),
      apiResponseObject
    ).deserialize();
    if (!message.forum.topic?.context?.includes("review")) {
      return;
    }
    const comment = Comment.fromMessage(message);
    dispatch(removeComment(comment));
  });

export const commentsSignalRReduxMiddleware = reduxSignalRMiddleware({
  callbacks,
  connection: commentsHubConnection,
  shouldConnectionStartImmediately: true,
});
