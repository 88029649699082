import { configureStore } from "@reduxjs/toolkit";
import {
  documentsSlice,
} from "documents/entities/document/store/documents-redux-slice";
import administratorInvitationsSlice from "legal-entities/entities/administrator-invitation/store/administrator-invitations-redux-slice";
import { entityAgreementsSlice } from "legal-entities/entities/entity-agreement/store/entity-agreements-redux-slice";
import officerInvitationsSlice from "legal-entities/entities/entity-officer-invitation/store/entity-officer-invitations-redux-slice";
import openEntityMemberInvitationsSlice from "legal-entities/entities/open-entity-member-invitation/store/open-entity-member-invitations-redux-slice";
import userEntityMemberInvitationsSlice from "legal-entities/entities/user-entity-member-invitation/store/user-entity-member-invitation-redux-store";
import bookmarksSlice from "marketplace/entities/bookmark/store/bookmarks-redux-store";
import { companiesSlice } from "marketplace/entities/company/store/companies-redux-slice";
import { feeSchedulesSlice } from "marketplace/entities/fee-schedule/store/fee-schedule-redux-slice";
import { individualsSlice } from "marketplace/entities/individual/realtime-store/individuals-redux-slice";
import { marketplaceTeamsSlice } from "marketplace/entities/marketplace-team/store/marketplace-teams-redux-slice";
import Forum from "messaging/entities/forum/forum";
import forumsSlice from "messaging/entities/forum/store/forums-redux-slice";
import Message from "messaging/entities/message/message";
import messagesSlice from "messaging/entities/message/realtime-store/messages-redux-slice";
import { messagesSignalRReduxMiddleware } from "messaging/entities/message/realtime-store/messages-signalr-redux-middleware";

import notificationsSlice from "notifications/entities/notification/store/notifications-redux-slice";
import { notificationsSignalRReduxMiddleware } from "notifications/entities/notification/store/notifications-signalr-redux-middleware";
import { useDispatch } from "react-redux";
import commentThreadsSlice from "work/entities/comment-thread/store/comment-thread-redux-slice";
import { commentsSignalRReduxMiddleware } from "work/entities/comment/store/comments-signalr-redux-middleware";
import commentsSlice from "work/entities/comment/store/comments-redux-slice";
import Proposal from "work/entities/proposal/proposal";
import { proposalsSignalRReduxMiddleware } from "work/entities/proposal/store/proposals-signalr-redux-middleware";
import proposalsSlice from "work/entities/proposal/store/proposals-redux-slice";

const attorneyHubStore = configureStore({
  reducer: {
    // Documents
    documents: documentsSlice.reducer,

    // Legal Entities
    administratorInvitations: administratorInvitationsSlice.reducer,
    entityAgreements: entityAgreementsSlice.reducer,
    entityOfficerInvitations: officerInvitationsSlice.reducer,
    openEntityMemberInvitations: openEntityMemberInvitationsSlice.reducer,
    userEntityMemberInvitations: userEntityMemberInvitationsSlice.reducer,

    // Marketplace
    bookmarks: bookmarksSlice.reducer,
    individuals: individualsSlice.reducer,
    companies: companiesSlice.reducer,
    teams: marketplaceTeamsSlice.reducer,
    feeSchedules: feeSchedulesSlice.reducer,

    // Messaging
    forums: forumsSlice.reducer,
    messages: messagesSlice.reducer,

    // Notifications
    notifications: notificationsSlice.reducer,

    // Work
    proposals: proposalsSlice.reducer,

    commentThreads: commentThreadsSlice.reducer,
    comments: commentsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(messagesSignalRReduxMiddleware)
      .concat(notificationsSignalRReduxMiddleware)
      .concat(proposalsSignalRReduxMiddleware)
      .concat(commentsSignalRReduxMiddleware),
});

export type RootState = ReturnType<typeof attorneyHubStore.getState>;

export const useAttorneyHubDispatch = () =>
  useDispatch<typeof attorneyHubStore.dispatch>();
export default attorneyHubStore;
