import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import Guid from "common/values/guid/guid";
import EntityAgreementAPIService from "legal-entities/entities/entity-agreement/api/entity-agreement-api-service";
import EntityAgreement from "legal-entities/entities/entity-agreement/entity-agreement";
import Session from "users/session/session";

type EntityAgreementStoreState = {
  byId: {
    entries: Record<string, EntityAgreement>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  }
};

const initialState: EntityAgreementStoreState = {
    byId: {
    entries: {},
    loading: {},
    error: {}
  }
};
export const populateEntityAgreement = createAsyncThunk<
EntityAgreement,
  { session: Session; id: Guid }
>(
  "entityAgreements/getEntityAgreementById",
  async ({session, id}: {session: Session, id: Guid}, thunkAPI) => {
    try {
      const apiService = new EntityAgreementAPIService(session);
      const entityAgreement = await apiService.getEntityAgreementById(id);
      return entityAgreement;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const entityAgreementsSlice = createSlice({
  name: "entityAgreements",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(populateEntityAgreement.pending, (state, action) => {
        const id = action.meta.arg.id.value;
        state.byId.loading[id] = true;
        state.byId.error[id] = null;
    });
    builder.addCase(populateEntityAgreement.fulfilled, (state, action) => {
        const id = action.meta.arg.id.value;
        state.byId.entries[id] = action.payload;
        state.byId.loading[id] = false;
    });
    
    builder.addCase(populateEntityAgreement.rejected, (state, action) => {
        const id = action.meta.arg.id.value;
        state.byId.loading[id] = false;
        state.byId.error[id] = action.payload as SerializedError;
    });
  }
});

