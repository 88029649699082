import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import UndoIcon from "@mui/icons-material/Undo";
import WorkIcon from "@mui/icons-material/Work";
import {
  Badge,
  IconButton,
  Portal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Loader from "common/components/loader";
import LoadingButton from "common/components/loading-button";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import {
  getCommentThreadsByProposal,
  getIsLoadingCommentThreadsByProposal,
  populateCommentThreadsByProposal,
} from "work/entities/comment-thread/store/comment-thread-redux-slice";
import {
  getArePendingComments,
  savePendingComments,
  updateAutoGeneratedComments,
} from "work/entities/comment/store/comments-redux-slice";

import Comments from "work/entities/comment/view/comments";
import ProposalAPIService from "work/entities/proposal/api/proposal-api-service";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import {
  addProposal,
  getErrorLoadingProposalById,
  getIsLoadingProposalById,
  getProposalById,
  getRevisionsByProposalId,
  populateProposal,
  populateRevisions,
} from "work/entities/proposal/store/proposals-redux-slice";

import { useAttorneyHubDispatch } from "app/realtime-store/redux-store";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import ChangeTraverser from "work/entities/proposal/redlining/view/change-traverser";
import ConflictsTab from "work/entities/proposal/redlining/view/tabs/conflicts-tab";
import DetailsTab from "work/entities/proposal/redlining/view/tabs/details-tab";
import DiscountTab from "work/entities/proposal/redlining/view/tabs/discount-tab";
import FeeScheduleTab from "work/entities/proposal/redlining/view/tabs/fee-schedule-tab";
import PoliciesTab from "work/entities/proposal/redlining/view/tabs/policies-tab";
import TeamTab from "work/entities/proposal/redlining/view/tabs/team-tab";
import {
  HumanReadableProposalFieldName,
  ProposalFieldName,
  ProposalStatus,
} from "work/values/constants";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import ProposalIssuesBadge from "work/values/proposal-issues/view/proposal-issues-badge";
import ProposalReviewer from "work/values/proposal-reviewer";
import ReviewerSelector from "work/view/components/reviewer-selector";
import AutoCommentGenerator from "work/entities/comment/auto-comment-generator";
import _ from "lodash";

const Header = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(1),
  position: "sticky",
  top: "0px",
  zIndex: 10,
}));
const TabsContainer = styled(Tabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    overflow: "hidden",
  },
}));
const Content = styled("section")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
}));
const TabContent = styled("section")(({ theme }) => ({
  alignItems: "stretch",
  flexDirection: "column",
  display: "flex",
  flex: 1,
  margin: theme.spacing(2, 0),
}));
const ActionsContainer = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  backgroundColor: theme.palette.background.default,
  bottom: "0px",
  display: "flex",
  flexDirection: "row",
  paddingBottom: theme.spacing(2.5),
  position: "sticky",
  width: "100%",
  zIndex: 10,
}));
const ProposalActions = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 0,
    flexWrap: "nowrap",
  },
  alignContent: "center",
  alignItems: "center",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))",
  gap: theme.spacing(1),
  minHeight: "64px",
  width: "100%",
}));
const ChangeNavigationContainer = styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(1),
  width: "100%",
}));
const NavTab = styled(Tab)(({ theme }) => ({
  minWidth: "fit-content",
  paddingLeft: 0,
}));
const ProposalActionButton = styled(LoadingButton)(({ theme }) => ({
  minWidth: theme.spacing(24),
  whiteSpace: "nowrap",
  width: "100%",
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.26) !important",
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
  },
}));
const DialogTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    overflow: "visible",
  },
}));
const CommentButtons = styled("section")(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(2),
}));
const CommentButton = styled(IconButton)(({ theme }) => ({
  paddingBottom: 0,
}));
const CommentButtonLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.6em",
}));
const SidePanel = styled(Drawer)(({ theme }) => ({
  position: "fixed",
  zIndex: theme.zIndex.modal + 1,
}));
const SidePanelContainer = styled("div")(({ theme }) => ({
  flex: 1,
  height: "100%",
}));
const SidePanelContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: theme.spacing(2),
  width: "400px",
}));
const TitleBar = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  paddingBottom: theme.spacing(1),
}));

type ProposalRedlineProps = {
  proposalId: Guid;
  disableEditing?: boolean;
  isDirtyRef: React.MutableRefObject<boolean>;
  navigate: (url: string) => void;
  onDirtyChange?: (isDirty: boolean) => void;
};

export default function ProposalRedlineDialog(
  props: Readonly<ProposalRedlineProps>
) {
  const {
    proposalId,
    isDirtyRef,
    onDirtyChange,
    navigate
  } = props;
  const session = useSession();
  const { closeAllDialogs } = useDialog();
  const confirm = useConfirmDialog();

  const [activeTab, setActiveTab] = React.useState<ProposalFieldCategory>(
    ProposalFieldCategory.Details
  );
  const [currentCommentField, setCurrentCommentField] = React.useState<
    ProposalField | undefined
  >();
  const [sidePanelTitle, setSidePanelTitle] = React.useState<
    string | undefined
  >();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [reviewerSelectorAnchor, setReviewerSelectorAnchor] =
    React.useState<HTMLButtonElement>();
  const [isManagingReviewers, setIsManagingReviewers] =
    React.useState<boolean>(false);
  const [isApprovingReview, setIsApprovingReview] =
    React.useState<boolean>(false);
  const [activeReviewField, setActiveReviewField] =
    React.useState<ProposalField>();
  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState<boolean>(false);

  const proposal = getProposalById(proposalId);
  const proposalRevisions = getRevisionsByProposalId(proposalId);
  const isLoadingProposal = getIsLoadingProposalById(proposalId);
  const errorLoadingProposal = getErrorLoadingProposalById(proposalId);
  const commentThreads = getCommentThreadsByProposal(proposal);
  const arePendingComments = getArePendingComments(proposal);
  const isLoadingCommentThreads =
    getIsLoadingCommentThreadsByProposal(proposal);
  const isLoading = isLoadingProposal || isLoadingCommentThreads;
  const dispatch = useAttorneyHubDispatch();

  let isReviewing = false;
  if (session.context?.viewingAsVendor) {
    isReviewing =
      proposal?.vendorReviewers.some((reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id)
      ) ?? false;
  } else {
    isReviewing =
      proposal?.clientReviewers.some((reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id)
      ) ?? false;
  }

  useEffect(() => {
    if (!proposal) {
      return;
    }
    if (proposal.supersededById && !proposalRevisions?.length) {
      dispatch(populateRevisions({ session, proposalId: proposalId }));
      return;
    }
    if (proposalRevisions?.length && proposalRevisions.length > 0) {
      const latestRevisionId =
        proposalRevisions[proposalRevisions.length - 1].id;
      if (!latestRevisionId) {
        console.error("Latest revision id is missing");
        return;
      }
      dispatch(
        populateProposal({
          session,
          id: latestRevisionId,
          redline: proposal.redline,
        })
      );
      navigate(`/proposals/active/revise/${latestRevisionId}`);
      return;
    }

    if (proposal && commentThreads === undefined) {
      dispatch(
        populateCommentThreadsByProposal({
          session: session,
          proposal: proposal,
        })
      );
    }
    setActiveReviewField(proposal.redline?.fieldsPendingReview[0]);
  }, [proposal, proposalId]);

  useEffect(() => {
    if (activeReviewField?.category !== activeTab) {
      setActiveTab(activeReviewField?.category ?? activeTab);
    }
  }, [activeReviewField]);

  useEffect(() => {
    if (!session?.canSwitchContext || !proposal) {
      return;
    }

    if (
      session.context?.viewingAsVendor &&
      proposal.client?.userId.isEqualTo(session.user?.id)
    ) {
      session.setAccountViewType(AccountType.Client);
    } else if (
      proposal.team?.leader?.userId.isEqualTo(session.user?.id) ||
      proposal.team?.memberUserIds?.some((member) =>
        member.isEqualTo(session.user?.id)
      )
    ) {
      session.setAccountViewType(AccountType.Vendor);
    }
  }, [proposal]);

  useEffect(() => {
    if (!proposal || proposal.supersededById) return;
    generateChangeAutoComments(proposal);
  }, [proposal]);  

  function handleTabChange(
    _event: React.ChangeEvent<{}> | null,
    newTab: ProposalFieldCategory
  ) {
    setActiveTab(newTab);
  }

  function handlePrevNext(direction: "previous" | "next") {
    const tabKeys = Object.keys(ProposalFieldCategory);
    const activeTabIndex = tabKeys.indexOf(activeTab);
    const targetTabKey =
      direction === "previous"
        ? tabKeys[activeTabIndex - 1]
        : tabKeys[activeTabIndex + 1];
    handleTabChange(
      null,
      ProposalFieldCategory[
        targetTabKey as keyof typeof ProposalFieldCategory
      ] ?? activeTab
    );
  }

  async function handleSave() {
    if (!proposal) {
      console.error("No proposal to save");
      return;
    }
    try {
      setIsSaving(true);
      dispatch(savePendingComments(session));
      const updatedProposal = await proposal.save(session);
      dispatch(addProposal(updatedProposal));
      onDirtyChange?.(false);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to save proposal", { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  }

  function handleManageReviewers(event: React.MouseEvent<HTMLButtonElement>) {
    setReviewerSelectorAnchor(event.currentTarget);
    setIsManagingReviewers(true);
  }

  async function handleSubmitRevision() {
    if (!proposal) return;
    try {
      setIsSubmitting(true);
      let updatedProposal = await proposal.save(session);
      dispatch(savePendingComments(session));

      if (!updatedProposal) {
        console.error("Failed to save proposal");
        return;
      }

      const submitResponse = await confirm({
        title: "Send Requested Changes?",
        message:
          "Doing so will send all your requested changes for review by the other party.",
        okButtonText: "Send",
      });
      if (submitResponse === ConfirmResponse.Cancel) return;

      const revision = await proposal.requestRevision(session);
      dispatch(addProposal(revision));
      closeAllDialogs();      
      navigate(`/proposals/active/revise/${revision.id}`);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(`${error}`, { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleApproveProposal() {
    dispatch(savePendingComments(session));
    if (!proposal) {
      console.error("No proposal to approve");
      return;
    }
    if (!proposal.redline?.revisionsAccepted) {
      console.error(
        "Cannot approve proposal where redline revisions not accepted."
      );
      return;
    }
    const approveResponse = await confirm({
      title: "Approve Proposal?",
      message:
        "Doing so will approve the proposal and send it to any other parties for their approval.",
      okButtonText: "Approve",
    });

    if (approveResponse === ConfirmResponse.Cancel) {
      setIsSubmitting(false);
      return;
    }
    const updatedProposal = await proposal.approve(session.user?.id);
    dispatch(addProposal(updatedProposal));
  }

  async function handleAccept() {
    if (!proposal) return;

    const acceptResponse = await confirm({
      title: "Accept All Changes?",
      message: "This will mark all changes as accepted.",
      okButtonText: "Accept",
    });

    if (acceptResponse === ConfirmResponse.Cancel) return;

    const updatedRedline = proposal.redline?.clone();
    if (!updatedRedline) return;
    updatedRedline.accept();
    handleProposalRedlineChanged(updatedRedline);
  }

  async function handleReject() {
    if (!proposal) return;
    dispatch(savePendingComments(session));


    const rejectResponse = await confirm({
      title: "Reject All Changes?",
      message: "This will mark all changes as rejected.",
      okButtonText: "Reject",
    });

    if (rejectResponse === ConfirmResponse.Cancel) return;

    const updatedRedline = proposal.redline?.clone();
    if (!updatedRedline) return;
    updatedRedline.reject();
    handleProposalRedlineChanged(updatedRedline);
  }

  async function handleEndNegotiation() {
    if (!proposal) return;
    dispatch(savePendingComments(session));

    try {
      const endResponse = await confirm({
        title: "End Negotiation?",
        message: "Doing so will end proposal negotations.",
        okButtonText: "End Negotiation",
      });

      if (endResponse === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);
      navigate(`/proposals/active/`);
      const updatedProposal = await proposal.reject(session.user?.id);
      dispatch(addProposal(updatedProposal));   
      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to reject proposal", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleCancel() {
    if (!proposal) return;
    dispatch(savePendingComments(session));

    try {
      const rejectResponse = await confirm({
        title: "Cancel Proposal?",
        message: "Doing so will end proposal negotations.",
      });

      if (rejectResponse === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);
      navigate(`/proposals/active/`);
      const updatedProposal = await proposal.cancel(session.user?.id);
      dispatch(addProposal(updatedProposal));
      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to cancel proposal", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleHire() {
    if (!proposal) return;
    dispatch(savePendingComments(session));

    try {
      const hireResponse = await confirm({
        title: "Hire?",
        message: "Doing so will hire the selected vendor.",
        okButtonText: "Hire",
      });

      if (hireResponse === ConfirmResponse.Cancel) return;

      setIsSubmitting(true);

      const updatedProposal = await proposal.hire(session.user?.id);
      dispatch(addProposal(updatedProposal));

      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to hire vendor", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleUndoChanges() {
    if (!proposal) return;

    const undoResponse = await confirm({
      title: "Undo All?",
      message: "Doing so will revert all changes.",
      okButtonText: "Undo All",
    });

    if (undoResponse === ConfirmResponse.Cancel) return;

    const updatedRedline = proposal.redline?.clone();
    if (!updatedRedline) return;
    updatedRedline.undo();
    handleProposalRedlineChanged(updatedRedline);
  }

  function shouldDisableTabs(): boolean {
    return isLoading || isSaving || isManagingReviewers;
  }

  function handleProposalRedlineChanged(newProposalRedline: ProposalRedline) {
    if(proposal?.isArchived){
      return;
    }    
    if (!proposal?.redline) {
      throw new Error("Current proposal redline is missing");
    }
    const updatedProposal = proposal.updateRedline(newProposalRedline);
    isDirtyRef.current = !newProposalRedline.isEqualTo(
      proposal.details?.redlining
    );

    dispatch(addProposal(updatedProposal));
  }

  async function generateChangeAutoComments(updatedProposal: Proposal) {
    if (!updatedProposal?.redline) return;
    const autoComments = new AutoCommentGenerator(
      session,
      updatedProposal,
      undefined,
      commentThreads ?? []
    ).generateAutoCommentsFromRedline();
    dispatch(updateAutoGeneratedComments(autoComments));
  }

  function handleTeamTemplatesUpdated(templateIds: Guid[]) {
    if (!proposal) {
      console.error("No proposal to update team templates");
      return;
    }
    let updatedProposal: Proposal;
    if (session.context?.viewingAsVendor) {
      updatedProposal = proposal.updateVendorTeamTemplateIds(templateIds);
    } else {
      updatedProposal = proposal.updateClientTeamTemplateIds(templateIds);
    }
    dispatch(addProposal(updatedProposal));
  }

  function handleFeeScheduleTemplatesUpdated(
    templateIds: Guid[],
    newProposalRedline?: ProposalRedline
  ) {
    if (!proposal) {
      console.error("No proposal to update fee schedule templates");
      return;
    }
    let updatedProposal: Proposal;
    if (session.context?.viewingAsVendor) {
      updatedProposal =
        proposal.updateVendorFeeScheduleTemplateIds(templateIds);
    } else {
      updatedProposal =
        proposal.updateClientFeeScheduleTemplateIds(templateIds);
    }
    if (newProposalRedline) {
      updatedProposal = updatedProposal.updateRedline(newProposalRedline);
      generateChangeAutoComments(updatedProposal);
    }
    dispatch(addProposal(updatedProposal));
  }

  async function handleToggleCommentsPanel(
    field?: ProposalField,
    sidePanelTitle?: string,
    setToOpen?: boolean
  ) {
    setCurrentCommentField(field);
    setSidePanelTitle(sidePanelTitle);

    if (
      !setToOpen &&
      isSidePanelOpen &&
      field === currentCommentField &&
      ((!field && !currentCommentField) ||
        field?.isEqualTo(currentCommentField))
    ) {
      setIsSidePanelOpen(false);
      adjustDialogPosition(true);
      return;
    }

    setIsSidePanelOpen(true);
    adjustDialogPosition(false);
    if (!field) return;
  }

  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName("MuiDialog-root")) {
      dialog.setAttribute(
        "style",
        `padding-right: ${
          panelOpen ? "0px" : "400px"
        }; transition: padding-right 225ms;`
      );
    }
  }

  async function handleApproveReview() {
    if (!proposal) throw new Error("No proposal to approve");

    try {
      const response = await confirm({
        title: "Approve Review",
        message: "Are you sure you want to approve this review?",
        okButtonText: "Approve",
      });

      if (response === ConfirmResponse.Cancel) return;

      setIsApprovingReview(true);
      const proposalService = new ProposalAPIService(session);
      await proposalService.giveReviewerApproval(proposal);
      closeAllDialogs();
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Failed to approve review", { variant: "error" });
    } finally {
      setIsApprovingReview(false);
    }
  }

  async function handleProposalReviewersChanged(reviewers: ProposalReviewer[]) {
    if (!proposal) {
      console.error("No proposal to update reviewers");
      return;
    }
    let updatedProposal: Proposal;
    if (session.context?.viewingAsVendor) {
      updatedProposal = proposal.updateVendorReviewers(reviewers);
    } else {
      updatedProposal = proposal.updateClientReviewers(reviewers);
    }
    const savedProposal = await updatedProposal.save(session);
    dispatch(addProposal(savedProposal));
  }

  function hasUserApprovedReview(): boolean {
    let reviewers: ProposalReviewer[] = [];

    if (session.context?.viewingAsVendor) {
      reviewers = proposal?.vendorReviewers ?? [];
    } else {
      reviewers = proposal?.clientReviewers ?? [];
    }

    return reviewers.some(
      (reviewer) =>
        reviewer.userId.isEqualTo(session.user?.id) && reviewer.dateApproved
    );
  }

  function getSidePanelTitle(): string {
    if (sidePanelTitle) return `${sidePanelTitle} Comments`;
    return `${
      HumanReadableProposalFieldName[
        currentCommentField?.name ?? ProposalFieldName.General
      ]
    } Comments`;
  }

  async function handleTeamMemberQuitProposal(memberId: Guid): Promise<void> {
    if (!proposal?.id) return;
    if (!memberId.isEqualTo(session.user?.id)) return;

    try {
      const service = new ProposalAPIService(session);
      await service.quitProposalTeam(proposal.id);
      enqueueSnackbar("You have quit the proposal team", {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to quit proposal team", { variant: "error" });
    }
  }

  const issues = proposal?.redline
    ? ProposalIssues.fromRedline(proposal?.redline)
    : undefined;

  return !errorLoadingProposal && proposal !== null && (
    <>
      <Header>
        <TabsContainer
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}
        >
          <NavTab
            disabled={
              shouldDisableTabs() || activeTab === ProposalFieldCategory.Details
            }
            icon={<NavigateBeforeIcon fontSize="large" />}
            onClick={async () => handlePrevNext("previous")}
          />
          <DialogTab
            value={ProposalFieldCategory.Details}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Details}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Team}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Team}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.FeeSchedule}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.FeeSchedule}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Conflicts}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Conflicts}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Policies}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Policies}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Discount}
            disabled={shouldDisableTabs()}
            label={
              <ProposalIssuesBadge
                issues={issues}
                category={ProposalFieldCategory.Discount}
              />
            }
          />
          <NavTab
            disabled={
              shouldDisableTabs() ||
              activeTab === ProposalFieldCategory.Discount
            }
            icon={<NavigateNextIcon fontSize="large" />}
            onClick={async () => handlePrevNext("next")}
          />
        </TabsContainer>
      </Header>
      <Content>
        <TabContent>
          {(isLoading ||
            !proposal?.redline ||
            !proposal.details?.client ||
            !commentThreads) && <Loader />}
          {!isLoading &&
            proposal?.redline &&
            proposal.details?.client &&
            commentThreads && (
              <>
                <DetailsTab
                  isOpen={activeTab === ProposalFieldCategory.Details}
                  proposalRedline={proposal.redline}
                  issues={issues}
                  activeReviewField={activeReviewField}
                  commentThreads={commentThreads}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalField,
                    name?: string,
                    setToOpen?: boolean
                  ) => {
                    handleToggleCommentsPanel(field, name, setToOpen);
                  }}
                  clientName={proposal.details.client.getFullName()}
                />
                <TeamTab
                  isOpen={activeTab === ProposalFieldCategory.Team}
                  proposalRedline={proposal.redline}
                  issues={issues}
                  activeReviewField={activeReviewField}
                  commentThreads={commentThreads}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalField,
                    name?: string,
                    setToOpen?: boolean
                  ) => {
                    handleToggleCommentsPanel(field, name, setToOpen);
                  }}
                  teamLeader={proposal.details?.team?.leader}
                  onTeamTemplatesUpdated={handleTeamTemplatesUpdated}
                  onTeamMemberQuitProposal={handleTeamMemberQuitProposal}
                />
                <FeeScheduleTab
                  isOpen={activeTab === ProposalFieldCategory.FeeSchedule}
                  proposalRedline={proposal.redline}
                  issues={issues}
                  activeReviewField={activeReviewField}
                  commentThreads={commentThreads}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onCommentsClicked={(
                    field: ProposalField,
                    name?: string,
                    setToOpen?: boolean
                  ) => {
                    handleToggleCommentsPanel(field, name, setToOpen);
                  }}
                  onFeeScheduleTemplatesUpdated={
                    handleFeeScheduleTemplatesUpdated
                  }
                />
                <ConflictsTab
                  isOpen={activeTab === ProposalFieldCategory.Conflicts}
                  proposalRedline={proposal.redline}
                  issues={issues}
                  activeReviewField={activeReviewField}
                  commentThreads={commentThreads}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalField,
                    name?: string,
                    setToOpen?: boolean
                  ) => {
                    handleToggleCommentsPanel(field, name, setToOpen);
                  }}
                />
                <PoliciesTab
                  isOpen={activeTab === ProposalFieldCategory.Policies}
                  proposalRedline={proposal.redline}
                  issues={issues}
                  activeReviewField={activeReviewField}
                  commentThreads={commentThreads}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalField,
                    name?: string,
                    setToOpen?: boolean
                  ) => {
                    handleToggleCommentsPanel(field, name, setToOpen);
                  }}
                />
                <DiscountTab
                  isOpen={activeTab === ProposalFieldCategory.Discount}
                  proposalRedline={proposal.redline}
                  issues={issues}
                  activeReviewField={activeReviewField}
                  commentThreads={commentThreads}
                  onProposalRedlineChange={handleProposalRedlineChanged}
                  onTraverseToNewField={setActiveReviewField}
                  onCommentsClicked={(
                    field: ProposalField,
                    name?: string,
                    setToOpen?: boolean
                  ) => {
                    handleToggleCommentsPanel(field, name, setToOpen);
                  }}
                />
              </>
            )}
        </TabContent>
      </Content>
      <ChangeNavigationContainer>
        {proposal?.redline && (
          <ChangeTraverser
            redline={proposal.redline}
            currentField={activeReviewField}
            onTraverseToNewField={setActiveReviewField}
          />
        )}
      </ChangeNavigationContainer>
      <ActionsContainer>
        <ProposalActions>
          <ProposalActionButton
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            loading={isSaving}
            disabled={
              isSubmitting ||
              isLoading ||
              isSaving ||
              isManagingReviewers ||
              ((proposal?.redline?.sessionHistory?.length === 0 ||
                proposal?.redline?.isEqualTo(proposal?.details?.redlining)) &&
                !arePendingComments)
            }
            onClick={handleSave}
          >
            Save
          </ProposalActionButton>
          {isReviewing && (
            <ProposalActionButton
              variant="contained"
              color="success"
              startIcon={<CheckIcon />}
              loading={isSaving}
              disabled={
                isLoading ||
                isSaving ||
                isApprovingReview ||
                isManagingReviewers ||
                hasUserApprovedReview()
              }
              onClick={handleApproveReview}
            >
              {hasUserApprovedReview() ? "Approved" : "Approve"}
            </ProposalActionButton>
          )}
          <ProposalActionButton
            variant="contained"
            color="primary"
            startIcon={<RateReviewIcon />}
            loading={false}
            disabled={
              isLoading ||
              isSaving ||
              isManagingReviewers ||
              reviewerSelectorAnchor !== undefined ||
              !proposal?.id ||
              isReviewing
            }
            onClick={handleManageReviewers}
          >
            Manage Reviewers
          </ProposalActionButton>
          <ReviewerSelector
            proposal={proposal}
            popoverAnchor={reviewerSelectorAnchor}
            onPopoverClose={() => {
              setReviewerSelectorAnchor(undefined);
              setIsManagingReviewers(false);
            }}
            onChange={handleProposalReviewersChanged}
          />
          {proposal?.supersedes && !proposal?.userCanHire(session.user) && (
            <>
              <ProposalActionButton
                variant="contained"
                color="success"
                startIcon={<CheckIcon />}
                loading={false}
                disabled={
                  isSubmitting ||
                  isLoading ||
                  isSaving ||
                  isManagingReviewers ||
                  reviewerSelectorAnchor !== undefined ||
                  !proposal?.id ||
                  proposal.creator?.userId.isEqualTo(session.user?.id) ||
                  !proposal.userCanApprove(session.user) ||
                  proposal.redline?.isResolved
                }
                onClick={handleAccept}
              >
                Accept All
              </ProposalActionButton>
              <ProposalActionButton
                variant="contained"
                color="error"
                startIcon={<CloseIcon />}
                loading={false}
                disabled={
                  isSubmitting ||
                  isLoading ||
                  isSaving ||
                  isManagingReviewers ||
                  reviewerSelectorAnchor !== undefined ||
                  !proposal?.id ||
                  proposal.creator?.userId.isEqualTo(session.user?.id) ||
                  !proposal.userCanReject(session.user) ||
                  proposal.redline?.isResolved
                }
                onClick={handleReject}
              >
                Reject All
              </ProposalActionButton>
            </>
          )}
          {proposal?.creator?.userId.isEqualTo(session.user?.id) && (
            <ProposalActionButton
              variant="contained"
              color="error"
              startIcon={<CloseIcon />}
              loading={false}
              disabled={
                isSubmitting ||
                isLoading ||
                isSaving ||
                isManagingReviewers ||
                reviewerSelectorAnchor !== undefined ||
                !proposal?.id ||
                isReviewing
              }
              onClick={handleCancel}
            >
              Cancel Proposal
            </ProposalActionButton>
          )}
          {proposal?.userCanApprove(session.user) &&
            proposal?.redline?.revisionsAccepted && (
              <Tooltip title="Submit Approval">
                <ProposalActionButton
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  loading={isSubmitting}
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    isSaving ||
                    isManagingReviewers ||
                    !proposal?.redline?.isResolved ||
                    (!proposal?.userCanApprove(session.user) && isReviewing)
                  }
                  onClick={handleApproveProposal}
                >
                  Submit Approval
                </ProposalActionButton>
              </Tooltip>
            )}

          {proposal?.userCanRevise(session.user) &&
            proposal?.redline?.canBeUndone && (
              <Tooltip title="Submit Changes">
                <ProposalActionButton
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  loading={isSubmitting}
                  disabled={
                    isSubmitting ||
                    isLoading ||
                    isSaving ||
                    isManagingReviewers ||
                    !proposal?.redline?.isResolved
                  }
                  onClick={handleSubmitRevision}
                >
                  Submit Changes
                </ProposalActionButton>
              </Tooltip>
            )}

          {proposal?.userCanHire(session.user) && (
            <ProposalActionButton
              variant="contained"
              color="success"
              startIcon={<WorkIcon />}
              loading={false}
              disabled={
                isSubmitting ||
                isLoading ||
                isSaving ||
                isManagingReviewers ||
                reviewerSelectorAnchor !== undefined ||
                !proposal?.id ||
                isReviewing
              }
              onClick={handleHire}
            >
              Hire
            </ProposalActionButton>
          )}
          {proposal?.redline?.canBeUndone && (
            <ProposalActionButton
              variant="contained"
              color="secondary"
              startIcon={<UndoIcon />}
              loading={false}
              disabled={
                isSubmitting || isLoading || isSaving || isManagingReviewers
              }
              onClick={handleUndoChanges}
            >
              Undo Changes
            </ProposalActionButton>
          )}
          {!proposal?.creator?.userId.isEqualTo(session.user?.id) && (
            <ProposalActionButton
              variant="text"
              color="error"
              startIcon={<BlockIcon />}
              loading={false}
              disabled={
                isSubmitting ||
                isLoading ||
                isSaving ||
                isManagingReviewers ||
                reviewerSelectorAnchor !== undefined ||
                !proposal?.id ||
                isReviewing
              }
              onClick={handleEndNegotiation}
            >
              End Negotiation
            </ProposalActionButton>
          )}
        </ProposalActions>
        <CommentButtons>
          <ButtonContainer>
            <CommentButton
              size="medium"
              color="primary"
              onClick={() => {
                handleToggleCommentsPanel(
                  ProposalField.General,
                  "General Comments"
                );
              }}
            >
              <Badge
                variant="dot"
                color="secondary"
                overlap="circular"
                invisible={
                  !commentThreads?.some((thread) =>
                    thread.field.isEqualTo(ProposalField.General)
                  )
                }
              >
                <CommentIcon fontSize="medium" />
              </Badge>
            </CommentButton>
            <CommentButtonLabel variant="button" color="primary">
              Comments
            </CommentButtonLabel>
          </ButtonContainer>
        </CommentButtons>
        {proposal && commentThreads && (
          <Portal>
            <SidePanel
              open={isSidePanelOpen}
              anchor="right"
              variant="persistent"
            >
              <SidePanelContainer>
                <SidePanelContent>
                  <TitleBar>
                    <Typography variant="h5">{getSidePanelTitle()}</Typography>
                    <IconButton onClick={() => handleToggleCommentsPanel()}>
                      <CloseIcon />
                    </IconButton>
                  </TitleBar>
                  <Comments
                    proposal={proposal}
                    commentThreads={commentThreads}
                    field={currentCommentField ?? ProposalField.General}
                    isSaving={isSaving}
                  />
                </SidePanelContent>
              </SidePanelContainer>
            </SidePanel>
          </Portal>
        )}
      </ActionsContainer>
    </>
  );
}
