import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornmentProps,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AccountType } from "common/values/account-type/account-type";
import Date from "common/values/date/date";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import NetworkedUserInfo from "users/entities/user-network-connection/networked-user-info";
import { useSession } from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import ClientSelector from "work/view/components/client-selector";

const ProposalContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
  display: "grid",
  gap: theme.spacing(6),
  gridTemplateColumns: "auto auto",
  width: "100%",
}));
const GeneralSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));
const DateSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& .MuiFormControl-root": {
    marginTop: theme.spacing(1),
  },
}));
const ClientTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const NameTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const DescriptionTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "unset",
    width: "100%",
  },
  margin: theme.spacing(1, 0),
  minWidth: theme.spacing(50),
}));
const NegotiableCheckbox = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));
const DateContainer = styled("div")(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(1, 0),
  width: "100%",
  "& > span:first-of-type": {
    flex: 1,
  },
}));
const DateControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: "100%",
}));
const DateFieldButtons = styled("span")(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  "& > :first-child": {
    marginRight: 0,
  },
}));

type DetailsTabProps = {
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  isLoading: boolean;
  issues?: ProposalIssues;
  disableCommenting: boolean;
  disableEditing?: boolean;
  disableValidate?: boolean;
  commentThreads: CommentThread[];
  isRequesting?: boolean;
  onProposalBuilderUpdated: (newBuilder: ProposalBuilder) => void;
  onSelectingClientChange: (isSelectingClient: boolean) => void;
  onCommentsClicked: (
    field: ProposalField,
    name?: string,
    setToOpen?: boolean
  ) => void;
};

export default function DetailsTab(props: Readonly<DetailsTabProps>) {
  const {
    activeTab,
    proposalBuilder,
    issues,
    disableCommenting,
    disableEditing,
    commentThreads,
    isRequesting,
    disableValidate,
    onProposalBuilderUpdated,
    onSelectingClientChange,
    onCommentsClicked,
  } = props;

  const [nameValue, setNameValue] = useState<string>(
    proposalBuilder.currentSpec.name?.value ?? ""
  );
  const [descriptionValue, setDescriptionValue] = useState<string>(
    proposalBuilder.currentSpec.description?.value ?? ""
  );
  const [isNegotiable, setIsNegotiable] = useState<boolean>(
    proposalBuilder.currentSpec.negotiable ?? true
  );
  const [isUsingResponseDueBy, setIsUsingResponseDueBy] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.responseDueBy)
  );
  const [isUsingStartDate, setIsUsingStartDate] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.startDate)
  );
  const [isUsingEndDate, setIsUsingEndDate] = useState<boolean>(
    Boolean(proposalBuilder.currentSpec.endDate)
  );
  const [startDate, setStartDate] = useState<Moment | null>(
    proposalBuilder.currentSpec.startDate?.value ?? null
  );
  const [isSelectingClient, setIsSelectingClient] = useState<boolean>(false);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [isEditingDescription, setIsEditingDescription] =
    useState<boolean>(false);
  const [clientSelectorAnchor, setClientSelectorAnchor] =
    useState<HTMLDivElement>();

  const session = useSession();

  useEffect(() => {
    if (!proposalBuilder.currentSpec.client) {
      setDefaultProposalClient();
    }
  }, []);

  useEffect(() => {
    setNameValue(proposalBuilder.currentSpec.name?.value ?? "");
    setDescriptionValue(proposalBuilder.currentSpec.description?.value ?? "");
    setIsNegotiable(proposalBuilder.currentSpec.negotiable ?? true);
    setIsUsingResponseDueBy(Boolean(proposalBuilder.currentSpec.responseDueBy));
    setIsUsingStartDate(Boolean(proposalBuilder.currentSpec.startDate));
    setIsUsingEndDate(Boolean(proposalBuilder.currentSpec.endDate));
    setStartDate(proposalBuilder.currentSpec.startDate?.value ?? null);
  }, [proposalBuilder]);

  function getMinValidDate(compareDate: Moment, daysToAdd: number = 0) {
    const currentDateUnix = moment().unix();
    const compareDateUnix = compareDate.unix();

    return moment
      .unix(Math.max(currentDateUnix, compareDateUnix))
      .add(daysToAdd, "day");
  }

  function setDefaultProposalClient() {
    if (
      session.accountType === AccountType.Client &&
      session.context?.isClientRepresentative &&
      session.user?.asClientRepresentative &&
      !proposalBuilder.currentSpec.client
    ) {
      onProposalBuilderUpdated(
        proposalBuilder.setClient(session.user.asClientRepresentative)
      );
    }
  }

  function handleSelectClientClicked(event: React.MouseEvent<HTMLDivElement>) {
    const inputElements = event.currentTarget.getElementsByTagName("input");
    setClientSelectorAnchor(
      inputElements.length > 0 ? inputElements[0] : event.currentTarget
    );
    setIsSelectingClient(true);
    onSelectingClientChange(true);
  }

  async function handleClientUpdated(userInfo?: NetworkedUserInfo) {
    if (!userInfo?.userId || !userInfo?.companyEntityId || !userInfo?.name) {
      onProposalBuilderUpdated(proposalBuilder.setClient());
    } else {
      onProposalBuilderUpdated(
        proposalBuilder.setClient(
          new EntityClientRepresentative(
            userInfo.userId,
            userInfo.companyEntityId,
            userInfo.name
          )
        )
      );
    }
  }

  function renderCommentsButton(field: ProposalField) {
    return (
      <Tooltip
        title={
          disableCommenting ? "Save proposal to enable commenting" : "Comments"
        }
      >
        <span>
          <IconButton
            disabled={disableCommenting}
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked(field);
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={
                !commentThreads.some((thread) => thread.field.isEqualTo(field))
              }
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderTextFieldButtons(
    field: "name" | "description",
    isEditing: boolean,
    setter: (value: React.SetStateAction<boolean>) => void,
    callback: (newValue: string) => void
  ) {
    return (
      <>
        {!disableEditing && !isEditing && (
          <Tooltip title="Edit">
            <span>
              <IconButton
                tabIndex={-1}
                onClick={(event) => {
                  event.stopPropagation();
                  setter(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {isEditing && (
          <>
            <Tooltip title="Submit">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    callback(field === "name" ? nameValue : descriptionValue);
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Cancel">
              <span>
                <IconButton
                  datatype="cancel"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setter(false);
                    if (field === "name") {
                      setNameValue(
                        proposalBuilder.currentSpec.name?.value ?? ""
                      );
                    }
                    if (field === "description") {
                      setDescriptionValue(
                        proposalBuilder.currentSpec.description?.value ?? ""
                      );
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  function renderDateFieldButtons(
    params: InputAdornmentProps,
    field: ProposalField
  ) {
    return (
      <DateFieldButtons>
        {params.children}
        {renderCommentsButton(field)}
      </DateFieldButtons>
    );
  }

  function setProposalNameValue(newNameValue: string) {
    setIsEditingName(false);
    const projectName = new ProjectName(newNameValue);
    if(projectName.isEqualTo(proposalBuilder.currentSpec.name)) return;
    onProposalBuilderUpdated(proposalBuilder.setName(projectName));
  }

  function setProposalDescriptionValue(newDescriptionValue: string) {
    setIsEditingDescription(false);
    const projectDescription = new ProjectDescription(newDescriptionValue);
    onProposalBuilderUpdated(
      proposalBuilder.setDescription(projectDescription)
    );
  }

  if (activeTab !== ProposalFieldCategory.Details) return null;

  return (
    <ProposalContainer>
      <GeneralSection>
        <ClientTextField
          disabled={
            disableEditing ||
            isSelectingClient ||
            clientSelectorAnchor !== undefined
          }
          label={
            proposalBuilder.currentSpec.client?.name.toString()
              ? "Client Representative"
              : "Select Client Representative"
          }
          error={issues?.entries.some(
            (entry) => entry.field === ProposalField.Client
          )}
          helperText={issues?.entries
            .filter((entry) => entry.field === ProposalField.Client)
            .map((entry) => entry.description)
            .join("\n")}
          required
          onClick={handleSelectClientClicked}
          value={proposalBuilder.currentSpec.client?.name.toString() ?? ""}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {!disableEditing && proposalBuilder.currentSpec.client && (
                    <Tooltip title="Remove Client">
                      <span>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            onProposalBuilderUpdated(
                              proposalBuilder.setClient()
                            );
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              ),
            },
            inputLabel: {
              shrink: Boolean(proposalBuilder.currentSpec.client),
            },
          }}
        />
        <NameTextField
          label="Proposal Name"
          required={true}
          disabled={disableEditing}
          value={nameValue}
          error={issues?.entries.some((entry) =>
            entry.field.isEqualTo(ProposalField.Name)
          )}
          helperText={issues?.entries
            .filter((entry) => entry.field.isEqualTo(ProposalField.Name))
            .map((entry) => entry.description)
            .join("\n")}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {renderTextFieldButtons(
                    "name",
                    isEditingName,
                    setIsEditingName,
                    setProposalNameValue
                  )}
                  {renderCommentsButton(ProposalField.Name)}
                </>
              ),
            },
          }}
          onFocus={() => {
            onCommentsClicked(ProposalField.Name, undefined, true);
            if (disableEditing) return;
            setIsEditingName(true);
          }}
          onBlur={(event) => {
            if (disableEditing) return;
            if (event.relatedTarget?.getAttribute("datatype") === "cancel")
              return;
            setProposalNameValue(nameValue);
          }}
          onChange={(event) => {
            if (disableEditing) return;
            setNameValue(event.target.value);
          }}
          onKeyDown={(event) => {
            if (disableEditing || !isEditingName) return;
            if (event.key === "Enter" || event.key === "Tab") {
              setProposalNameValue(nameValue);
            } else if (event.key === "Escape") {
              setNameValue(proposalBuilder.currentSpec.name?.value ?? "");
              setIsEditingName(false);
            }
          }}
        />
        <DescriptionTextField
          label="Description"
          required={true}
          multiline={true}
          rows={3}
          disabled={disableEditing}
          value={descriptionValue}
          error={issues?.entries.some((entry) =>
            entry.field.isEqualTo(ProposalField.Description)
          )}
          helperText={issues?.entries
            .filter((entry) => entry.field.isEqualTo(ProposalField.Description))
            .map((entry) => entry.description)
            .join("\n")}
          slotProps={{
            input: {
              endAdornment: (
                <>
                  {renderTextFieldButtons(
                    "description",
                    isEditingDescription,
                    setIsEditingDescription,
                    setProposalDescriptionValue
                  )}
                  {renderCommentsButton(ProposalField.Description)}
                </>
              ),
            },
          }}
          onFocusCapture={() => {
            onCommentsClicked(ProposalField.Description, undefined, true);
          }}
          onBlur={(event) => {
            if (disableEditing) return;
            if (event.relatedTarget?.getAttribute("datatype") === "cancel")
              return;
            setProposalDescriptionValue(descriptionValue);
          }}
          onFocus={() => setIsEditingDescription(true)}
          onChange={(event) => {
            if (disableEditing) return;
            setDescriptionValue(event.target.value);
          }}
          onKeyDown={(event) => {
            if (disableEditing) return;
            if (event.key === "Tab") {
              setProposalDescriptionValue(descriptionValue);
            } else if (event.key === "Escape") {
              setDescriptionValue(
                proposalBuilder.currentSpec.description?.value ?? ""
              );
              setIsEditingDescription(false);
            } else {
              setIsEditingDescription(true);
            }
          }}
        />
      </GeneralSection>
      {!isRequesting && (
        <DateSection>
          <NegotiableCheckbox>
            <FormControlLabel
              label="Negotiable"
              control={
                <Checkbox
                  color="primary"
                  checked={isNegotiable}
                  disabled={disableEditing}
                  onChange={(
                    _: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    setIsNegotiable(checked);
                    onProposalBuilderUpdated(
                      proposalBuilder.setNegotiable(checked)
                    );
                  }}
                />
              }
            />
          </NegotiableCheckbox>
          <DateContainer>
            <span>
              <DateControlLabel
                slotProps={{
                  typography: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                label={
                  !isUsingResponseDueBy ? (
                    <>
                      <span>Set a response due date?</span>
                      <span>
                        {renderCommentsButton(ProposalField.ResponseDueBy)}
                      </span>
                    </>
                  ) : null
                }
                control={
                  <Checkbox
                    color="primary"
                    required={false}
                    disabled={disableEditing}
                    checked={isUsingResponseDueBy}
                    onClick={() => {
                      onCommentsClicked(
                        ProposalField.ResponseDueBy,
                        undefined,
                        true
                      );
                    }}
                    onChange={(
                      _: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setIsUsingResponseDueBy(checked);
                      const newDate = checked ? new Date(moment()) : undefined;
                      onProposalBuilderUpdated(
                        proposalBuilder.setResponseDueBy(newDate)
                      );
                    }}
                  />
                }
              />
            </span>
            {isUsingResponseDueBy && (
              <DatePicker
                label="Response Due Date"
                onOpen={() => {
                  onCommentsClicked(
                    ProposalField.ResponseDueBy,
                    undefined,
                    true
                  );
                }}
                disablePast={!disableValidate}
                disabled={disableEditing}
                value={proposalBuilder.currentSpec.responseDueBy?.value ?? null}
                minDate={moment()}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalField.ResponseDueBy),
                }}
                slotProps={{
                  textField: {
                    onFocus: () => {
                      onCommentsClicked(
                        ProposalField.ResponseDueBy,
                        undefined,
                        true
                      );
                    },
                    error: issues?.entries.some((entry) =>
                      entry.field.isEqualTo(ProposalField.ResponseDueBy)
                    ),
                    helperText: issues?.entries
                      .filter((entry) =>
                        entry.field.isEqualTo(ProposalField.ResponseDueBy)
                      )
                      .map((entry) => entry.description)
                      .join("\n"),
                  },
                }}
                onChange={(date: Moment | null) => {
                  onProposalBuilderUpdated(
                    proposalBuilder.setResponseDueBy(
                      date ? new Date(date) : undefined
                    )
                  );
                }}
              />
            )}
          </DateContainer>
          <DateContainer>
            <span>
              <DateControlLabel
                slotProps={{
                  typography: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                label={
                  !isUsingStartDate ? (
                    <>
                      <span>Set a start date?</span>
                      <span>
                        {renderCommentsButton(ProposalField.StartDate)}
                      </span>
                    </>
                  ) : null
                }
                control={
                  <Checkbox
                    color="primary"
                    disabled={disableEditing}
                    required={false}
                    checked={isUsingStartDate}
                    onClick={() => {
                      onCommentsClicked(
                        ProposalField.StartDate,
                        undefined,
                        true
                      );
                    }}
                    onChange={(
                      _: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setIsUsingStartDate(checked);
                      const newDate = checked
                        ? new Date(
                            proposalBuilder.currentSpec.responseDueBy?.value ??
                              moment()
                          )
                        : undefined;
                      setStartDate(newDate?.value ?? null);
                      onProposalBuilderUpdated(
                        proposalBuilder.setStartDate(newDate)
                      );
                    }}
                  />
                }
              />
            </span>

            {isUsingStartDate && (
              <DatePicker
                label="Start Date"
                onOpen={() => {
                  onCommentsClicked(ProposalField.StartDate, undefined, true);
                }}
                disablePast={!disableValidate}
                disabled={disableEditing}
                value={startDate}
                minDate={
                  !disableValidate ? moment() : undefined
                }
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalField.StartDate),
                }}
                slotProps={{
                  textField: {
                    error: issues?.entries.some((entry) =>
                      entry.field.isEqualTo(ProposalField.StartDate)
                    ),
                    onFocus: () => {
                      onCommentsClicked(
                        ProposalField.StartDate,
                        undefined,
                        true
                      );
                    },
                    helperText: issues?.entries
                      .filter((entry) =>
                        entry.field.isEqualTo(ProposalField.StartDate)
                      )
                      .map((entry) => entry.description)
                      .join("\n"),
                  },
                }}
                onChange={(date: Moment | null) => {
                  setStartDate(date ?? null);
                  onProposalBuilderUpdated(
                    proposalBuilder.setStartDate(
                      date ? new Date(date) : undefined
                    )
                  );
                }}
              />
            )}
          </DateContainer>
          <DateContainer>
            <span>
              <DateControlLabel
                slotProps={{
                  typography: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
                label={
                  !isUsingEndDate ? (
                    <>
                      <span>Set a end date?</span>
                      <span>{renderCommentsButton(ProposalField.EndDate)}</span>
                    </>
                  ) : null
                }
                control={
                  <Checkbox
                    color="primary"
                    required={false}
                    disabled={disableEditing}
                    checked={isUsingEndDate}
                    onClick={() => {
                      onCommentsClicked(ProposalField.EndDate, undefined, true);
                    }}
                    onChange={(
                      _: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      setIsUsingEndDate(checked);
                      const newDate = checked
                        ? new Date(startDate ?? moment())
                        : undefined;
                      onProposalBuilderUpdated(
                        proposalBuilder.setEndDate(newDate)
                      );
                    }}
                  />
                }
              />
            </span>
            {isUsingEndDate && (
              <DatePicker
                label="End Date"
                onOpen={() => {
                  onCommentsClicked(ProposalField.EndDate, undefined, true);
                }}
                disablePast={!disableValidate}
                disabled={disableEditing}
                value={proposalBuilder.currentSpec.endDate?.value ?? null}
                minDate={!disableValidate ? getMinValidDate(
                  startDate ??
                    proposalBuilder.currentSpec.responseDueBy?.value ??
                    moment()
                ) : undefined}
                slots={{
                  inputAdornment: (params) =>
                    renderDateFieldButtons(params, ProposalField.EndDate),
                }}
                slotProps={{
                  textField: {
                    onFocus: () => {
                      onCommentsClicked(ProposalField.EndDate, undefined, true);
                    },
                    error: issues?.entries.some((entry) =>
                      entry.field.isEqualTo(ProposalField.EndDate)
                    ),
                    helperText: issues?.entries
                      .filter((entry) =>
                        entry.field.isEqualTo(ProposalField.EndDate)
                      )
                      .map((entry) => entry.description)
                      .join("\n"),
                  },
                }}
                onChange={(date: Moment | null) => {
                  onProposalBuilderUpdated(
                    proposalBuilder.setEndDate(
                      date ? new Date(date) : undefined
                    )
                  );
                }}
              />
            )}
          </DateContainer>
        </DateSection>
      )}
      <ClientSelector
        clientUserId={proposalBuilder.currentSpec.client?.userId}
        popoverAnchor={clientSelectorAnchor}
        onPopoverClose={() => {
          setClientSelectorAnchor(undefined);
          setIsSelectingClient(false);
          onSelectingClientChange(false);
        }}
        onClientUpdated={handleClientUpdated}
      />
    </ProposalContainer>
  );
}
