import MarketplaceFeeScheduleCategoryAPIResponse from
    "marketplace/values/fee-schedule-category/api/response-contracts/marketplace-fee-schedule-category-api-response";
import FeeSchedule from "../../fee-schedule";
import Guid from "common/values/guid/guid";
import FeeScheduleTag from "marketplace/values/fee-schedule-tag/fee-schedule-tag";

export default class MarketplaceFeeScheduleAPIResponse {
    id?: string;
    name?: string;
    tags?: string[];
    categories?: MarketplaceFeeScheduleCategoryAPIResponse[];

    constructor(values: Partial<MarketplaceFeeScheduleAPIResponse>) {
        Object.assign(this, values);
    }

    deserialize(): FeeSchedule {
        if (!this.id) throw new Error("Id is required, but was not provided");
        if (!this.name) throw new Error("Name is required, but was not provided");

        let feeSchedule = new FeeSchedule();
        feeSchedule.id = new Guid(this.id);
        feeSchedule.name = this.name;
        feeSchedule.tags = this.tags ? this.tags.map(tag => new FeeScheduleTag(tag)) : [];
        feeSchedule.categories = this.categories ?
            this.categories.map(category => {
                let categoryResponse = new MarketplaceFeeScheduleCategoryAPIResponse(category);
                return categoryResponse.deserialize();
            }) : [];
        return feeSchedule;
    }
}
