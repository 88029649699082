import Guid from "common/values/guid/guid";
import moment from "moment";
import reactElementToJSXString from "react-element-to-jsx-string";
import Session from "users/session/session";
import CommentThread, {
  Audience,
} from "work/entities/comment-thread/comment-thread";
import Comment from "work/entities/comment/comment";
import Proposal, { ProposalField } from "work/entities/proposal/proposal";
import {
  RedlineChange
} from "work/entities/proposal/redlining/redline-change";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";

export default class AutoCommentGenerator {
  private readonly _session: Session;
  private readonly _proposal: Proposal;
  private readonly _builder?: ProposalBuilder;
  private readonly _threads: CommentThread[];

  constructor(
    session: Session,
    proposal: Proposal,
    builder: ProposalBuilder | undefined,
    threads: CommentThread[]
  ) {
    this._session = session;
    this._proposal = proposal;
    this._builder = builder;
    this._threads = [...threads];
  }

  public static generateAutoCommentTextFromRedlineChange(
    redlineChange: RedlineChange
  ): string {
    if (!redlineChange.action) {
      throw new Error(
        "Redline change action is required to generate auto comment text."
      );
    }

    const redlineComment =
      AutoCommentGenerator.renderRedlineChange(redlineChange);

    let commentRedlineString = reactElementToJSXString(redlineComment, {
      tabStop: 0,
    });
    if (redlineChange.field?.isEqualTo(ProposalField.TeamRestriction)) {
      commentRedlineString = "Team Restriction";
    } else if (
      redlineChange.field?.isEqualTo(ProposalField.WaiveConflictsCheck)
    ) {
      commentRedlineString = "Conflicts Check Waiver";
    }
    const cleanedCommentRedlineString = commentRedlineString
      .replaceAll("\n", " ")
      .replaceAll(/{'\s*'}/g, "");

    const autoCommentText = `
      <span className="action ${redlineChange.action?.actionDescription.toLowerCase()}">${
      redlineChange.action?.actionDescription
    }</span>:
      ${cleanedCommentRedlineString}
    `;

    return autoCommentText;
  }

  private static renderRedlineChange(redlineChange: RedlineChange) {
    if (!redlineChange.textChanges || redlineChange.textChanges.length === 0) {
      throw new Error(
        "Redline change text changes are required to render redline change."
      );
    }

    let nodes = [];
    for (
      let changeIndex = 0;
      changeIndex < redlineChange.textChanges.length;
      changeIndex++
    ) {
      if (
        redlineChange.textChanges?.[changeIndex - 1]?.isModified &&
        redlineChange.textChanges[changeIndex].isModified
      ) {
        continue;
      }

      const value = redlineChange.textChanges[changeIndex]?.diff.value;
      const nextValue = redlineChange.textChanges[changeIndex + 1]?.diff.value;

      if (
        redlineChange.textChanges[changeIndex].isModified &&
        redlineChange.textChanges[changeIndex + 1]?.isModified
      ) {
        const node = (
          <span key={Guid.generate().value} className="change-group">
            <span
              className={`removed ${
                redlineChange.isResolved ? "resolved" : ""
              }`}
            >
              {value}
            </span>
            <span
              className={`added ${redlineChange.isResolved ? "resolved" : ""}`}
            >
              {nextValue}
            </span>
          </span>
        );
        nodes.push(node);
        continue;
      }

      let className = "unchanged";
      if (redlineChange.textChanges[changeIndex].isAdded) className = "added";
      if (redlineChange.textChanges[changeIndex].isRemoved)
        className = "removed";
      if (redlineChange.isResolved) className += " resolved";
      const node = (
        <span key={Guid.generate().value} className={className}>
          {value}
        </span>
      );
      nodes.push(node);
    }

    return (
      <span key={Guid.generate().value} className="comment-redline">
        {nodes.map((node) => node)}
      </span>
    );
  }

  public generateAutoCommentsFromRedline(): Comment[] {
    const autoComments: Comment[] = [];

    if (!this._proposal.redline) return autoComments;
    for (let redlineChange of this._proposal.redline.sessionHistory ?? []) {
      const commentText =
        AutoCommentGenerator.generateAutoCommentTextFromRedlineChange(
          redlineChange
        );
      const comment = this.draftFieldComment(
        commentText,
        redlineChange.field?.commentField ?? ProposalField.General
      );
      if (!comment) continue;
      autoComments.push(comment);
    }
    return autoComments;
  }
  public generateAutoCommentsFromBuilder(): Comment[] {
    const autoComments: Comment[] = [];
    if (!this._builder) return autoComments

    for (let specChange of this._builder.sessionHistory) {
      if (!specChange.action) continue;
      if (!specChange.field) continue;
      if (!specChange.value) continue;

      const commentText = `
        <span className="action ${specChange.action?.actionDescription.toLowerCase()}">${
        specChange.action?.actionDescription
      }</span>: ${specChange.value}
      `;

      const comment = this.draftFieldComment(
        commentText,
        specChange.field.commentField,
        specChange.timeStamp
      );
      if (!comment) continue;
      autoComments.push(comment);
    }

    return autoComments;
  }
  private draftFieldComment(
    commentText: string,
    field: ProposalField,
    timeStamp?: moment.Moment
  ): Comment | undefined {
    if (!this._proposal?.id || !this._session.user?.id) return;

    const { audience, subscriberIds } =
      this._proposal.getCommentAudienceAndSubscriberIds(false, this._session);
    const thread =
      this.getCommentThreadFromField(audience, field) ??
      new CommentThread(
        this._proposal.id,
        field,
        subscriberIds,
        false,
        undefined,
        timeStamp
      );
    this._threads.push(thread);

    const newComment = new Comment(
      thread,
      commentText,
      this._session.user.id,
      true,
      Guid.generate(),
      timeStamp
    );
    newComment.markedForCreation = true;

    return newComment;
  }
  private getCommentThreadFromField(
    audience: Audience,
    field: ProposalField
  ): CommentThread | undefined {
    for (const thread of this._threads) {
      if (
        thread.field.isEqualTo(field) &&
        thread.getAudience(this._session.context?.viewingAsVendor ?? false) ===
          audience
      ) {
        return thread;
      }
    }
  }
}
