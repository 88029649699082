import CommentIcon from "@mui/icons-material/Comment";
import { Badge, Divider, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { downloadFile } from "common/helpers/utils";
import AHBoolean from "common/values/boolean/boolean";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentSelector from "documents/view/document-selector";
import DocumentUploadOverlay from "documents/view/document-upload-overlay";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { useSession } from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import { ProposalField } from "work/entities/proposal/proposal";
import FieldRedline, {
  FieldRedlineArray,
} from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import DocumentRedlineSelection from "work/entities/proposal/redlining/view/redline-field-inputs/document-redline-selection";
import SwitchRedline from "work/entities/proposal/redlining/view/redline-field-inputs/switch-redline";
import { ProposalFieldName } from "work/values/constants";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import WorkDocument, {
  WorkDocumentType,
} from "work/values/work-document/work-document";

const ListContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "waived",
})<{ waived: boolean }>(({ theme, waived }) => ({
  flexWrap: "nowrap",
  minHeight: "20rem",
  opacity: waived ? 0.25 : 1,
  pointerEvents: waived ? "none" : "auto",
}));
const DocumentSelectorContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
}));
const SelectedDocsList = styled(DocumentRedlineSelection)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    marginTop: theme.spacing(2),
  },
  maxHeight: "60vh",
  marginLeft: theme.spacing(2),
  overflowY: "auto",
}));
const HeadControls = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
}));
const Uploader = styled(DocumentUploadOverlay)(({ theme }) => ({
  backgroundColor: "rgba(250, 250, 250, 0.5)",
  backdropFilter: "blur(5px) saturate(200%)",
  position: "absolute",
  top: 0,
  left: 0,
  margin: "1rem",
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  zIndex: 9999,
}));
const Change = styled("div")<{ focused: boolean }>(({ theme, focused }) => ({
  border: focused ? "2px solid" : 0,
  borderColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
}));

type ConflictsTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  issues?: ProposalIssues;
  activeReviewField: ProposalField | undefined;
  commentThreads: CommentThread[];
  disableEditing?: boolean;
  onProposalRedlineChange: (
    newRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onTraverseToNewField: (fieldInfo: ProposalField | undefined) => void;
  onCommentsClicked: (field: ProposalField, name?: string, setToOpen?: boolean) => void;
};

const ConflictsTab = (props: Readonly<ConflictsTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    commentThreads,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    onCommentsClicked,
  } = props;

  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(
    null
  );
  const [documentIdToReplace, setDocumentIdToReplace] =
    React.useState<Guid | null>(null);
  const [creatingDocumentFromTemplate, setCreatingDocumentFromTemplate] =
    React.useState<Guid | null>(null);

  const session = useSession();

  async function handleDownloadDocumentById(
    documentId?: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    if (!documentId) return;

    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const conflictsTemplate = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(conflictsTemplate);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download conflicts template. Please try again",
        { variant: "error" }
      );
    } finally {
      setIsDownloadingFile(null);
    }
  }

  function handleDocumentRedlineChanged(
    newDocumentsRedline: FieldRedlineArray<WorkDocument>,
    traversalFieldOverride?: ProposalField
  ) {
    let updatedProposalRedline =
      proposalRedline.updateConflictsDocumentsRedline(newDocumentsRedline);

    if (
      newDocumentsRedline.redlines.some(
        (documentRedline) => documentRedline.currentEntry
      ) &&
      !updatedProposalRedline.conflictsCheckWaived.currentEntry?.isEqualTo(
        new AHBoolean(false)
      )
    ) {
      updatedProposalRedline =
        updatedProposalRedline.updateConflictsCheckWaivedRedline(
          updatedProposalRedline.conflictsCheckWaived.edit(new AHBoolean(false))
        );
    }
    onProposalRedlineChange(updatedProposalRedline, traversalFieldOverride);
    onCommentsClicked(ProposalField.Conflicts, undefined, true);
  }

  function handleDocumentSelectionChanged(
    selectedDocs: Document[],
    deselectedDocs: Document[]
  ) {
    if (!selectedDocs.length && !deselectedDocs.length) return;
    let updatedProposalRedline = proposalRedline.clone();

    if (selectedDocs.length) {
      if (
        !updatedProposalRedline.conflictsCheckWaived.currentEntry?.isEqualTo(
          new AHBoolean(false)
        )
      ) {
        updatedProposalRedline =
          updatedProposalRedline.updateConflictsCheckWaivedRedline(
            updatedProposalRedline.conflictsCheckWaived.edit(
              new AHBoolean(false)
            )
          );
      }
    }

    for (const selectedDoc of selectedDocs) {
      const workDocument = WorkDocument.fromDocument(
        selectedDoc,
        WorkDocumentType.Conflicts
      );
      if (
        !updatedProposalRedline.conflictsDocuments.redlines.some((document) =>
          document.currentEntry?.isEqualTo(workDocument)
        )
      ) {
        updatedProposalRedline =
          updatedProposalRedline.updateConflictsDocumentsRedline(
            updatedProposalRedline.conflictsDocuments.addEntry(workDocument)
          );
      }
    }

    for (const deselectedDoc of deselectedDocs) {
      const redlineId = updatedProposalRedline.conflictsDocuments.redlines.find(
        (documentRedline) =>
          documentRedline.currentEntry?.id?.isEqualTo(deselectedDoc.id) ||
          (!documentRedline.currentEntry &&
            documentRedline.revisedEntry?.id?.isEqualTo(deselectedDoc.id))
      )?.field.id;

      if (!redlineId) {
        continue;
      }

      updatedProposalRedline =
        updatedProposalRedline.updateConflictsDocumentsRedline(
          updatedProposalRedline.conflictsDocuments.removeEntryByFieldId(
            redlineId
          )
        );
    }

    onProposalRedlineChange(updatedProposalRedline, null);
    onCommentsClicked(ProposalField.Conflicts, undefined, true);
  }

  function renderCommentButton() {
    return (
      <Tooltip title="Comments">
        <span>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onCommentsClicked?.(ProposalField.Conflicts);
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              overlap="circular"
              invisible={!commentThreads.some((thread) => thread.field.isEqualTo(ProposalField.Conflicts))}
            >
              <CommentIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function renderDocumentSelector() {
    let selectedTemplateIds: Array<Guid> = [];
    for (const documentRedline of proposalRedline.conflictsDocuments.redlines) {
      if (documentRedline.currentEntry) {
        documentRedline.currentEntry.templateIds.forEach((templateId) => {
          selectedTemplateIds.push(templateId);
        });
      }
    }

    return (
      <DocumentSelector
        documentType="conflicts"
        selectedDocumentIds={proposalRedline.conflictsDocuments.currentIds}
        selectedTemplateIds={selectedTemplateIds}
        onDocumentSelectionChanged={handleDocumentSelectionChanged}
        onCreateDocumentFromTemplate={handleBeginCreateDocumentFromTemplate}
      />
    );
  }

  function handleConflictsCheckWaiverChanged(
    newRedline: FieldRedline<AHBoolean>
  ) {
    try {
      let updatedProposalRedline =
        proposalRedline.updateConflictsCheckWaivedRedline(newRedline);
      let newDocumentsRedline: FieldRedlineArray<WorkDocument> =
        proposalRedline.conflictsDocuments;

      if (newRedline.currentEntry?.isEqualTo(new AHBoolean(true))) {
        for (const documentRedline of updatedProposalRedline.conflictsDocuments
          .redlines) {
          if (!documentRedline.field.id) {
            console.error("Document redline does not have a field id");
            continue;
          }
          newDocumentsRedline =
            updatedProposalRedline.conflictsDocuments.removeEntryByFieldId(
              documentRedline.field.id
            );
        }
      } else {
        newDocumentsRedline = proposalRedline.conflictsDocuments.undoAll();
      }
      updatedProposalRedline =
      updatedProposalRedline.updateConflictsDocumentsRedline(
        newDocumentsRedline
      );
      onProposalRedlineChange(
        updatedProposalRedline,
        ProposalField.WaiveConflictsCheck
      );
      onCommentsClicked(ProposalField.Conflicts, undefined, true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to update conflicts check waiver.", {
        variant: "error",
      });
    }
  }

  async function handleBeginCreateDocumentFromTemplate(
    templateId: Guid | undefined | null
  ) {
    if (!templateId) return;
    setCreatingDocumentFromTemplate(templateId);
    handleDownloadDocumentById(templateId);
  }

  async function handleBeginReplaceDocument(
    documentId: Guid | undefined | null
  ) {
    if (!documentId) throw new Error("Document ID must be present to replace document");
    setDocumentIdToReplace(documentId);
    handleDownloadDocumentById(documentId);
  }

  function handleConflictsDocumentAdded(newDocument: Document) {
    if (!(newDocument instanceof Document))
      throw new Error("Document must be available");
    const newWorkDocument = WorkDocument.fromDocument(
      newDocument,
      WorkDocumentType.Conflicts,
      documentIdToReplace ?? undefined
    );
    try {
      let updatedProposalRedline = proposalRedline.clone();
      if (documentIdToReplace) {
        updatedProposalRedline =
          proposalRedline.updateConflictsDocumentsRedline(
            proposalRedline.conflictsDocuments.replaceEntryById(
              documentIdToReplace,
              newWorkDocument
            )
          );
      } else if (
        !proposalRedline.conflictsDocuments.redlines.some((document) =>
          document.currentEntry?.isEqualTo(newWorkDocument)
        )
      ) {
        updatedProposalRedline =
          proposalRedline.updateConflictsDocumentsRedline(
            proposalRedline.conflictsDocuments.addEntry(newWorkDocument)
          );
      }
      if (
        !updatedProposalRedline.conflictsCheckWaived.currentEntry?.isEqualTo(
          new AHBoolean(false)
        )
      ) {
        updatedProposalRedline =
          updatedProposalRedline.updateConflictsCheckWaivedRedline(
            updatedProposalRedline.conflictsCheckWaived.edit(
              new AHBoolean(false)
            )
          );
      }

      onProposalRedlineChange(updatedProposalRedline, null);
      onCommentsClicked(ProposalField.Conflicts, undefined, true)      
      setDocumentIdToReplace(null);
      setCreatingDocumentFromTemplate(null);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to add conflicts document.", {
        variant: "error",
      });
    }
  }

  if (!isOpen) return null;

  return (
    <>
      <HeadControls>
        <Change
          focused={
            activeReviewField?.name === ProposalFieldName.WaiveConflictsCheck
          }
        >
          <SwitchRedline
            boolRedline={proposalRedline.conflictsCheckWaived}
            titleText="Remove Any Newly-Added Documents?"
            messageText="Waiving conflicts will remove any newly-added documents. Are you sure you want to proceed?"
            okButtonText="Remove"
            unsetLabel="Not Waiving Conflicts Check"
            setLabel="Waiving Conflicts Check"
            onBoolRedlineChange={(newRedline) => {
              try {
                handleConflictsCheckWaiverChanged(newRedline);
              } catch (error) {
                console.error(error);
                enqueueSnackbar("Failed to update conflicts check waiver.", {
                  variant: "error",
                });
              }
            }}
          />
        </Change>
        {renderCommentButton()}
      </HeadControls>
      <ListContainer
        waived={
          proposalRedline.conflictsCheckWaived.currentEntry?.value ??
          proposalRedline.conflictsCheckWaived.revisedEntry?.value ??
          false
        }
        container
        direction="row"
      >
        <DocumentSelectorContainer size="grow">
          {!disableEditing && renderDocumentSelector()}
        </DocumentSelectorContainer>
        <Grid>
          <Divider orientation="vertical" />
        </Grid>
        <Grid>
          <SelectedDocsList
            workDocumentType={WorkDocumentType.Conflicts}
            documentsRedline={proposalRedline.conflictsDocuments}
            downloadingFileId={isDownloadingFile}
            commentThreads={commentThreads}
            disableEditing={disableEditing}
            activeReviewField={activeReviewField}
            onCommentsClicked={onCommentsClicked}
            onDownload={handleDownloadDocumentById}
            onDocumentRedlineChange={(newRedline) => {
              try {
                handleDocumentRedlineChanged(newRedline);
              } catch (error) {
                console.error(error);
                enqueueSnackbar("Failed to update conflicts document.", {
                  variant: "error",
                });
              }
            }}
            onBeginReplaceDocument={handleBeginReplaceDocument}
            onDocumentClicked={(documentId: Guid) => {
              onTraverseToNewField?.(
                ProposalField.ConflictsDocument(documentId)
              );
            }}
            onTraverseToNewField={onTraverseToNewField}
          />
        </Grid>
      </ListContainer>
      {(creatingDocumentFromTemplate || documentIdToReplace) && (
        <Uploader
          session={session}
          documentType={WorkDocumentType.Conflicts}
          templateId={creatingDocumentFromTemplate ?? undefined}
          onDocumentUploaded={handleConflictsDocumentAdded}
          onClose={() => {
            setCreatingDocumentFromTemplate(null);
            setDocumentIdToReplace(null);
          }}
        />
      )}
    </>
  );
};

export default ConflictsTab;
