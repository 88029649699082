import { HubConnection } from '@microsoft/signalr';
import { Action, Dispatch, Middleware, UnknownAction } from 'redux';
import Session from 'users/session/session';

export interface SignalDispatch<S, A extends Action> {
  <T extends A>(action: T): T;
  <R>(asyncAction: SignalAction<R, S, A>): R;
}

export type SignalAction<R, S, A extends Action> = (
  dispatch: SignalDispatch<S, A>,
  getState: () => S,
  invoke: HubConnection['invoke']
) => R;

export type SignalMiddleware<S = {}, A extends Action = UnknownAction> = Middleware<
  SignalDispatch<S, A>,
  S,
  SignalDispatch<S, A>
>;

export type Callback<S = any, D extends Dispatch = Dispatch> = (
  ...args: any[]
) => (dispatch: D, getState: () => S, invoke: HubConnection['invoke']) => void;
export interface WithCallbacks<S = any, D extends Dispatch = Dispatch> {
  (): void;
  add: (name: string, callback: Callback<S, D>) => WithCallbacks<S, D>;
  callbackMap: Map<string, Callback<S, D>>;
}
export type WithCallbacksCreator = <
  S = any,
  D extends Dispatch = Dispatch
>() => WithCallbacks<S, D>;

export interface MiddlewareConfig<S = any, D extends Dispatch = Dispatch> {
  callbacks: WithCallbacks<S, D>;
  connection: HubConnection;
  onStart?(): void;
  shouldConnectionStartImmediately?: boolean;
}

export const withCallbacks: WithCallbacksCreator = <
  S = any,
  D extends Dispatch = Dispatch
>() => {
  const callbackMap = new Map<string, Callback<S, D>>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const moduleReducer = () => {};

  const add = (name: string, callback: Callback<S, D>) => {
    callbackMap.set(name, callback);
    return moduleReducer;
  };

  moduleReducer.add = add;
  moduleReducer.callbackMap = callbackMap;

  return moduleReducer;
};


const reduxSignalRMiddleware = ({
  callbacks,
  onStart = () => {},
  connection,
  shouldConnectionStartImmediately = true,
}: MiddlewareConfig): SignalMiddleware => (store) => {
  const { callbackMap } = callbacks;
  for (const [name, callback] of callbackMap) {
    connection.on(name, (...args) => {
      callback
        .call(null, ...args)
        .call(
          store,
          store.dispatch.bind(store),
          store.getState.bind(store),
          connection.invoke.bind(connection)
        );
    });
  }

  if (shouldConnectionStartImmediately) {
    connect(connection);
  }
  document.addEventListener('loggedIn', () => {
    connect(connection);
  });

  return (next) => (action) =>
    typeof action === 'function'
      ? action(
          store.dispatch.bind(store),
          store.getState.bind(store),
          connection.invoke.bind(connection)
        )
      : next(action);
};

const connect = (connection: HubConnection) => {
  const token = Session.loadFromStorage(() => {}).authToken?.value;
  if(!token || connection.state !== 'Disconnected') {
    return;
  }
  connection
    .start()
    .then(function () {
      console.log('SignalR Connected');
    })
    .catch(function (err) {
      return console.error(err.toString());
    });
}

export default reduxSignalRMiddleware;