import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import Individual from "marketplace/entities/individual/individual";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import { Moment } from "moment";

export interface INetworkedUserInfoData {
    connectionId: Guid;
    createdDate?: Moment;
    userId: Guid;
    name: Name;
    email?: EmailAddress;
    individualId?: Guid;
    companyId?: Guid;
    companyEntityId?: Guid;
    companyName?: string;
    companyProfileVisible?: boolean;
    avatarId?: Guid;
    teamsOn?: number;
    teamsLeading?: number;
    myComments?: string;
    isClientRep: boolean;
    isVendorRep: boolean;
}

export default class NetworkedUserInfo {
    public connectionId: Guid;
    public createdDate?: Moment;
    public userId: Guid;
    public name: Name;
    public email?: EmailAddress;
    public individualId?: Guid;
    public companyId?: Guid;
    public companyEntityId?: Guid;
    public companyName?: string;
    public companyProfileVisible?: boolean;
    public avatarId?: Guid;
    public teamsOn?: number;
    public teamsLeading?: number;
    public myComments?: string;
    public isClientRep: boolean = false;
    public isVendorRep: boolean = false;

    constructor(networkedUserInfo: INetworkedUserInfoData) {
        if(!networkedUserInfo.connectionId) 
            throw new InvalidNetworkedUserInfoError(`connectionId is required.`);
        if(!networkedUserInfo.userId) 
            throw new InvalidNetworkedUserInfoError(`userId is required.`);
        if(!networkedUserInfo.individualId) 
            throw new InvalidNetworkedUserInfoError(`individualId is required.`);
        if(!networkedUserInfo.name)
            throw new InvalidNetworkedUserInfoError(`name is required.`);

        this.connectionId = networkedUserInfo.connectionId;
        this.createdDate = networkedUserInfo.createdDate;
        this.userId = networkedUserInfo.userId;
        this.name = networkedUserInfo.name;
        this.email = networkedUserInfo.email;
        this.individualId = networkedUserInfo.individualId ? networkedUserInfo.individualId : undefined;
        this.companyId = networkedUserInfo.companyId ? networkedUserInfo.companyId : undefined;
        this.companyEntityId = networkedUserInfo.companyEntityId ? networkedUserInfo.companyEntityId : undefined;
        this.companyName = networkedUserInfo.companyName;
        this.companyProfileVisible = networkedUserInfo.companyProfileVisible;
        this.avatarId = Boolean(networkedUserInfo.avatarId) !== false ? networkedUserInfo.avatarId : undefined;
        this.teamsOn = networkedUserInfo.teamsOn;
        this.teamsLeading = networkedUserInfo.teamsLeading;
        this.myComments = networkedUserInfo.myComments;
        this.isClientRep = networkedUserInfo.isClientRep;
        this.isVendorRep = networkedUserInfo.isVendorRep;
    }

    public asIndividual(): Individual {
        if(!this.individualId)
            throw new InvalidNetworkedUserInfoError(`individualId is required.`);
        if(!this.companyEntityId)
            throw new InvalidNetworkedUserInfoError(`companyEntityId is required`);
        const profile = new IndividualProfile(this.userId, this.name.firstName ?? "", this.name.lastName ?? "", true);
        profile.entityId = this.companyEntityId;
        profile.email = this.email;
        profile.avatarId = this.avatarId;
        return new Individual(this.individualId, this.userId, profile);
    }
}

export class InvalidNetworkedUserInfoError extends Error {}
