import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { RootState } from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import bookmarksSlice from "marketplace/entities/bookmark/store/bookmarks-redux-store";
import MarketplaceCompanySearch from "marketplace/entities/company/api/request-contracts/marketplace-company-search";
import MarketplaceIndividualPendingInvitation from "marketplace/entities/marketplace-individual-pending-invitation/marketplace-individual-pending-invitation";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import MarketplaceTeamSearch from "marketplace/entities/marketplace-team/api/request-contracts/marketplace-team-search";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import TeamInvitation from "marketplace/entities/team-invitation/team-invitation";
import { useSelector } from "react-redux";

import Session from "users/session/session";

type MarketplaceTeamStoreState = {
  vendors: {
    entries: MarketplaceTeam[];
    loading: boolean;
    error: SerializedError | null;
  };
  byId: {
    entries: Record<string, MarketplaceTeam>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  byQuery: {
    entries: Record<string, MarketplaceTeam[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  avatars: {
    entries: Record<string, string>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };

  invitations: {
    byTeamId: {
      entries: Record<string, MarketplaceIndividualPendingInvitation[]>;
      loading: Record<string, boolean>;
      error: Record<string, SerializedError | null>;
    };
    byId: {
      entries: Record<string, TeamInvitation>;
      loading: Record<string, boolean>;
      error: Record<string, SerializedError | null>;
    };
  };
};

const initialState: MarketplaceTeamStoreState = {
  vendors: {
    entries: [],
    loading: false,
    error: null,
  },
  byId: {
    entries: {},
    loading: {},
    error: {},
  },
  byQuery: {
    entries: {},
    loading: {},
    error: {},
  },
  avatars: {
    entries: {},
    loading: {},
    error: {},
  },

  invitations: {
    byTeamId: {
      entries: {},
      loading: {},
      error: {},
    },
    byId: {
      entries: {},
      loading: {},
      error: {},
    },
  },
};

export const populateMarketplaceTeamVendors = createAsyncThunk<
  MarketplaceTeam[],
  { session: Session; userIds: Guid[] }
>(
  "marketplaceTeams/getTeamVendors",
  async ({ session }: { session: Session }, thunkAPI) => {
    try {
      const apiService = new MarketplaceTeamAPIService(session);
      const marketplaceTeams = await apiService.getTeamVendors();
      return marketplaceTeams;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const populateMarketplaceTeamById = createAsyncThunk(
  "marketplaceTeams/getMarketplaceTeamById",
  async ({ session, teamId }: { session: Session; teamId: Guid }, thunkAPI) => {
    try {
      const apiService = new MarketplaceTeamAPIService(session);
      const team = await apiService.getTeamById(teamId);
      return team;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateMarketplaceTeamsByQuery = createAsyncThunk(
  "marketplaceTeams/getMarketplaceTeamsByQuery",
  async (
    {
      session,
      query,
    }: {
      session: Session;
      query: MarketplaceTeamSearch;
    },
    thunkAPI
  ) => {
    try {
      const apiService = new MarketplaceTeamAPIService(session);
      const marketplaceTeams = await apiService.searchTeams(query);
      return marketplaceTeams;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateMarketplaceTeamAvatar = createAsyncThunk(
  "marketplaceTeams/getMarketplaceTeamAvatar",
  async ({ session, teamId }: { session: Session; teamId: Guid }, thunkAPI) => {
    try {
      const apiService = new MarketplaceTeamAPIService(session);
      const avatars = await apiService.getTeamAvatar(teamId);
      return avatars;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateMarketplaceTeamInvitations = createAsyncThunk(
  "marketplaceTeams/getMarketplaceTeamInvitations",
  async ({ session, teamId }: { session: Session; teamId: Guid }, thunkAPI) => {
    try {
      const apiService = new MarketplaceTeamAPIService(session);
      const invitations = await apiService.getTeamInvitationsByTeamId(teamId);
      return invitations;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateMarketplaceTeamInvitationById = createAsyncThunk(
  "marketplaceTeams/getMarketplaceTeamInvitationById",
  async (
    { session, invitationId }: { session: Session; invitationId: Guid },
    thunkAPI
  ) => {
    try {
      const apiService = new MarketplaceTeamAPIService(session);
      const forum = await apiService.getTeamInvitationByInvitationId(
        invitationId
      );
      return forum;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const marketplaceTeamsSlice = createSlice({
  name: "marketplaceTeams",
  initialState,
  reducers: {
    addMarketplaceTeam: (state, action: PayloadAction<MarketplaceTeam>) => {
      const teamId = action.payload.id?.value;
      if (!teamId) {
        return;
      }

      state.byId.entries[teamId] = action.payload as any;
      state.vendors.entries = state.vendors.entries.filter(
        (team) => team.id?.value !== teamId
      );
      state.vendors.entries.push(action.payload);
    },
    removeMarketplaceTeam: (state, action: PayloadAction<Guid>) => {
      delete state.byId.entries[action.payload.value];
    },
    addAvatar: (
      state,
      action: PayloadAction<{ teamId: Guid; avatar: string }>
    ) => {
      state.avatars.entries[action.payload.teamId.value] =
        action.payload.avatar;
    },
    removeAvatar: (state, action: PayloadAction<Guid>) => {
      delete state.avatars.entries[action.payload.value];
    },
    addMarketplaceTeamInvitation: (
      state,
      action: PayloadAction<TeamInvitation>
    ) => {
      const invitationId = action.payload.id?.value;
      if (!invitationId) {
        return;
      }

      state.invitations.byId.entries[invitationId] = action.payload as any;
    },
    removeMarketplaceTeamInvitation: (state, action: PayloadAction<Guid>) => {
      const invitationId = action.payload.value;
      if (!invitationId) {
        return;
      }

      delete state.invitations.byId.entries[invitationId];
      for (const teamId in state.invitations.byTeamId.entries) {
        state.invitations.byTeamId.entries[teamId] =
          state.invitations.byTeamId.entries[teamId].filter(
            (invitation) => invitation.id.value !== invitationId
          );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateMarketplaceTeamVendors.pending, (state, action) => {
      state.vendors.loading = true;
      state.vendors.error = null;
    });
    builder.addCase(
      populateMarketplaceTeamVendors.fulfilled,
      (state, action) => {
        state.vendors.entries = action.payload;
        state.vendors.loading = false;
      }
    );
    builder.addCase(
      populateMarketplaceTeamVendors.rejected,
      (state, action) => {
        state.vendors.loading = false;
        state.vendors.error = action.error;
      }
    );

    builder.addCase(populateMarketplaceTeamById.pending, (state, action) => {
      const teamId = action.meta.arg.teamId.value;
      state.byId.loading[teamId] = true;
      state.byId.error[teamId] = null;
    });
    builder.addCase(populateMarketplaceTeamById.fulfilled, (state, action) => {
      const teamId = action.meta.arg.teamId.value;
      state.byId.loading[teamId] = false;
      if (!action.payload) {
        console.error("MarketplaceTeam not received by store.");
        return;
      }
      state.byId.entries[teamId] = action.payload;
    });
    builder.addCase(populateMarketplaceTeamById.rejected, (state, action) => {
      const teamId = action.meta.arg.teamId.value;
      state.byId.loading[teamId] = false;
      state.byId.error[teamId] = action.error;
    });

    builder.addCase(
      populateMarketplaceTeamsByQuery.pending,
      (state, action) => {
        const query = action.meta.arg.query.asSearchParams().toString();
        state.byQuery.loading[query] = true;
        state.byQuery.error[query] = null;
      }
    );
    builder.addCase(
      populateMarketplaceTeamsByQuery.fulfilled,
      (state, action) => {
        const query = action.meta.arg.query.asSearchParams().toString();
        state.byQuery.entries[query] =
          action.payload.hits?.map((hit) => hit.data.deserializeToTeam()) || [];
        state.byQuery.loading[query] = false;
      }
    );
    builder.addCase(
      populateMarketplaceTeamsByQuery.rejected,
      (state, action) => {
        const query = action.meta.arg.query.asSearchParams().toString();
        state.byQuery.loading[query] = false;
        state.byQuery.error[query] = action.error;
      }
    );

    builder.addCase(populateMarketplaceTeamAvatar.pending, (state, action) => {
      const teamId = action.meta.arg.teamId.value;
      state.avatars.loading[teamId] = true;
      state.avatars.error[teamId] = null;
    });
    builder.addCase(
      populateMarketplaceTeamAvatar.fulfilled,
      (state, action) => {
        const teamId = action.meta.arg.teamId.value;
        state.avatars.entries[teamId] = action.payload;
        state.avatars.loading[teamId] = false;
      }
    );
    builder.addCase(populateMarketplaceTeamAvatar.rejected, (state, action) => {
      const teamId = action.meta.arg.teamId.value;
      state.avatars.loading[teamId] = false;
      state.avatars.error[teamId] = action.error;
    });

    builder.addCase(
      populateMarketplaceTeamInvitationById.pending,
      (state, action) => {
        const invitationId = action.meta.arg.invitationId.value;
        state.invitations.byId.loading[invitationId] = true;
        state.invitations.byId.error[invitationId] = null;
      }
    );

    builder.addCase(
      populateMarketplaceTeamInvitationById.fulfilled,
      (state, action) => {
        const invitationId = action.meta.arg.invitationId.value;
        state.invitations.byId.entries[invitationId] = action.payload;
        state.invitations.byId.loading[invitationId] = false;
      }
    );
    builder.addCase(
      populateMarketplaceTeamInvitationById.rejected,
      (state, action) => {
        const invitationId = action.meta.arg.invitationId.value;
        state.invitations.byId.loading[invitationId] = false;
        state.invitations.byId.error[invitationId] = action.error;
      }
    );
    builder.addCase(
      populateMarketplaceTeamInvitations.pending,
      (state, action) => {
        const teamId = action.meta.arg.teamId.value;
        state.invitations.byTeamId.loading[teamId] = true;
        state.invitations.byTeamId.error[teamId] = null;
      }
    );

    builder.addCase(
      populateMarketplaceTeamInvitations.fulfilled,
      (state, action) => {
        const teamId = action.meta.arg.teamId.value;
        state.invitations.byTeamId.entries[teamId] = action.payload;
        state.invitations.byTeamId.loading[teamId] = false;
      }
    );
    builder.addCase(
      populateMarketplaceTeamInvitations.rejected,
      (state, action) => {
        const teamId = action.meta.arg.teamId.value;
        state.invitations.byTeamId.loading[teamId] = false;
        state.invitations.byTeamId.error[teamId] = action.error;
      }
    );
  },
});

export const {
  addCompanyBookmark,
  removeCompanyBookmark,
  addIndividualBookmark,
  removeIndividualBookmark,
  addTeamBookmark,
  removeTeamBookmark,
} = bookmarksSlice.actions;
export const getCompanyBookmarks = () =>
  useSelector((state: RootState) => state.bookmarks.companyBookmarks.entries);
export const getIsLoadingCompanyBookmarks = () =>
  useSelector((state: RootState) => state.bookmarks.companyBookmarks.loading);
export const getErrorLoadingCompanyBookmarks = () =>
  useSelector((state: RootState) => state.bookmarks.companyBookmarks.error);

export const getIndividualBookmarks = () =>
  useSelector(
    (state: RootState) => state.bookmarks.individualBookmarks.entries
  );
export const getIsLoadingIndividualBookmarks = () =>
  useSelector(
    (state: RootState) => state.bookmarks.individualBookmarks.loading
  );
export const getErrorLoadingIndividualBookmarks = () =>
  useSelector((state: RootState) => state.bookmarks.individualBookmarks.error);

export const getTeamBookmarks = () =>
  useSelector((state: RootState) => state.bookmarks.teamBookmarks.entries);
export const getIsLoadingTeamBookmarks = () =>
  useSelector((state: RootState) => state.bookmarks.teamBookmarks.loading);
export const getErrorLoadingTeamBookmarks = () =>
  useSelector((state: RootState) => state.bookmarks.teamBookmarks.error);

export const getCompanyVendors = () =>
  useSelector((state: RootState) => state.companies.vendors.entries);
export const getCompanyVendorsIsLoading = () =>
  useSelector((state: RootState) => state.companies.vendors.loading);
export const getCompanyVendorsError = () =>
  useSelector((state: RootState) => state.companies.vendors.error);

export const getCompanyById = (id: Guid) =>
  useSelector((state: RootState) => state.companies.byId.entries[id.value]);
export const getIsLoadingCompanyById = (id: Guid) =>
  useSelector((state: RootState) => state.companies.byId.loading[id.value]);
export const getErrorLoadingCompanyById = (id: Guid) =>
  useSelector((state: RootState) => state.companies.byId.error[id.value]);

export const getCompaniesByQuery = (query: MarketplaceCompanySearch) =>
  useSelector(
    (state: RootState) =>
      state.companies.byQuery.entries[query.asSearchParams().toString()]
  );
export const getIsLoadingCompaniesByQuery = (query: MarketplaceCompanySearch) =>
  useSelector(
    (state: RootState) =>
      state.companies.byQuery.loading[query.asSearchParams().toString()]
  );
export const getErrorLoadingCompaniesByQuery = (
  query: MarketplaceCompanySearch
) =>
  useSelector(
    (state: RootState) =>
      state.companies.byQuery.error[query.asSearchParams().toString()]
  );

export const getCompanyAvatar = (companyId: Guid) =>
  useSelector(
    (state: RootState) => state.companies.avatars.entries[companyId.value]
  );
export const getCompanyAvatarIsLoading = (companyId: Guid) =>
  useSelector(
    (state: RootState) => state.companies.avatars.loading[companyId.value]
  );
export const getCompanyAvatarError = (companyId: Guid) =>
  useSelector(
    (state: RootState) => state.companies.avatars.error[companyId.value]
  );
