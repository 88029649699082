export default class DocumentFilterParameters {
  name?: string;
  minCreatedDate?: string;
  maxCreatedDate?: string;

  constructor(
    name?: string,
    minCreatedDate?: string,
    maxCreatedDate?: string
  ) {
    this.name = name;
    this.minCreatedDate = minCreatedDate;
    this.maxCreatedDate = maxCreatedDate;
  }

  public asSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    if (this.name) searchParams.append("name", this.name);
    if (this.minCreatedDate) searchParams.append("minCreatedDate", this.minCreatedDate);
    if (this.maxCreatedDate) searchParams.append("maxCreatedDate", this.maxCreatedDate);
    return searchParams;
  }
}
