import { useDialog } from 'app/providers/dialog';
import { Hit } from 'common/contracts/search-results';
import * as Constants from 'common/helpers/constants';
import Guid from 'common/values/guid/guid';
import MarketplaceSearchInfoAPIResponse from 'marketplace/api/response-contracts/marketplace-search-info-api-response';
import CompanyResult from 'marketplace/entities/company/view/company-result';
import Individual from 'marketplace/entities/individual/individual';
import MarketplaceTeam from 'marketplace/entities/marketplace-team/marketplace-team';
import MarketplaceCompanyInfoAPIResponse from 'marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response';
import MarketplaceIndividualInfoAPIResponse from 'marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response';
import MarketplaceTeamInfoAPIResponse from 'marketplace/values/team-profile/api/response-contracts/marketplace-team-info-api-response';

import IndividualResult from 'marketplace/view/individual-result';
import TeamResult from 'marketplace/view/team-result';
import React from 'react';
import { NavigateFunction } from 'react-router';
import { useSession } from 'users/session/session-context';

type ResultsListProps = {
  results: Hit<MarketplaceSearchInfoAPIResponse>[];
  embedded?: boolean;
  selectingTeamLeader?: boolean;
  selectingTeamMember?: boolean;
  selectingTeam?: boolean;
  selectedTeamMemberUserIds?: Guid[];
  navigate?: NavigateFunction;
  onTeamMemberSelected?: (teamMember: Individual) => void;
  onTeamSelected?: (team: MarketplaceTeam) => void;
}

export default function ResultsList(props: Readonly<ResultsListProps>) {
  const {
    results,
    embedded,
    selectingTeamLeader,
    selectingTeamMember,
    selectingTeam,
    selectedTeamMemberUserIds,
    navigate,
    onTeamMemberSelected,
    onTeamSelected
  } = props;

  const { openDialog } = useDialog();
  const session = useSession();

  function handleResultClicked(
    event: React.MouseEvent, 
    profileId: Guid,
    profileType: Constants.MarketplaceResultType,
    component: React.JSX.Element, 
    fullWidth = false
  ) {
    if (!embedded) {
      navigate?.(`/marketplace/${profileType.toLowerCase()}/${profileId.value}`);
      return;
    }
    openDialog({
      component: component,
      titleStyle: {
        position: 'absolute',
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: 'flex',
        overflowX: 'hidden'
      },
      MuiProps: {
        maxWidth: 'lg',
        fullWidth: fullWidth
      }
    });
  }

  function getText(
    result: Hit<MarketplaceSearchInfoAPIResponse>,
    fieldNames: string[],
    base: unknown = result?.data,
    noCommas: boolean = false
  ) {
    let html: string = '';

    fieldNames.forEach((value: string, index: number, array: string[]) => {
      const isLastLoop: boolean = index === array.length - 1;
      let comma: string = noCommas || isLastLoop ? '' : ',';

      // If value is invalid and we're on the last iteration then remove the last comma
      if (!base?.[value as keyof unknown] && isLastLoop)
        html = html.slice(0, -1);

      // Value is invalid, skip
      if (!base?.[value as keyof unknown]) return;

      if (result?.highlight?.[value])
        html += ` ${result?.highlight?.[value][0]}${comma}`;
      else
        html += ` ${base?.[value as keyof unknown]}${comma}`;
    });

    return { __html: html };
  }

  return (
    <React.Fragment>
      {results?.map((hit: Hit<MarketplaceSearchInfoAPIResponse>) => {
        const result = Object.assign(new MarketplaceSearchInfoAPIResponse(), hit.data);

        if (result?.type === Constants.MarketplaceResultType.Company && !selectingTeam && !selectingTeamLeader && !selectingTeamMember) {
          const companyResult = Object.assign(new MarketplaceCompanyInfoAPIResponse(), result);
          const companyProfile = companyResult.deserializeToProfile();
          return (
            <CompanyResult
              key={companyResult.id}
              hit={hit}
              result={companyResult}
              isNetworked={companyProfile.contactUserId ? session.isNetworkedWith(companyProfile.contactUserId) : false}
              handleResultClicked={handleResultClicked}
              getText={getText}
            />
          );
        }

        if (result?.type === Constants.MarketplaceResultType.Team && !selectingTeamLeader && !selectingTeamMember) {
          const teamResult = Object.assign(new MarketplaceTeamInfoAPIResponse(), result);
          const team = teamResult.deserializeToTeam();
          return (
            <TeamResult
              key={teamResult.id}
              embedded={embedded ?? false}
              hit={hit}
              result={teamResult}
              isNetworked={session.isNetworkedWith(team.leader.userId)}
              handleResultClicked={handleResultClicked}
              getText={getText}
              session={session}
              navigate={navigate}
              onTeamSelected={onTeamSelected}
            />
          );
        }

        if (result?.type === Constants.MarketplaceResultType.Individual && !selectingTeam) {
          const individualResult = Object.assign(new MarketplaceIndividualInfoAPIResponse(), result);
          return (
            <IndividualResult
              selectingAsLeader={selectingTeamLeader}
              selectedTeamMemberUserIds={selectedTeamMemberUserIds}
              key={individualResult.id}
              embedded={embedded ?? false}
              hit={hit}
              result={individualResult}
              session={session}
              navigate={navigate}
              handleResultClicked={handleResultClicked}
              getText={getText}
              onTeamMemberSelected={onTeamMemberSelected}
            />
          );
        }

        return null;
      })}
    </React.Fragment>
  );
}
