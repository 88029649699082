import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import PaginatedResponse from "common/contracts/paginated-response";
import PaginationParameters from "common/contracts/pagination-parameters";
import Document from "documents/entities/document/document";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import DocumentFilterParameters from "documents/entities/document/api/request-contracts/document-filter-parameters";
import DocumentOrderParameters from "documents/entities/document/api/request-contracts/document-order-parameters";
import DocumentTopicParameters from "documents/entities/document/api/request-contracts/document-topic-parameters";
import Session from "users/session/session";
import Guid from "common/values/guid/guid";
import UnavailableDocument from "documents/values/unavailable-document";
import { RootState } from "app/realtime-store/redux-store";

type DocumentStoreState = {
  byQuery: {
    entries: Record<string, PaginatedResponse<Document | UnavailableDocument>>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  byId: {
    entries: Record<string, Document | UnavailableDocument>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
};

const initialState: DocumentStoreState = {
  byQuery: {
    entries: {},
    loading: {},
    error: {},
  },
  byId: {
    entries: {},
    loading: {},
    error: {},
  },
};

export class DocumentsQueryParameters {
  paginationParameters: PaginationParameters;
  topicParameters: DocumentTopicParameters;
  orderParameters: DocumentOrderParameters;
  filterParameters: DocumentFilterParameters;

  constructor(
    paginationParameters: PaginationParameters,
    topicParameters: DocumentTopicParameters,
    orderParameters: DocumentOrderParameters,
    filterParameters: DocumentFilterParameters
  ) {
    this.paginationParameters = paginationParameters;
    this.topicParameters = topicParameters;
    this.orderParameters = orderParameters;
    this.filterParameters = filterParameters;
  }

  public asSearchParams(): URLSearchParams {
    const paginationParameters = this.paginationParameters.asSearchParams();
    const topicParameters = this.topicParameters.asSearchParams();
    const orderParameters = this.orderParameters.asSearchParams();
    const filterParameters = this.filterParameters.asSearchParams();

    const searchParams = new URLSearchParams([...paginationParameters, ...topicParameters, ...orderParameters, ...filterParameters]);
    return searchParams;
  }
};
export const populateDocumentsQuery = createAsyncThunk<
  PaginatedResponse<Document>,
  { session: Session; query: DocumentsQueryParameters }
>(
  "documents/getDocumentsByQuery",
  async (
    { session, query }: { session: Session; query: DocumentsQueryParameters },
    thunkAPI
  ) => {
    try {
      const apiService = new DocumentAPIService(session);
      const documents = await apiService.getDocumentsInfo(
        undefined,
        query.paginationParameters,
        query.topicParameters,
        query.orderParameters,
        query.filterParameters
      );
      return documents;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const populateDocumentsByIds = createAsyncThunk<
  (Document | UnavailableDocument | undefined)[],
  { session: Session; ids: Guid[] }
>(
  "documents/getDocumentsByIds",
  async ({ session, ids }: { session: Session; ids: Guid[] }, thunkAPI) => {
    try {
      const apiService = new DocumentAPIService(session);
      const documents = await apiService.getDocumentsInfoByIds(ids, undefined);
      return documents;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    addDocument: (state, action: PayloadAction<Document | UnavailableDocument>) => {
      state.byId.entries[action.payload.id.value] = action.payload;
      for (const query in state.byQuery.entries) {
        state.byQuery.entries[query].data = state.byQuery.entries[
          query
        ].data.filter((doc) => doc.id.value !== action.payload.id.value);
        state.byQuery.entries[query].data.push(action.payload);
      }
    },
    removeDocument: (state, action: PayloadAction<Document | UnavailableDocument>) => {
      delete state.byId.entries[action.payload.id.value];
      for (const query in state.byQuery.entries) {
        state.byQuery.entries[query].data = state.byQuery.entries[
          query
        ].data.filter((doc) => doc.id.value !== action.payload.id.value);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(populateDocumentsByIds.pending, (state, action) => {
      const documentIds = action.meta.arg.ids;
      if (!documentIds.length) {
        return;
      }
      documentIds.forEach((id) => {
        state.byId.loading[id.value] = true;
        state.byId.error[id.value] = null;
      });
    });
    builder.addCase(
      populateDocumentsByIds.fulfilled,
      (state, action) => {
        const documentIds = action.meta.arg.ids;
        if (!documentIds.length) {
          return;
        }

        documentIds.forEach((id) => {
          state.byId.loading[id.value] = false;
          const document = action.payload.find(
            (doc) => doc?.id.value === id.value
          );
          if (!document) {
            state.byId.error[id.value] = new Error("Document not found");
          } else {
            state.byId.error[id.value] = null;
            state.byId.entries[id.value] = document;
            for (const query in state.byQuery.entries) {
                state.byQuery.entries[query].data = state.byQuery.entries[
                query
                ].data.filter((doc) => doc.id.value !== id.value);
                state.byQuery.entries[query].data.push(document);
            }            
          }
        });
      }
    );

    builder.addCase(populateDocumentsByIds.rejected, (state, action) => {
      const documentIds = action.meta.arg.ids;
      if (!documentIds.length) {
        return;
      }
      documentIds.forEach((id) => {
        state.byId.loading[id.value] = false;
        state.byId.error[id.value] = action.error;
      });
    });

    builder.addCase(populateDocumentsQuery.pending, (state, action) => {
      const query = action.meta.arg.query.asSearchParams().toString();
      state.byQuery.loading[query] = true;
      state.byQuery.error[query] = null;
    });
    builder.addCase(populateDocumentsQuery.fulfilled, (state, action) => {
      const query = action.meta.arg.query.paginationParameters.asSearchParams().toString();
      state.byQuery.entries[query] = action.payload;
      state.byQuery.loading[query] = false;
    });
    builder.addCase(populateDocumentsQuery.rejected, (state, action) => {
      const query = action.meta.arg.query.paginationParameters.asSearchParams().toString();
      state.byQuery.loading[query] = false;
      state.byQuery.error[query] = action.error;
    });
  },
});

export const { addDocument, removeDocument } = documentsSlice.actions;

export const getDocumentsByQuery = (query: DocumentsQueryParameters) => 
  (state: RootState) =>
    state.documents.byQuery.entries[query.asSearchParams().toString()];
export const getDocumentById = (id: Guid) =>
  (state: RootState) => state.documents.byId.entries[id.value];
export const getDocumentIsLoading = (id: Guid) =>
  (state: RootState) => state.documents.byId.loading[id.value];
export const getDocumentError = (id: Guid) =>
  (state: RootState) => state.documents.byId.error[id.value];
export const getDocumentsByQueryIsLoading = (query: DocumentsQueryParameters) =>
    (state: RootState) =>
      state.documents.byQuery.loading[query.asSearchParams().toString()];
export const getDocumentsByQueryError = (query: DocumentsQueryParameters) =>
    (state: RootState) =>
      state.documents.byQuery.error[query.asSearchParams().toString()];
