import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { RootState } from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import { WritableDraft } from "immer";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeamSearch from "marketplace/entities/marketplace-team/api/request-contracts/marketplace-team-search";
import { useSelector } from "react-redux";
import Session from "users/session/session";

type IndividualStoreState = {
  byUserId: {
    entries: Record<string, Individual>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  }
};

const initialState: IndividualStoreState = {
  byUserId: {
    entries: {},
    loading: {},
    error: {}
  }
};
export const populateIndividuals = createAsyncThunk<
  Individual[],
  { session: Session; userIds: Guid[] }
>(
  "individuals/getIndividualsByForum",
  async ({session, userIds}: {session: Session, userIds: Guid[]}, thunkAPI) => {
    const store: {individuals: IndividualStoreState} = thunkAPI.getState() as any;
    const missingIndividuals = userIds.filter((userId) => !store.individuals.byUserId.entries[userId.value]);
    if(!missingIndividuals.length){
      return [];
    }
    try {
      const apiService = new IndividualAPIService(session);
      const individuals = await apiService.getUsersProfileInfo(missingIndividuals);
      return individuals;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const individualsSlice = createSlice({
  name: "individuals",
  initialState,
  reducers: {
    addIndividual(state, action){
      if(!state.byUserId.entries[action.payload.userId.value]){
        state.byUserId.entries[action.payload.userId.value] = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(populateIndividuals.pending, (state, action) => {
      const userIds = action.meta.arg.userIds;
      if(!userIds.length){
        return;
      }
      userIds.forEach((userId) => {
        state.byUserId.loading[userId.value] = true;
        state.byUserId.error[userId.value] = null;
      });
    });
    builder.addCase(populateIndividuals.fulfilled, (state, action) => {
      const userIds = action.meta.arg.userIds;
      if(!userIds.length){
        return;
      }

      action.payload.forEach((individual) => {
        state.byUserId.entries[individual.userId.value] = individual as WritableDraft<Individual>;
      });

      userIds.forEach((userId) => {
        state.byUserId.loading[userId.value] = false;
        if(!state.byUserId.entries[userId.value]){
          state.byUserId.error[userId.value] = new Error("Individual not found");
        }
      });
    });
    
    builder.addCase(populateIndividuals.rejected, (state, action) => {
      const userIds = action.meta.arg.userIds;
      if(!userIds.length){
        return;
      }
      userIds.forEach((userId) => {
        state.byUserId.loading[userId.value] = false;
        state.byUserId.error[userId.value] = action.error;
      });
    });
  }
});

export const { addIndividual } = individualsSlice.actions;
export const getIndividualByUserId = (userId: Guid) =>
  useSelector(
    (state: RootState) => state.individuals.byUserId.entries[userId.value]
  );
export const getIndividualIsLoadingByUserId = (userId: Guid) =>
  useSelector(
    (state: RootState) => state.individuals.byUserId.loading[userId.value]
  );
export const getErrorLoadingIndividualByUserId = (userId: Guid) =>
  useSelector(
    (state: RootState) => state.individuals.byUserId.error[userId.value]
  );

export const getTeamVendors = () =>
  useSelector((state: RootState) => state.teams.vendors);
export const getTeamVendorsIsLoading = () =>
  useSelector((state: RootState) => state.teams.vendors.loading);
export const getTeamVendorsError = () =>
  useSelector((state: RootState) => state.teams.vendors.error);

export const getTeamById = (id: Guid) =>
  useSelector((state: RootState) => state.teams.byId.entries[id.value]);
export const getIsLoadingTeamById = (id: Guid) =>
  useSelector((state: RootState) => state.teams.byId.loading[id.value]);
export const getErrorLoadingTeamById = (id: Guid) =>
  useSelector((state: RootState) => state.teams.byId.error[id.value]);

export const getTeamsByQuery = (query: MarketplaceTeamSearch) =>
  useSelector(
    (state: RootState) =>
      state.teams.byQuery.entries[query.asSearchParams().toString()]
  );
export const getIsLoadingTeamsByQuery = (query: MarketplaceTeamSearch) =>
  useSelector(
    (state: RootState) =>
      state.teams.byQuery.loading[query.asSearchParams().toString()]
  );
export const getErrorLoadingTeamsByQuery = (query: MarketplaceTeamSearch) =>
  useSelector(
    (state: RootState) =>
      state.teams.byQuery.error[query.asSearchParams().toString()]
  );

export const getFeeSchedulesForTeam = (teamId: Guid) =>
  useSelector(
    (state: RootState) => state.feeSchedules.byTeamId.entries[teamId.value]
  );
export const getIsLoadingFeeSchedulesForTeam = (teamId: Guid) =>
  useSelector(
    (state: RootState) => state.feeSchedules.byTeamId.loading[teamId.value]
  );
export const getErrorLoadingFeeSchedulesForTeam = (teamId: Guid) =>
  useSelector(
    (state: RootState) => state.feeSchedules.byTeamId.error[teamId.value]
  );

export const getTeamAvatar = (teamId: Guid) =>
  useSelector((state: RootState) => state.teams.avatars.entries[teamId.value]);
export const getIsLoadingTeamAvatar = (teamId: Guid) =>
  useSelector((state: RootState) => state.teams.avatars.loading[teamId.value]);
export const getErrorLoadingTeamAvatar = (teamId: Guid) =>
  useSelector((state: RootState) => state.teams.avatars.error[teamId.value]);

export const getTeamInvitationsByTeamId =
  (teamId: Guid) => (state: RootState) =>
    state.teams.invitations.byTeamId.entries[teamId.value];
export const getIsLoadingTeamInvitationsByTeamId = (teamId: Guid) =>
  useSelector(
    (state: RootState) => state.teams.invitations.byTeamId.loading[teamId.value]
  );
export const getErrorLoadingTeamInvitationsByTeamId = (teamId: Guid) =>
  useSelector(
    (state: RootState) => state.teams.invitations.byTeamId.error[teamId.value]
  );

export const getTeamInvitationById = (id: Guid) => (state: RootState) =>
  state.teams.invitations.byId.entries[id.value];
export const getIsLoadingTeamInvitationById = (id: Guid) =>
  useSelector(
    (state: RootState) => state.teams.invitations.byId.loading[id.value]
  );
export const getErrorLoadingTeamInvitationById = (id: Guid) =>
  useSelector(
    (state: RootState) => state.teams.invitations.byId.error[id.value]
  );
