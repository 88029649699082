import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { RootState } from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import AdministratorInvitation from "legal-entities/entities/administrator-invitation/administrator-invitation";
import AdministratorInvitationAPIService from "legal-entities/entities/administrator-invitation/api/administrator-invitation-api-service";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";

import Session from "users/session/session";

type AdminInvitationStoreState = {
  byEntityId: {
    entries: Record<string, AdministratorInvitation[]>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
  byId: {
    entries: Record<string, AdministratorInvitation>;
    loading: Record<string, boolean>;
    error: Record<string, SerializedError | null>;
  };
};

const initialState: AdminInvitationStoreState = {
  byEntityId: {
    entries: {},
    loading: {},
    error: {},
  },
  byId: {
    entries: {},
    loading: {},
    error: {},
  },
};

const populateAdministratorInvitation = createAsyncThunk(
  "administratorInvitations/getAdministratorInvitationById",
  async ({session, id}: {session: Session, id: Guid}, thunkAPI) => {
    try {
      const apiService = new AdministratorInvitationAPIService(session);
      const invitation = await apiService.getAdministratorInvitationById(id);
      return invitation;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const populateAdministratorInvitationsForEntity = createAsyncThunk(
    "administratorInvitations/getAdminInvitationsForLegalEntity",
    async ({session, entity}: {session: Session, entity: LegalEntity}, thunkAPI) => {
      try {
        const apiService = new AdministratorInvitationAPIService(session);
        const invitations = await apiService.getAdminInvitationsForLegalEntity(entity);
        return invitations;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
const administratorInvitationsSlice = createSlice({
  name: "administratorInvitations",
  initialState,
  reducers: {
    addEntityAdministratorInvitation: (state, action: PayloadAction<AdministratorInvitation>) => {
        if(!action.payload.id?.value){
            return;
        }
        state.byId.entries[action.payload.id?.value] = action.payload;

        const entityId = action.payload.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = state.byEntityId.entries[entityId]?.filter((existingInvitation) => !existingInvitation.id?.isEqualTo(action.payload.id)) ?? [];
        state.byEntityId.entries[entityId].push(action.payload);
    },
    removeEntityAdministratorInvitation: (state, action: PayloadAction<AdministratorInvitation>) => {
        if(!action.payload.id?.value){
            return;
        }
        delete state.byId.entries[action.payload.id?.value];

        const entityId = action.payload.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = state.byEntityId.entries[entityId]?.filter((existingInvitation) => !existingInvitation.id?.isEqualTo(action.payload.id)) ?? [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(populateAdministratorInvitation.pending, (state, action) => {
      const invitationId = action.meta.arg.id?.value;
      if(!invitationId){
        return;
      }
      state.byId.loading[invitationId] = true;
      state.byId.error[invitationId] = null;
    });
    builder.addCase(populateAdministratorInvitation.fulfilled, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.byId.entries[invitationId] = action.payload;
        state.byId.loading[invitationId] = false;
        state.byId.error[invitationId] = null;

        const entityId = action.payload.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = state.byEntityId.entries[entityId]?.filter((existingInvitation) => !existingInvitation.id?.isEqualTo(action.payload.id)) ?? [];
        state.byEntityId.entries[entityId].push(action.payload);
    });
    builder.addCase(populateAdministratorInvitation.rejected, (state, action) => {
        const invitationId = action.meta.arg.id?.value;
        if(!invitationId){
            return;
        }
        state.byId.loading[invitationId] = false;
        state.byId.error[invitationId] = action.error;
    });
    
    builder.addCase(populateAdministratorInvitationsForEntity.pending, (state, action) => {
        const entityId = action.meta.arg.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.loading[entityId] = true;
        state.byEntityId.error[entityId] = null;
    });
    builder.addCase(populateAdministratorInvitationsForEntity.fulfilled, (state, action) => {
        const entityId = action.meta.arg.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.entries[entityId] = action.payload;
        state.byEntityId.loading[entityId] = false;
        state.byEntityId.error[entityId] = null;

        action.payload.forEach((invitation) => {
            if(!invitation.id?.value){
                return;
            }
            state.byId.entries[invitation.id?.value] = invitation;
        });
    });
    builder.addCase(populateAdministratorInvitationsForEntity.rejected, (state, action) => {
        const entityId = action.meta.arg.entity.id?.value;
        if(!entityId){
            return;
        }
        state.byEntityId.loading[entityId] = false;
        state.byEntityId.error[entityId] = action.error;
    });
  }
});

export const {
  addEntityAdministratorInvitation,
  removeEntityAdministratorInvitation,
} = administratorInvitationsSlice.actions;

export const getAdminInvitationsForLegalEntity = (entityId: Guid) =>
    (state: RootState) =>
      state.administratorInvitations.byEntityId.entries[entityId.value]
export const getIsLoadingAdminInvitationsForLegalEntity = (entityId: Guid) =>
    (state: RootState) =>
      state.administratorInvitations.byEntityId.loading[entityId.value]
export const getErrorLoadingAdminInvitationsForLegalEntity = (entityId: Guid) =>
    (state: RootState) =>
      state.administratorInvitations.byEntityId.error[entityId.value]

export const getAdminInvitationById = (id: Guid) =>
    (state: RootState) => state.administratorInvitations.byId.entries[id.value]
export const getIsLoadingAdminInvitationById = (id: Guid) =>
    (state: RootState) => state.administratorInvitations.byId.loading[id.value]
export const getErrorLoadingAdminInvitationById = (id: Guid) =>
    (state: RootState) => state.administratorInvitations.byId.error[id.value]

export const getEntityAgreementById = (id: Guid) =>
    (state: RootState) => state.entityAgreements.byId.entries[id.value]
export const getIsLoadingEntityAgreementById = (id: Guid) =>
    (state: RootState) => state.entityAgreements.byId.loading[id.value]
export const getErrorLoadingEntityAgreementById = (id: Guid) =>
    (state: RootState) => state.entityAgreements.byId.error[id.value]

export const getEntityAgreementsForLegalEntity = (entity: LegalEntity) =>
    (state: RootState) => state.entityAgreements.byId.entries[entity.id?.value]
export const getIsLoadingEntityAgreementsForLegalEntity = (
  entity: LegalEntity
) =>
    (state: RootState) => state.entityAgreements.byId.loading[entity.id?.value]
export const getErrorLoadingAEntityAgreementsForLegalEntity = (
  entity: LegalEntity
) =>
    (state: RootState) => state.entityAgreements.byId.error[entity.id?.value]

export default administratorInvitationsSlice;