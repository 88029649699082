import Text from "common/values/text/text";

export default class FeeScheduleCategoryDescription extends Text {
    constructor(value: string) {
        super(value);

    }
    public get isEmpty(): boolean {
        return this.value === '';
    }
    public isEqualTo(other: Text | null): boolean {
        if(!(other instanceof FeeScheduleCategoryDescription)) return false;
        return super.isEqualTo(other);
    }
    
    public override clone(): FeeScheduleCategoryDescription {
        return new FeeScheduleCategoryDescription(this.value);
    }

    public static get Prototype(): FeeScheduleCategoryDescription {
        return new FeeScheduleCategoryDescription('Prototype');
    }
    public override fromJSON(object: any): FeeScheduleCategoryDescription | null {
        if(!object) return object;
        if(typeof object !== 'string') throw new Error('FeeScheduleCategoryDescription.fromJSON requires a string');
        return new FeeScheduleCategoryDescription(object);
    }
    public toJSON(): any {
        return this._value;
    }
}