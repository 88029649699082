import { ThemeProvider, Typography, createTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import ErrorList from "common/components/error-list";
import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InvalidEmailAddressError, InvalidPasswordError } from "common/errors";
import EmailAddress from "common/values/email-address/email-address";
import Password from "common/values/password/password";
import { useSession } from "users/session/session-context";
import { InvalidCredentialsError } from "users/errors";
import LoadingButton from "common/components/loading-button";
import LoginIcon from "@mui/icons-material/Login";
import UserCredentials from "users/values/user-credentials/user-credentials";
import { messageHubConnection } from "messaging/entities/message/realtime-store/messages-signalr-redux-middleware";
import { HubConnectionState } from "@microsoft/signalr";
import { commentsHubConnection } from "work/entities/comment/store/comments-signalr-redux-middleware";
import { proposalHubConnection } from "work/entities/proposal/store/proposals-signalr-redux-middleware";


const PageTitle = styled(Typography)(() => ({
  color: "#fff",
}));
const CredentialInput = styled(TextField)(() => ({
  "&.MuiTextField-root": {
    "& .Mui-focused:not(.Mui-error)": {
      color: "#fff",
      "&.MuiInputBase-root::after": {
        borderBottomColor: "#fff",
      },
    },
  },
}));
const RememberMeToggle = styled(FormControlLabel)(() => ({
  color: "#fff",
  "& .MuiCheckbox-root": {
    color: "#fff",
  },
}));
const LoginButton = styled(LoadingButton)(() => ({
  backgroundColor: "#E19941",
  margin: "1.5em 0",
  "&.MuiButton-root": {
    color: "black",
    "&:hover": {
      backgroundColor: "#FFB949",
    },
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.3)",
      boxShadow: "none",
      backgroundColor: "rgba(255, 255, 255, 0.12)",
    },
  },
}));
const ErrorItem = styled("li")(() => ({
  listStyleType: "none",
}));

type CredentialErrors = {
  general?: string[];
  emailValue?: string;
  passwordValue?: string[];
};

type LoginFormProps = {
  onEmailValueChanged?: (email: string) => void;
};

export default function LoginForm(props: Readonly<LoginFormProps>) {
  const [emailValue, setEmailValue] = React.useState("");
  const [passwordValue, setPasswordValue] = React.useState("");
  const [rememberMeChecked, setRememberMeChecked] = React.useState(false);
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [errors, setErrors] = React.useState<CredentialErrors>({
    emailValue: "",
    passwordValue: [],
  });
  const [formValid, setFormValid] = React.useState(false);
  const [signingIn, setSigningIn] = React.useState(false);

  const session = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const darkTheme = createTheme({ palette: { mode: "dark" } });

  const validateForm = useCallback(() => {
    clearErrors();

    let emailValidationErrors: string = "";
    let passwordValidationErrors: string[] = [];

    try {
      EmailAddress.validate(emailValue);
    } catch (error: any) {
      if (error instanceof InvalidEmailAddressError) {
        if (!emailValue) emailValidationErrors = "Email is required";
        else emailValidationErrors = "Email is invalid";
      }
    }

    try {
      Password.validate(passwordValue);
    } catch (error: any) {
      if (error instanceof InvalidPasswordError) {
        passwordValidationErrors = error.issues.password;
      }
    }

    setFormValid(
      !emailValidationErrors && passwordValidationErrors.length === 0
    );
    setErrors({
      emailValue: emailValidationErrors,
      passwordValue: passwordValidationErrors,
    });
  }, [emailValue, passwordValue]);

  useEffect(() => {
    if (!emailValue && !passwordValue) return;
    validateForm();
  }, [emailValue, passwordValue, validateForm]);

  function clearErrors() {
    setErrors({ general: [], emailValue: "", passwordValue: [] });
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmailValue(event.target.value);
    props.onEmailValueChanged?.(event.target.value);
  }

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPasswordValue(event.target.value);
  }

  async function handleLoginSubmit() {
    setLoginFailed(false);
    clearErrors();
    setSigningIn(true);

    try {
      await session.login(
        new UserCredentials(new EmailAddress(emailValue), new Password(passwordValue)),
        rememberMeChecked
      );
      if(proposalHubConnection.state === HubConnectionState.Disconnected){
        proposalHubConnection.start();
      }
      if(messageHubConnection.state === HubConnectionState.Disconnected){
        messageHubConnection.start();
      }
      if(commentsHubConnection.state === HubConnectionState.Disconnected){
        commentsHubConnection.start();
      }
      navigate(from, { replace: true });
    } catch (error: any) {
      if (error instanceof InvalidCredentialsError) {
        setErrors({ general: ["Invalid email or password."] });
      } else {
        console.error(error);
        setErrors({
          general: ["Unknown error occurred. Please try again later."],
        });
      }
      setLoginFailed(true);
    } finally {
      setSigningIn(false);
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <PageTitle variant="h4" align="center">
        Sign In
      </PageTitle>
      <CredentialInput
        variant="filled"
        margin="normal"
        required
        fullWidth
        id="login-email-input"
        label="Email Address"
        name="email"
        onKeyDown={(event) => {
          event.key === "Enter" && formValid && handleLoginSubmit();
        }}
        onChange={handleEmailChange}
        value={emailValue}
        autoComplete="email"
        autoFocus
        error={
          loginFailed || (errors.emailValue !== undefined && errors.emailValue.length > 0)
        }
        helperText={<ErrorItem key={errors.emailValue}>{errors.emailValue}</ErrorItem>}
      />
      <CredentialInput
        variant="filled"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="login-password-input"
        onChange={handlePasswordChange}
        onKeyDown={(event) => {
          event.key === "Enter" && formValid && handleLoginSubmit();
        }}
        value={passwordValue}
        autoComplete="current-password"
        error={loginFailed || (errors.passwordValue && errors.passwordValue.length > 0)}
        helperText={errors.passwordValue?.map((err: string) => (
          <ErrorItem key={err}>{err}</ErrorItem>
        ))}
      />
      <ErrorList errors={errors.general} />
      <RememberMeToggle
        label="Remember me"
        control={
          <Checkbox
            value="remember"
            checked={rememberMeChecked}
            onChange={(event) => setRememberMeChecked(event.target.checked)}
          />
        }
      />
      <LoginButton
        id="login-submit-button"
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<LoginIcon />}
        disabled={!formValid || signingIn}
        loading={signingIn}
        onClick={handleLoginSubmit}
      >
        {signingIn ? "Signing In..." : "Sign In"}
      </LoginButton>
    </ThemeProvider>
  );
}
