import Guid from "common/values/guid/guid";
import Action from "notifications/values/action/action";
import NotificationMessage from "notifications/values/notification-message/notification-message";
import NotificationTopic from "notifications/values/notification-topic/notification-topic";
import Notification from 'notifications/entities/notification/notification';
import { Moment } from "moment";
import MessageInfo from "notifications/entities/message-info/message-info";


export default class MessageNotification extends Notification {
  messageInfo?: MessageInfo;

  constructor(
    id: Guid,
    recipientUserId?: Guid,
    message?: NotificationMessage,
    topic?: NotificationTopic | null,
    actionRequired?: Action,
    seenAt?: Moment | null,
    createdDate?: Moment,
    isSeen?: boolean,
    messageInfo?: MessageInfo
  ) {
    super(
      id,
      recipientUserId,
      message,
      topic,
      actionRequired,
      seenAt,
      createdDate,
      isSeen
    );

    this.messageInfo = messageInfo;
  }
}
