import { AccountType } from "common/values/account-type/account-type";
import Session from "users/session/session";
import WorkFeeScheduleCategoryAPIRequest from "work/entities/proposal/api/request-contracts/work-fee-schedule-category-api-request";
import WorkTeamAPIRequest from "work/entities/proposal/api/request-contracts/work-team-api-request";
import ProposalReviewerAPIRequest from "work/entities/proposal/api/request-contracts/proposal-reviewer-api-request";
import Proposal from "work/entities/proposal/proposal";
import ReplaceableDocumentAPIRequest from "work/entities/proposal/api/request-contracts/replaceable-document-api-request";
import VendorRepresentativeAPIRequest from "work/entities/entity-vendor-representative/api/request-contracts/vendor-representative-api-request";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";

export default class WorkProposalAPIRequest {
  RFPId?: string;
  creatorType?: string;
  creatorEntityId?: string;
  name?: string;
  description?: string;
  clientRepEntityId: string | undefined;
  clientRepUserId: string | undefined;
  team?: WorkTeamAPIRequest;
  feeSchedule?: WorkFeeScheduleCategoryAPIRequest[];
  startDate?: string;
  endDate?: string;
  discount?: number;
  negotiable?: boolean;
  responseDueBy?: string;
  supersedesId?: string;
  clientReviewers?: ProposalReviewerAPIRequest[];
  vendorReviewers?: ProposalReviewerAPIRequest[];
  clientPolicyDocuments?: ReplaceableDocumentAPIRequest[];
  vendorPolicyDocuments?: ReplaceableDocumentAPIRequest[];
  conflictsCheckWaived?: boolean | null;
  teamRestricted?: boolean | null;
  conflictsDocuments?: ReplaceableDocumentAPIRequest[];
  clientTeamTemplateIds?: string[];
  vendorTeamTemplateIds?: string[];
  clientFeeScheduleTemplateIds?: string[];
  vendorFeeScheduleTemplateIds?: string[];

  constructor(proposal: Proposal, session: Readonly<Session>) {
    this.RFPId = proposal.RFPId?.value;
    this.name = proposal.name?.value;
    this.description = proposal.description?.value;
    this.team = proposal.team
      ? Object.assign(new WorkTeamAPIRequest(), {
          leader: proposal.team.leader?.entityId
            ? new VendorRepresentativeAPIRequest(
                new EntityVendorRepresentative(
                  proposal.team.leader.userId,
                  proposal.team.leader.entityId
                )
              )
            : undefined,
          memberUserIds: proposal.team.memberUserIds.map((id) => id.value),
          marketplaceId: proposal.team.marketplaceId?.value,
        })
      : undefined;
    this.feeSchedule = proposal.feeSchedule.map((category) => {
      return new WorkFeeScheduleCategoryAPIRequest(category);
    });
    this.discount = proposal.discount.numeral;
    this.creatorType = session.accountType;
    this.creatorEntityId = session.currentEntity.entityId.value;
    this.startDate = proposal.startDate?.toISOString();
    this.endDate = proposal.endDate?.toISOString();
    this.responseDueBy = proposal.responseDueBy?.value.toISOString();
    this.negotiable = proposal.negotiable;
    this.clientReviewers = proposal.clientReviewers.map((reviewer) => {
      return Object.assign(new ProposalReviewerAPIRequest(reviewer));
    });
    this.vendorReviewers = proposal.vendorReviewers.map((reviewer) => {
      return Object.assign(new ProposalReviewerAPIRequest(reviewer));
    });
    this.supersedesId = proposal.supersedes?.id?.value;

    if (session.accountType === AccountType.Vendor) {
      this.clientRepEntityId = proposal.client?.entityId.value;
      this.clientRepUserId = proposal.client?.userId.value;
    } else {
      this.clientRepEntityId = session.currentEntity.entityId.value;
      this.clientRepUserId = session.user?.id?.value;
    }
    this.clientPolicyDocuments = proposal.clientPolicyDocuments.map(
      (document) => {
        return Object.assign(new ReplaceableDocumentAPIRequest(document));
      }
    );
    this.vendorPolicyDocuments = proposal.vendorPolicyDocuments.map(
      (document) => {
        return Object.assign(new ReplaceableDocumentAPIRequest(document));
      }
    );
    this.conflictsCheckWaived = proposal.conflictsCheckWaived.value;
    this.teamRestricted = proposal.teamRestricted.value;
    this.conflictsDocuments = proposal.conflictsDocuments.map((document) => {
      return Object.assign(new ReplaceableDocumentAPIRequest(document));
    });

    this.clientTeamTemplateIds = proposal.clientTeamTemplateIds.map(
      (id) => id.value
    );
    this.vendorTeamTemplateIds = proposal.vendorTeamTemplateIds.map(
      (id) => id.value
    );
    this.clientFeeScheduleTemplateIds =
      proposal.clientFeeScheduleTemplateIds.map((id) => id.value);
    this.vendorFeeScheduleTemplateIds =
      proposal.vendorFeeScheduleTemplateIds.map((id) => id.value);
  }

  public get payload(): object {
    return {
      RFPId: this.RFPId,
      creatorType: this.creatorType,
      creatorEntityId: this.creatorEntityId,
      name: this.name,
      description: this.description,
      clientRepEntityId: this.clientRepEntityId,
      clientRepUserId: this.clientRepUserId,
      teamRestricted: this.teamRestricted,
      team: this.team?.payload,
      feeSchedule: this.feeSchedule?.map((category) => category.payload),
      startDate: this.startDate,
      endDate: this.endDate,
      discount: this.discount,
      negotiable: this.negotiable,
      responseDueBy: this.responseDueBy,
      supersedesId: this.supersedesId,
      clientReviewers: this.clientReviewers?.map(
        (reviewer) => reviewer.payload
      ),
      vendorReviewers: this.vendorReviewers?.map(
        (reviewer) => reviewer.payload
      ),
      clientFeeScheduleTemplateIds: this.clientFeeScheduleTemplateIds,
      vendorFeeScheduleTemplateIds: this.vendorFeeScheduleTemplateIds,
      clientTeamTemplateIds: this.clientTeamTemplateIds,
      vendorTeamTemplateIds: this.vendorTeamTemplateIds,
      clientPolicyDocuments: this.clientPolicyDocuments,
      vendorPolicyDocuments: this.vendorPolicyDocuments,
      conflictsCheckWaived: this.conflictsCheckWaived,
      conflictsDocuments: this.conflictsDocuments,
    };
  }
}
