import AHBoolean from "common/values/boolean/boolean";
import Date from "common/values/date/date";
import Percent from "common/values/percent/percent";
import Individual from "marketplace/entities/individual/individual";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import Proposal, {
  ProposalField,
  ProposalFieldCategory
} from "work/entities/proposal/proposal";
import { FeeScheduleRedline } from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FieldRedline, {
  FieldRedlineArray,
} from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import { ProposalFieldName } from "work/values/constants";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import WorkDocument from "work/values/work-document/work-document";
import DetailedTeam from "../team/detailed-team";

export default class ProposalIssues {
  private _entries: ProposalIssue[] = [];

  public get entries(): ProposalIssue[] {
    return [...this._entries];
  }

  public get canSubmit(): boolean {
    return this.canSave &&
      !this._entries.some((entry) => entry.field.isEqualTo(ProposalField.TeamLeader))
  }

  public get canSave(): boolean {
    return !this._entries.some((entry) => entry.level === ProposalIssueLevel.Critical)
  }

  public static fromProposal(proposal: Proposal): ProposalIssues {
    const issues = new ProposalIssues();
    issues.validateClient(proposal.client);
    issues.validateName(proposal.name);
    issues.validateDescription(proposal.description);
    issues.validateDates(
      proposal.responseDueBy,
      proposal.startDate,
      proposal.endDate
    );
    issues.validateTeam(proposal?.team);
    issues.validateFeeSchedule(proposal.feeSchedule ?? []);
    issues.validateConflictsDocuments(
      proposal.conflictsCheckWaived ?? new AHBoolean(false),
      proposal.conflictsDocuments ?? []
    );
    return issues;
  }

  public static fromBuilder(builder: ProposalBuilder): ProposalIssues {
    const issues = new ProposalIssues();

    issues.validateClient(builder.currentSpec.client);
    issues.validateName(builder.currentSpec.name);
    issues.validateDescription(builder.currentSpec.description);
    issues.validateDates(
      builder.currentSpec.responseDueBy,
      builder.currentSpec.startDate,
      builder.currentSpec.endDate
    );
    issues.validateTeam(builder.currentSpec.team);
    issues.validateFeeSchedule(
      builder.currentSpec.feeSchedule ?? []
    );
    issues.validateConflictsDocuments(
      builder.currentSpec.conflictsCheckWaived ??
        new AHBoolean(false),
      builder.currentSpec.conflictsDocuments ?? []
    );

    return issues;
  }

  public static fromRedline(redline: ProposalRedline): ProposalIssues {
    const issues = new ProposalIssues();

    issues.validateNameRedline(redline.name);
    issues.validateDescriptionRedline(redline.description);
    issues.validateDatesRedline(
      redline.responseDueBy,
      redline.startDate,
      redline.endDate
    );
    issues.validateTeamRedline(redline.team);
    issues.validateFeeScheduleRedline(redline.feeSchedule);
    issues.validateConflictsDocumentsRedline(
      redline.conflictsCheckWaived,
      redline.conflictsDocuments
    );
    issues.validatePolicyDocumentsRedline(redline.clientPolicyDocuments);
    issues.validatePolicyDocumentsRedline(redline.vendorPolicyDocuments);
    issues.validateDiscountRedline(redline.discount);
    return issues;
  }

  private validateClient(client?: EntityClientRepresentative | null): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Client
    );
    if (!client) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Client,
          "Client is required.",
          ProposalIssueLevel.Critical
        )
      );
    }
  }

  private validateName(name?: ProjectName | null): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Name
    );
    if (!name || name.value === "") {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Name,
          "Name is required.",
          ProposalIssueLevel.Critical
        )
      );
    }
  }

  private validateNameRedline(nameRedline: FieldRedline<ProjectName>): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Name
    );

    if (!nameRedline.isResolved) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Name,
          "Changes requested to name.",
          ProposalIssueLevel.Critical
        )
      );
    } else {
      this.validateName(nameRedline.currentEntry);
    }
  }

  private validateDescription(description?: ProjectDescription | null): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Description
    );
    if (!description || description.value === "") {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Description,
          "Description is required.",
          ProposalIssueLevel.Critical
        )
      );
    }
  }
  private validateDescriptionRedline(
    descriptionRedline: FieldRedline<ProjectDescription>
  ): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Description
    );

    if (!descriptionRedline.isResolved) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Description,
          "Changes requested to description.",
          ProposalIssueLevel.Critical
        )
      );
    } else {
      this.validateDescription(descriptionRedline.currentEntry);
    }
  }

  private validateDates(
    responseDueBy?: Date | null,
    startDate?: Date | null,
    endDate?: Date | null
  ): void {
    this._entries = this._entries.filter(
      (entry) =>
        [
          ProposalFieldName.ResponseDueBy,
          ProposalFieldName.StartDate,
          ProposalFieldName.EndDate,
        ].indexOf(entry.field.name) === -1
    );
    const now = new Date();

    if(responseDueBy && !responseDueBy.isValid) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.ResponseDueBy,
          "Response due by date is invalid.",
          ProposalIssueLevel.Critical
        )
      );
    }
    if(responseDueBy && responseDueBy.isValid && now.isAfter(responseDueBy)) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.ResponseDueBy,
          "Response due by cannot be in the past.",
          ProposalIssueLevel.Critical
        )
      );
    }

    if(startDate && !startDate.isValid) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.StartDate,
          "Start date is invalid.",
          ProposalIssueLevel.Critical
        )
      );
    }
    if(startDate && startDate.isValid && now.isAfter(startDate)) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.StartDate,
          "Start date cannot be in the past.",
          ProposalIssueLevel.Critical
        )
      );
    }

    if(endDate && !endDate.isValid) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.EndDate,
          "End date is invalid.",
          ProposalIssueLevel.Critical
        )
      );
    }
    if(endDate && endDate.isValid && now.isAfter(endDate)) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.EndDate,
          "End date cannot be in the past.",
          ProposalIssueLevel.Critical
        )
      );
    }
    if (responseDueBy?.isValid && startDate?.isValid && responseDueBy.isAfter(startDate)) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.ResponseDueBy,
          "Response due by date cannot be after start date.",
          ProposalIssueLevel.Critical
        )
      );
    }
    if (startDate?.isValid && endDate?.isValid && startDate.isAfter(endDate)) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.StartDate,
          "Start date cannot be after end date.",
          ProposalIssueLevel.Critical
        )
      );
    }
  }
  private validateDatesRedline(
    responseDueByRedline: FieldRedline<Date>,
    startDateRedline: FieldRedline<Date>,
    endDateRedline: FieldRedline<Date>
  ): void {
    this._entries = this._entries.filter(
      (entry) =>
        [
          ProposalFieldName.ResponseDueBy,
          ProposalFieldName.StartDate,
          ProposalFieldName.EndDate,
        ].indexOf(entry.field.name) === -1
    );

    if (!responseDueByRedline.isResolved) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.ResponseDueBy,
          "Changes requested to response due by date.",
          ProposalIssueLevel.Critical
        )
      );
    }

    if (!startDateRedline.isResolved) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.StartDate,
          "Changes requested to start date.",
          ProposalIssueLevel.Critical
        )
      );
    }

    if (!endDateRedline.isResolved) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.EndDate,
          "Changes requested to end date.",
          ProposalIssueLevel.Critical
        )
      );
    }

    if (
      responseDueByRedline.isResolved &&
      startDateRedline.isResolved &&
      endDateRedline.isResolved
    ) {
      this.validateDates(
        responseDueByRedline.currentEntry,
        startDateRedline.currentEntry,
        endDateRedline.currentEntry
      );
    }
  }

  private validateTeam(team?: DetailedTeam | null): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.category !== ProposalFieldCategory.Team
    );
    if (!team?.leader) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.TeamLeader,
          "Team leader is required to submit proposal.",
          ProposalIssueLevel.Warning
        )
      );
    }
  }
  private validateTeamRedline(
    teamRedline: FieldRedlineArray<Individual>
  ): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Team
    );

    for (const memberRedline of teamRedline.redlines) {
      if (!memberRedline.isResolved) {
        this._entries.push(
          new ProposalIssue(
            memberRedline.field,
            `Changes requested for team member: ${
              memberRedline.revisedEntry?.getFullName() ??
              memberRedline.originalEntry?.getFullName()
            }.`,
            ProposalIssueLevel.Critical
          )
        );
      }
    }
  }

  private validateFeeSchedule(feeSchedule: FeeScheduleCategory[]): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.FeeSchedule
    );
    if (feeSchedule.length === 0) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.FeeSchedule,
          "Fee schedule is required to hire.",
          ProposalIssueLevel.Warning
        )
      );
      return;
    }
    for (const deferredCategory of feeSchedule.filter(
      (category) => !category.fee
    )) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.FeeScheduleCategory(deferredCategory.id),
          `Deferred fee for ${deferredCategory.name} must be set to hire.`,
          ProposalIssueLevel.Warning
        )
      );
    }
  }
  private validateFeeScheduleRedline(
    feeScheduleRedline: FeeScheduleRedline
  ): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.FeeSchedule
    );

    for (const categoryRedline of feeScheduleRedline.redlines) {
      if (!categoryRedline.isResolved) {
        this._entries.push(
          new ProposalIssue(
            categoryRedline.field,
            `Changes requested to fee schedule category: ${
              categoryRedline.revisedEntry?.name ??
              categoryRedline.originalEntry?.name
            }.`,
            ProposalIssueLevel.Critical
          )
        );
      } else if (
        categoryRedline.currentEntry &&
        !categoryRedline.currentEntry.fee
      ) {
        this._entries.push(
          new ProposalIssue(
            categoryRedline.field,
            `Deferred fee for ${categoryRedline.currentEntry.name} must be set to hire.`,
            ProposalIssueLevel.Warning
          )
        );
      }
    }
    if (
      feeScheduleRedline.redlines.length < 1 ||
      (feeScheduleRedline.isResolved &&
        feeScheduleRedline.redlines.filter((redline) => redline.currentEntry)
          .length < 1)
    ) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.FeeSchedule,
          "Fee schedule is required to hire.",
          ProposalIssueLevel.Warning
        )
      );
    }
  }

  private validateConflictsDocuments(
    conflictsCheckWaived: AHBoolean,
    documents: WorkDocument[]
  ) {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Conflicts
    );
    if (!conflictsCheckWaived.value && documents.length === 0) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Conflicts,
          "Conflicts checks are required to hire when not waived.",
          ProposalIssueLevel.Warning
        )
      );
    }
  }
  private validateConflictsDocumentsRedline(
    conflictsCheckWaived: FieldRedline<AHBoolean>,
    documentsRedline: FieldRedlineArray<WorkDocument>
  ) {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Conflicts
    );

    if (!conflictsCheckWaived.isResolved) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Conflicts,
          "Changes requested to conflicts check waiver.",
          ProposalIssueLevel.Critical
        )
      );
    }

    for (const documentRedline of documentsRedline.redlines) {
      if (!documentRedline.isResolved) {
        this._entries.push(
          new ProposalIssue(
            documentRedline.field,
            "Changes requested to conflicts document.",
            ProposalIssueLevel.Critical
          )
        );
      }
    }

    if (
      conflictsCheckWaived.isResolved &&
      !conflictsCheckWaived.currentEntry?.value &&
      documentsRedline.redlines.filter((redline) => redline.currentEntry)
        .length < 1
    ) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Conflicts,
          "Conflicts checks are required to hire when not waived.",
          ProposalIssueLevel.Warning
        )
      );
    }
  }
  private validatePolicyDocumentsRedline(
    documentsRedline: FieldRedlineArray<WorkDocument>
  ) {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== documentsRedline.field.name
    );

    for (const documentRedline of documentsRedline.redlines) {
      if (!documentRedline.isResolved && documentRedline.field.id) {
        this._entries.push(
          new ProposalIssue(
            documentRedline.field,
            "Changes requested to policy document.",
            ProposalIssueLevel.Critical
          )
        );
      }
    }
  }

  private validateDiscountRedline(discountRedline: FieldRedline<Percent>): void {
    this._entries = this._entries.filter(
      (entry) => entry.field.name !== ProposalFieldName.Discount
    );

    if (!discountRedline.isResolved) {
      this._entries.push(
        new ProposalIssue(
          ProposalField.Discount,
          "Changes requested to discount.",
          ProposalIssueLevel.Critical
        )
      );
    }
  }
}

export class ProposalIssue {
  public readonly field: ProposalField;
  public readonly description: string;
  public readonly level: ProposalIssueLevel;

  constructor(
    field: ProposalField,
    description: string,
    level: ProposalIssueLevel
  ) {
    this.field = field;
    this.description = description;
    this.level = level;
  }
}

export enum ProposalIssueLevel {
  Critical,
  Warning,
  Info,
}
