export default class BillingRateInfo {
    billingRate: string;
    billingUnit: string;

    constructor(billingRate: string, billingUnit: string) {
        this.billingRate = billingRate;
        this.billingUnit = billingUnit;
    }

    public static fromJSON(object: any) {
        if (!object) return;
        const { billingRate, billingUnit } = object;
        return new BillingRateInfo(billingRate, billingUnit);
    }

    public toJSON() {
        return {
            billingRate: this.billingRate.toString(),
            billingUnit: this.billingUnit.toString()
        };
    }
}
